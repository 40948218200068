import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../shared/components/buttons/buton.module';
import { FormFieldModule } from '../shared/components/form-field';
import { GraphModule } from '../shared/components/graphs/graph.module';
import { GroupService } from '../shared/services/group.service';
import { PersistanceService } from '../shared/services/persistance.service';
import { ModalModule } from '../_modal';
import { GroupListComponent } from './components/group-list/group-list.component';
import { GroupPageComponent } from './components/group-page/group-page.component';
import { UploadGroupComponent } from './components/upload-group/upload-group.component';
import { GroupContainerComponent } from './containers/group-container.component';
import { GroupApiEffects } from './group-api.effects';


const routes: Routes = [
  // Site routes goes here
   {
     path: '',
     redirectTo:'groups',
     pathMatch:'full'

   }
   ,
   {
     path: 'groups',
     component: GroupContainerComponent,
     children:[
      {path: '', redirectTo: 'group-list',  pathMatch:'full'},
      {
        path:'group-list',
        component: GroupPageComponent
      },
      {
        path:'upload-group',
        component: UploadGroupComponent
      }]

   }
  ]

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FormFieldModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule.forChild(routes),
    EffectsModule.forFeature([GroupApiEffects]),
    GraphModule
  ],
  declarations: [
    GroupContainerComponent,
    GroupListComponent,
    GroupPageComponent,
    UploadGroupComponent

  ],
  providers: [
    PersistanceService,
    GroupService
  ]
})
export class GroupModule {}
