
<ng-container>

  <div class="table__header  flex content-evenly items-center">

    <div class="pr-4 font-medium text-2xl leading-tight">{{'PAGE_NOTIFICATIONS.LIST_NOTIFICATIONS.MESSAGES' | translate }}</div>
    <div class="flex-grow"></div>
    <button type="button" class="pl-4 w-8" (click)="onClickPrevPage($event)">
      <img src="/assets/images/back-icon.png" class="w-full block" />
    </button>
    <div class="pl-4 w-8 text-center text-gray-800">
      <p>{{currentPage}}</p>
      <!-- page number here  -->
    </div>
    <button type="button" class="pl-4 w-8" (click)="onClickNextPage($event)">
      <img src="/assets/images/forward-icon.png" class="w-full block" />
    </button>
  </div>

  <div class="table__content pt-6">
    <div *ngFor="let d of  data" class="table__row flex content-evenly items-center pb-3">
      <div class="pr-4 w-6">
        <img class="w-full block" src="/assets/images/point-icon.png" />
      </div>
      <!-- <div class="flex-grow table__description">Welcome to redONE…</div> -->
      <div class="flex-grow table__description">{{d.messageTitle}}</div>
      <div class="w-6">
        <div *ngIf="d.status == 0" class="block circle-tag rounded-full bg-gray-700 text-center w-6 text-white">S</div>
        <div  *ngIf="d.status == 1" class="block circle-tag rounded-full bg-yellow-700 text-center w-6 text-white">C</div>
      </div>
      <div class="ml-3 w-5" >
        <img src="/assets/images/edit-icon.png" class="w-full block" (click)="onClickGoDetailPage(d)" />
      </div>
      <div class="ml-3 w-5" >
        <img src="/assets/images/send-icon.png" class="w-full block" (click)="onClickOpenSendNotificationPageHandler(d)" />
      </div>
      <div class="ml-3 w-5">
        <img src="/assets/images/delete-icon.png" class="w-full block" (click)="onClickDeleteNotification(d)"/>
      </div>
    </div>
  </div>

</ng-container>


<!-- <app-create-notification-floating-button  (click)="onClickFloatingButtonHandler($event)"  class="absolute top-0 right-0 mt-12 mr-12"></app-create-notification-floating-button> -->

