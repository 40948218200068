import { createAction, props } from "@ngrx/store";
import { initializeNewNotificationCommandInterface } from 'src/app/shared/types/params/request/initializeNewNotificationCommand.interface';
import { loadDeliveredNotificationsRequestInterface } from 'src/app/shared/types/params/request/loadDeliveredNotificationsRequest.interface';
import { loadNotificationTemplatesRequestInterface } from 'src/app/shared/types/params/request/loadNotificationTemplatesRequest.interface';
import { loadNewNotificationRecordRequestInterface } from '../../shared/types/params/request/loadNewNotificationRecordRequest.interface';
import { UpdateSelectedNotificationTypeCommandInterface } from 'src/app/shared/types/params/request/UpdateSelectedNotificationTypeCommand.interface';
import { UpdateNotificationMessageContentCommandInterface } from 'src/app/shared/types/params/request/UpdateNotificationMessageContentCommand.Interface';
import { CreateNewTemplateContentCommandInterface } from 'src/app/shared/types/params/request/CreateNewTemplateContentCommand.interface';
import { CreateNewActionCommandInterface } from 'src/app/shared/types/params/request/CreateNewActionCommand.interface';
import { CreateNewNotificationTemplateCommandInterface } from '../../shared/types/params/request/CreateNewNotificationTemplateCommand.interface';
import { SendNotificationToAllCommandInterface } from '../../shared/types/params/request/SendNotificationToAllCommand.interface';
import { Type } from '@angular/core';
import { SendNotificationToIndividualCommandInterface } from 'src/app/shared/types/params/request/SendNotificationToIndividualCommand.interface';
import { SendNotificationToGroupCommandInterface } from 'src/app/shared/types/params/request/SendNotificationToGroupCommand.interface';
import { deleteNotificationCommandInterface } from 'src/app/shared/types/params/request/deleteNotificationCommand.interface';
import { loadSelectedNotificationTemplateRequestInterface } from 'src/app/shared/types/params/request/loadSelectedNotificationTemplateRequest.interface';
import { loadSegmentsRequestInterface } from '../../shared/types/params/request/loadSegmentsRequest.interface';
import { SendNotificationToSegmentsCommandInterface } from 'src/app/shared/types/params/request/SendNotificationToSegmentsCommand.interface';

/* load notifications templates*/
export const loadNT = createAction(
  "[Notifications Page] Load Notification Templates",
  props<{request: loadNotificationTemplatesRequestInterface}>()
  );

  export const goNextNTPage = createAction(
    "[Notifications Page] Go Next Notification Templates Page",
    props<{request: loadNotificationTemplatesRequestInterface}>()
    );


  export const goPrevNTPage = createAction(
    "[Notifications Page] Go Prev Notification Templates Page",
    props<{request: loadNotificationTemplatesRequestInterface}>()
    );

    /* load delivered notifications */
    export const loadDN = createAction(
      "[Notifications Page] Load Delivered Notifications",
      props<{request: loadDeliveredNotificationsRequestInterface}>()
      );

      export const goNextDNPage = createAction(
        "[Notifications Page] Go Next Delivered Notifications Page",
        props<{request: loadDeliveredNotificationsRequestInterface}>()
        );


      export const goPrevDNPage = createAction(
        "[Notifications Page] Go Prev Delivered Notifications Page",
        props<{request: loadDeliveredNotificationsRequestInterface}>()
        );


    /* initialize new notification */
    export const InitializeNewNotification = createAction(
      "[Notifications Page] Initialize new notification",
      props<{command: initializeNewNotificationCommandInterface}>()
    );


    /* CREATE NOTIFICATION STEP ONE - load new initialized notification record */
    export const LoadNewNotificationRecord = createAction(
      "[Notifications Page] Load new notification record",
      props<{request: loadNewNotificationRecordRequestInterface}>()
    );

    /*  CREATE NOTIFICATION STEP ONE -  update selected notification type */
    export const UpdateSelectedNotificationType = createAction(
      "[Notifications Page] Update selected notification type",
       props<{command: UpdateSelectedNotificationTypeCommandInterface}>()
    );

    /*  CREATE NOTIFICATION STEP TWO -  update notification messages */
     export const UpdateNotificationMessageContent = createAction(
      "[Notifications Page] Update notificaition messages",
       props<{command: UpdateNotificationMessageContentCommandInterface}>()
    );

    /*  CREATE NOTIFICATION STEP THREE -  create new notification content   */
    export const CreateNewContentTemplate = createAction(
      "[Notifications Page] Create new content template",
       props<{command: CreateNewTemplateContentCommandInterface}>()
    );

    export const CreateNewAction = createAction(
      "[Notifications Page] Create new action",
      props<{command: CreateNewActionCommandInterface}>()
    )



    export const ResetAndSaveAction = createAction(
      "[Notifications Page] Reset the saved action and save"
    )


    export const DiscardNewMessageTemplate = createAction(
      "[Notifications Page] Discard current working message template"
    )

    export const CreateNewNotificationTemplate = createAction(
      "[Notifications Page] Create new notification template",
      props<{command: CreateNewNotificationTemplateCommandInterface}>()
    )

    export const SendNotificationToAll = createAction(
      "[Notifications Page] Send notification to all",
      props<{command: SendNotificationToAllCommandInterface}>()
    )

    export const SendNotificationToSingle = createAction(
      "[Notifications Page] Send notification to individual",
      props<{command: SendNotificationToIndividualCommandInterface}>()
    )

    export const SendNotificationToGroup = createAction(
      "[Notifications Page] Send notification to group",
      props<{command: SendNotificationToGroupCommandInterface}>()
    )

    export const SendNotificationToSegments = createAction(
      "[Notifications Page] Send notification to segments",
      props<{command: SendNotificationToSegmentsCommandInterface}>()
    )

      /* delete notification */
      export const DeleteNotification = createAction(
        "[Notifications Page] Delete notification",
        props<{command: deleteNotificationCommandInterface}>()
      );



      /* */
      export const LoadSelectedNotificationTemplate = createAction(
        "[Notifications Page] Load selected notification template",
        props<{request: loadSelectedNotificationTemplateRequestInterface}>()
      );


     /* */
      export const LoadSegments = createAction(
        "[Notifications Page] Load segments",
         props<{request: loadSegmentsRequestInterface}>()
      );


export type NotificationsActions = ReturnType<
  | typeof loadNT
  | typeof goNextNTPage
  | typeof goPrevNTPage

  | typeof loadDN
  | typeof goNextDNPage
  | typeof goPrevDNPage

  | typeof InitializeNewNotification
  | typeof UpdateSelectedNotificationType

  |typeof UpdateNotificationMessageContent

  |typeof CreateNewContentTemplate
  |typeof CreateNewAction
  |typeof ResetAndSaveAction

  |typeof DiscardNewMessageTemplate

  |typeof SendNotificationToAll

  |typeof SendNotificationToSegments

  |typeof DeleteNotification
  |typeof LoadSelectedNotificationTemplate

  |typeof LoadSegments
>;
