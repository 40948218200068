import { ImageModel } from '../../../shared/models/image.model';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { NotificationTemplateModel } from 'src/app/shared/models/notificationTemplate.model';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { ModalService } from 'src/app/_modal';
import * as fromRoot from "src/app/shared/state";
import { loadNotificationTemplatesRequestInterface } from 'src/app/shared/types/params/request/loadNotificationTemplatesRequest.interface';

import { environment } from 'src/environments/environment';

import { initializeNewNotificationCommandInterface as InitializeNewNotificationCommandInterface } from '../../../shared/types/params/request/initializeNewNotificationCommand.interface';
import * as uuid from 'uuid';

import { GroupPageActions } from '../../actions';
import { loadImagesListRequestInterface } from 'src/app/shared/types/params/request/loadImagesListRequest.interface';
import { loadGroupListRequestInterface } from 'src/app/shared/types/params/request/loadGroupListRequest.interface';
import { GroupModel } from 'src/app/shared/models/group.model';
import { GroupUser } from '../../../shared/models/group.model';
import { SpinnerOverlayService } from 'src/app/shared/components/spinner/spinner-overlay/spinner-overlay.service';
import { deleteGroupCommandInterface } from 'src/app/shared/types/params/request/deleteGroupCommand.interface';

@Component({
  selector: "app-group-page",
  templateUrl: "./group-page.component.html",
  styleUrls: ["./group-page.component.scss"]
})
export class GroupPageComponent implements OnInit, OnDestroy {

  groups$: Observable<PagedListModel<GroupModel[]>>;
  selectedGroup:GroupModel;
  selectedGroupUsers:GroupUser[];
  currentPage$: Observable<number>;
  currentPage:number;
  currentPageSubscription: Subscription;
  groupsLoadingState$:Observable<boolean>;
  groupsLoadingStateSubscription:Subscription;



  constructor(
    private modalService:ModalService,
    private _router:Router,
    private store: Store<fromRoot.State>,
    private spinnerOverlayService: SpinnerOverlayService
    ) {
      this.groups$ = this.store.pipe(select(fromRoot.selectAllGroupsList));
      this.currentPage$ = this.store.pipe(select(fromRoot.selectAllGroupsListCurrentPage));
      this.groupsLoadingState$ = this.store.pipe(select(fromRoot.selectAllGroupsListLoadingState));

      this.groupsLoadingStateSubscription = this.groupsLoadingState$.subscribe((res)=>{
        if(res){
          this.spinnerOverlayService.show();
        } else {
          var me = this;
          setTimeout(function(){
            me.spinnerOverlayService.hide();
          },1000);
        }

      })

      this.currentPageSubscription = this.currentPage$.subscribe((res)=>{
        if(res == null){
          res = 1;
        }
        this.currentPage = res;
      })

      // this.currentPageSubscription = this.groups$.subscribe((res)=>{
      //   if(res == null){
      //     res = 1;
      //   }
      //   this.selectedGroup = res;
      // })

    }
  ngOnDestroy(): void {
    this.currentPageSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeValues();
    this.getImages();
  }


  initializeValues(){
    this.selectedGroupUsers = [];
  }

  getImages() {
    const requestParams: loadGroupListRequestInterface = {
        request: {
          currentPage : this.currentPage,
          pageSize : environment.pageSize
        }
    }
    this.store.dispatch(GroupPageActions.loadGroupList({request:requestParams}));

  }


  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  goToNext(event:Event){

    const requestParams: loadGroupListRequestInterface = {
      request: {
        currentPage : this.currentPage + 1,
        pageSize : environment.pageSize
      }
    }
    this.store.dispatch(GroupPageActions.goNextGroupListPage({request:requestParams}));


  }

  goToPrev(event:Event){
    const requestParams: loadGroupListRequestInterface = {
      request: {
        currentPage : (this.currentPage>1)?this.currentPage - 1:1,
        pageSize : environment.pageSize
      }
    }

    this.store.dispatch(GroupPageActions.goPrevGroupListPage({request:requestParams}));

  }

  checkGroup(groupModel:GroupModel){
    this.selectedGroup = groupModel;
    this.selectedGroupUsers = [];
    this.selectedGroupUsers = groupModel.groupUsers;
    console.log(groupModel.groupUsers);

    this.openModal('view-group-modal');
  }

  deleteGroup(groupModel:GroupModel){
    var answer = confirm("Confirm delete group [" + groupModel.groupName + "]" );
    if (answer) {
      const requestParams: deleteGroupCommandInterface = {
        request: {
          groupId : groupModel.groupId
        }
      }

      this.store.dispatch(GroupPageActions.DeleteGroup({command:requestParams}));
    } else {

    }
  }


  onClickFloatingButtonHandler(ev:Event){
    this._router.navigate(['./groups/upload-group']);
  }


}
