export enum SendNotificationTargetTypeEnum {
    SEND_TO_ALL = 1,
    SEND_TO_ALL_ENGLISH = 2,
    SEND_TO_ALL_THAI = 3,
    SEND_TO_GROUP = 4,
    SEND_TO_SINGLE_ACCTID = 5,
    SEND_TO_SINGLE_CALLERID = 6,
    SEND_TO_SEGMENTS = 7,
    SEND_TO_SINGLE_SUBSCRIPTIONID= 8,
    SEND_TO_ALL_BURMESE = 9,
}
