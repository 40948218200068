import { createSelector } from '@ngrx/store';
import { PagedListModel } from '../models/pagedList.model';

import { GroupModel } from '../models/group.model';
import { GroupApiActions, GroupPageActions } from 'src/app/group/actions';
import { goPrevGroupListPage } from '../../group/actions/group-page.actions';
import { SegmentAggregateModel, SegmentModel } from '../models/segment.model';
import { NotificationsApiActions, NotificationsPageActions } from 'src/app/notifications/actions';


export interface SegmentsStateInterface {
  data: SegmentAggregateModel | null
  isLoading: boolean
  error: string | null
}

export const initialState : SegmentsStateInterface =  {
    data : null,
    isLoading : false,
    error : null
}

export function reducer(
  state = initialState,
  action: NotificationsPageActions.NotificationsActions | NotificationsApiActions.NotificationApiActions
): SegmentsStateInterface {
  switch (action.type) {
    case NotificationsPageActions.LoadSegments.type:
      return   {
        ...state,
        isLoading: true
      }

    case NotificationsApiActions.LoadSegmentsSuccess.type:
        return   {
          ...state,
          isLoading: false,
          data: action.segments
        }
    case NotificationsApiActions.LoadSegmentsFailed.type:
        return   {
            ...state,
            isLoading: false,
            error: action.errors
      }

    default:
      return state;
  }
}


/**
 * Selectors
 */

export const selectAllSegments= (state:SegmentsStateInterface ) => state.data;
export const selectAllSegmentsState= (state:SegmentsStateInterface ) => state;
export const getAllSegmentsSelector= createSelector(
  selectAllSegments,
  (data:SegmentAggregateModel) => {
     return data
  }
);

export const getAllSegmentsSelectorLoadingState = createSelector(
  selectAllSegmentsState,
  (data: SegmentsStateInterface) => {
    return data.isLoading;
  }
);
