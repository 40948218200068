import { trigger, transition, style, animate, state, group } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { GroupModel, GroupUser } from 'src/app/shared/models/group.model';
import { ImageModel } from 'src/app/shared/models/image.model';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { ModalService } from 'src/app/_modal';
import * as fromRoot from "src/app/shared/state";
import { SpinnerOverlayService } from 'src/app/shared/components/spinner/spinner-overlay/spinner-overlay.service';
import { loadGroupListRequestInterface } from 'src/app/shared/types/params/request/loadGroupListRequest.interface';
import { environment } from 'src/environments/environment';
import { GroupPageActions } from 'src/app/group/actions';
import { SendNotificationToGroupCommandInterface } from 'src/app/shared/types/params/request/SendNotificationToGroupCommand.interface';
import { NotificationsPageActions } from '../../actions';
import * as uuid from 'uuid';
import { SegmentAggregateModel, SegmentModel, SelectedSegmentModel } from '../../../shared/models/segment.model';
import { SendNotificationToSegmentsCommandInterface } from 'src/app/shared/types/params/request/SendNotificationToSegmentsCommand.interface';
import { loadSegmentsRequestInterface } from '../../../shared/types/params/request/loadSegmentsRequest.interface';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector:'app-send-to-segments',
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeInOut', [
      // ...
      state('fadeIn', style({
        opacity: 1
      })),
      state('fadeOut', style({
        opacity: 0,
      })),
      transition('in => out', [
        animate('1s ease-in')
      ]),
      transition('out => in', [
        animate('0.5s ease-out')
      ]),
    ]),
    trigger('showHidden', [
      state('in', style({
        opacity: 1
      })),
      transition('void => *', [
        style({  opacity:  1}),
        group([
          animate('0.5s ease', style({
            opacity: 1
          }))
        ])
      ]),
      transition('* => void', [
        group([
          animate('0.5s 0.2s ease', style({
            opacity:  0.6
          }))
        ])
      ])
    ]),
      trigger('flyInOut', [
        state('in', style({
          transform: 'translateX(0)', opacity: 1
        })),
        transition('void => *', [
          style({  transform: 'translateX(10px)', opacity:  1}),
          group([
            animate('0.5s 0.1s ease', style({
              transform: 'translateX(0)'
            })),
            animate('0.5s ease', style({
              opacity: 1
            }))
          ])
        ]),
        transition('* => void', [
          group([
            animate('0.5s ease', style({
              transform: 'translateX(10px)'
            })),
            animate('0.5s 0.2s ease', style({
              opacity:  0.6
            }))
          ])
        ])
      ])
  ],
  templateUrl: './send-to-segments.component.html',
  styleUrls:['./send-to-segments.component.scss']
})
export class SendToSegmentsComponent implements OnInit {

  form: FormGroup;
  // segment groups
  segmentsGroup = [
    {optionValue1:"", optionValue2:"",selectedSegmentValues:[]},
    {optionValue1:"", optionValue2:"",selectedSegmentValues:[]},
    {optionValue1:"", optionValue2:"",selectedSegmentValues:[]},
    {optionValue1:"", optionValue2:"",selectedSegmentValues:[]},
    {optionValue1:"", optionValue2:"",selectedSegmentValues:[]},
    {optionValue1:"", optionValue2:"",selectedSegmentValues:[]},
    {optionValue1:"", optionValue2:"",selectedSegmentValues:[]},
    {optionValue1:"", optionValue2:"",selectedSegmentValues:[]}
  ]

  segments$: Observable<SegmentAggregateModel>;
  segmentList: SegmentAggregateModel;

  // options
  segmentKeyList: string[];
  selectedSegmentValues: string[];


  selectedSegmentKey: string;

  selectedSegments:SelectedSegmentModel;


  segmentsLoadingState$:Observable<boolean>;
  segmentsLoadingStateSubscription:Subscription;
  segmentsSubscription:Subscription;
  slug: string
  transactionId:string

  constructor(
    private modalService:ModalService,
    private fb: FormBuilder,
    private _router:Router,
    private store: Store<fromRoot.State>,
    private spinnerOverlayService: SpinnerOverlayService,
    private route:ActivatedRoute
    ) {


      this.segments$ = this.store.pipe(select(fromRoot.getSegmentsSelector));
      this.segmentsLoadingState$ = this.store.pipe(select(fromRoot.getSegmentsSelectorLoadingState));

      this.segmentsLoadingStateSubscription = this.segmentsLoadingState$.subscribe((res)=>{
        if(res){
          this.spinnerOverlayService.show();
        } else {
          var me = this;
          setTimeout(function(){
            me.spinnerOverlayService.hide();
          },1000);
        }

      })

      this.segmentsSubscription = this.segments$.subscribe((res)=>{
        this.segmentKeyList  = [];
        if(res != null){
          this.segmentKeyList  = Object.keys(res);
          this.segmentList = res;
        }
      });
    }

  ngOnDestroy(): void {
    this.segmentsLoadingStateSubscription.unsubscribe();
    this.segmentsSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeValues();
    this.initializeForm();
    this.loadSegments();
  }


  initializeValues(){
    if( this.transactionId == null){
      this.transactionId = uuid.v4();
    }
    this.slug = this.route.snapshot.paramMap.get('msgid');
  }

   // convenience getters for easy access to form fields
   get f() { return this.form.controls; }
   get t() { return this.f.segments as FormArray; }

  initializeForm(){
    this.form = this.fb.group({
      segments: new FormArray([])
    });

    for(var i=0; i<8;i++){
      this.t.push(this.fb.group({
        keys: ['', Validators.required],
        keysValue: ['']
      }));
    }
  }

  loadSegments() {

    var requestInput:loadSegmentsRequestInterface = {
      request:{

      }
    }

    this.store.dispatch(NotificationsPageActions.LoadSegments({request: requestInput}));

  }


  onClickSendNotification(segments:SegmentModel[]){


    var answer = confirm("Confirm to send the notification to the segments of your choices." );
    if (answer) {

      //  // filter the invalid inputs
      // var filteredSegmentsGroup = this.segmentsGroup.filter(function (el) {
      //   return el.optionValue1 != null && el.optionValue2 != null && el.optionValue1 != "" && el.optionValue2 != "";
      // });

      // // map the options into the request params
      // var segments:SegmentModel[] = [];
      // for(let i=0; i< filteredSegmentsGroup.length; i++){
      //   segments.push({tagName: filteredSegmentsGroup[i].optionValue1, tagValue: filteredSegmentsGroup[i].optionValue2});
      // }

      var command:SendNotificationToSegmentsCommandInterface = {
        request :{
          transactionId: this.transactionId,
          messageId : this.slug,
          segments : segments,
          cultureId : 99
        }
      }

      if(segments.length > 0){
        this.store.dispatch(NotificationsPageActions.SendNotificationToSegments({command}));
      }
    } else {

    }

  }


  onChangeFirstOptions(ev:string, index:number){
   // alert(JSON.stringify(this.segmentList[ev]));

    this.segmentsGroup[index].optionValue1 = ev;

    this.segmentsGroup[index].selectedSegmentValues =  this.segmentList[ev];

  }

  onChangeSecondOptions(ev:string, index:number){
    // alert(JSON.stringify(this.segmentList[ev]));

     this.segmentsGroup[index].optionValue2 = ev;

   }

  onClickCancelButtonHandler(ev:Event){
    this._router.navigate(['./notifications/list-notifications'])
  }

  onClickContinueButtonHandler(ev:Event){

   // filter the invalid inputs
   var filteredSegmentsGroup = this.segmentsGroup.filter(function (el) {
    return el.optionValue1 != null && el.optionValue2 != null && el.optionValue1 != "" && el.optionValue2 != "";
  });

  // map the options into the request params
  var segments:SegmentModel[] = [];
  for(let i=0; i< filteredSegmentsGroup.length; i++){

    segments.push({tagName: filteredSegmentsGroup[i].optionValue1, tagValue: filteredSegmentsGroup[i].optionValue2});
  }


  this.onClickSendNotification(segments);
  }


}
