<div class="flex flex-col pt-12  pl-16 pr-16">
  <div>
    <p class="header text-5xl font-bold leading-tight">{{ 'TITLE.IMAGES' | translate}}</p>
  </div>
</div>
<div class="table w-full pl-16 pr-16 mt-10">
  <app-images-list [currentPage]="IMcurrentPage$ | async"  [data]="(images$|async)?.items" (IMGoToNextEvent)="IMGoToNext($event)" (IMGoToPrevEvent)="IMGoToPrev($event)" (CheckImageEvent)="CheckImage($event)" (DeleteImageEvent)="DeleteImage($event)" ></app-images-list>
</div>
<div class="empty_space h-20"></div>



<app-floating-button  (click)="onClickFloatingButtonHandler($event)"  class="absolute top-0 right-0 mt-12 mr-12"></app-floating-button>



<jw-modal id="view-image-modal">

  <form class="max-w-4xl ml-auto mr-auto ">
    <div class="flex flex-col items-center justify-center pt-12  pl-16 pr-16">
        <img  [src]="selectedImageUrl"
        class="block border-2 rounded-lg"
        style="height:240px; width:480px; background-position: center center;
        background-repeat: no-repeat;object-fit: cover"
        />
    </div>
    <div class="button-group flex flex-row justify-end mt-10 pl-16 pr-16 mb-8">
      <app-round-outline-button
        color="dark"
        type="button"
        (click)="closeModal('view-image-modal')"
        > {{'PAGE_IMAGES.LIST_IMAGES.CLOSE_WINDOW_BUTTON'| translate}}</app-round-outline-button
      >
    </div>
  </form>
</jw-modal>
