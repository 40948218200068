import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import {  NotificationTemplateModel } from '../models/notificationTemplate.model';
import { of } from 'rxjs/internal/observable/of'
import { PagedListModel } from '../models/pagedList.model'
import { loadNotificationTemplatesRequestInterface } from '../types/params/request//loadNotificationTemplatesRequest.interface'

import { CreateNewNotificationTemplateCommandInterface } from '../types/params/request/CreateNewNotificationTemplateCommand.interface'
import { CreateNewNotificationTemplateResponseInterface } from '../types/params/response/CreateNewNotificationTemplateResponse.interface'
import { loadNotificationTemplatesResponseInterface } from '../types/params/response/loadNotificationTemplatesResponse.interface'
import { SendNotificationToAllCommandInterface } from '../types/params/request/SendNotificationToAllCommand.interface';
import { SendNotificationToIndividualCommandInterface } from '../types/params/request/SendNotificationToIndividualCommand.interface'
import { SendNotificationToGroupCommandInterface } from '../types/params/request/SendNotificationToGroupCommand.interface'
import { loadActionHistoryRequestInterface } from '../types/params/request/loadActionHistoryRequest.interface'
import { loadActionHistoryResponseInterface } from '../types/params/response/loadActionHistoryResponse.interface'
import { ActionHistoryModel } from '../models/actionHistory.model'
import { deleteNotificationCommandInterface } from '../types/params/request/deleteNotificationCommand.interface'
import { DeleteNotificationResponseInterface } from '../types/params/response/DeleteNotificationResponse.interface'
import { loadSegmentsRequestInterface } from '../types/params/request/loadSegmentsRequest.interface';
import { SegmentAggregateModel, SegmentModel } from '../models/segment.model';
import { loadSegmentsResponseInterface } from '../types/params/response/loadSegmentsResponse.interface'
import { SendNotificationToSegmentsCommandInterface } from '../types/params/request/SendNotificationToSegmentsCommand.interface';



class notificationTemplatePagedList implements PagedListModel<NotificationTemplateModel[]> {
  currentPage: number
  totalPage: number
  pageSize: number
  items: NotificationTemplateModel[] = []
}

class actionHistoryPagedList implements PagedListModel<ActionHistoryModel[]> {
  currentPage: number
  totalPage: number
  pageSize: number
  items: ActionHistoryModel[] = []
}

class notificationTemplateList {
  status:number
  message:string | null
  notifications:NotificationTemplateModel[]  = []
}


@Injectable()
export class NotificationsService {
  baseUrl:string;
  constructor(private http: HttpClient) {
    this.baseUrl =  environment.apiUrl+'notifications';
  }

  errorHandler(error: HttpErrorResponse, caught:any) {
      // return Observable.throw(error.statusText);
    return throwError(error.statusText || "server error.");
  }

  sendNotificationToAll(data:SendNotificationToAllCommandInterface):Observable<Boolean> {
    const url =  this.baseUrl+'/send-to-all';

    console.log(data);
    var body = data.request;
   return this.http.post(url, body)
        .pipe(
          map((response: CreateNewNotificationTemplateResponseInterface) => {
            // return response.result
            console.log(response);
              return response.status != 0;

          }),
          catchError(this.errorHandler)
    )
  }

  sendNotificationToSingle(data:SendNotificationToIndividualCommandInterface):Observable<Boolean> {
    const url =  this.baseUrl+'/send-to-individual';

    console.log(data);
    var body = data.request;
   return this.http.post(url, body)
        .pipe(
          map((response: CreateNewNotificationTemplateResponseInterface) => {
            // return response.result
            console.log(response);
              return response.status != 0;

          }),
          catchError(this.errorHandler)
    )
  }



  sendNotificationToGroup(data:SendNotificationToGroupCommandInterface):Observable<Boolean> {
    const url =  this.baseUrl+'/send-to-group';

    console.log(data);
    var body = data.request;
   return this.http.post(url, body)
        .pipe(
          map((response: CreateNewNotificationTemplateResponseInterface) => {
            // return response.result
            console.log(response);
              return response.status != 0;

          }),
          catchError(this.errorHandler)
    )
  }


  sendNotificationToSegments(data:SendNotificationToSegmentsCommandInterface):Observable<Boolean> {
    const url =  this.baseUrl+'/send-to-segments';

    console.log(data);
    var body = data.request;
   return this.http.post(url, body)
        .pipe(
          map((response: CreateNewNotificationTemplateResponseInterface) => {
            // return response.result
            console.log(response);
              return response.status != 0;

          }),
          catchError(this.errorHandler)
    )
  }


  createNewNotificationsTemplate(data:CreateNewNotificationTemplateCommandInterface): Observable<Boolean> {
    const url =  this.baseUrl+'/pn-templates';

    console.log(data);
    var body = data.command;
   return this.http.post(url, body)
        .pipe(
          map((response: CreateNewNotificationTemplateResponseInterface) => {
            // return response.result
            console.log(response);
              return response.status != 0;

          }),
          catchError(this.errorHandler)
    )
  }

  deleteNotification(data:deleteNotificationCommandInterface): Observable<Boolean> {
    const url =  this.baseUrl+'/delete-notification';

    console.log(data);
    var body = data.request;
   return this.http.post(url, body)
        .pipe(
          map((response: DeleteNotificationResponseInterface) => {
            // return response.result
            console.log(response);
              return response.status != 0;

          }),
          catchError(this.errorHandler)
    )
  }

  getSegments(data:loadSegmentsRequestInterface): Observable<SegmentAggregateModel> {
    const urlString =  this.baseUrl+'/unique-tags';

   return this.http.get(urlString)
        .pipe(
          map((response: loadSegmentsResponseInterface) => {
            return response.result
          }),
          catchError(this.errorHandler)
    )
  }

  getNotificationsTemplate(data:loadNotificationTemplatesRequestInterface): Observable<PagedListModel<NotificationTemplateModel[]>> {
    const urlString =  this.baseUrl+'/pn-templates'+'?currentPage='+data.request.currentPage+'&pageSize='+data.request.pageSize;    //'/notification-templates'

  return this.http.get(urlString)
        .pipe(
          map((response: loadNotificationTemplatesResponseInterface) => {
            return response.result
          }),
          catchError(this.errorHandler)
    )
  }


  getDeliveredNotifications(data:loadActionHistoryRequestInterface): Observable<PagedListModel<ActionHistoryModel[]>> {
    const url = this.baseUrl+ '/action-histories'+'?currentPage='+data.request.currentPage+'&pageSize='+data.request.pageSize;
   let dn =  new actionHistoryPagedList();


  return this.http.get(url).pipe(
      map((response: loadActionHistoryResponseInterface) => {
        return response.result
      }),
      catchError(this.errorHandler)
    )
  }
}
