import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import {  NotificationTemplateModel } from '../models/notificationTemplate.model';
import { of } from 'rxjs/internal/observable/of'
import { PagedListModel } from '../models/pagedList.model'
import { loadNotificationTemplatesRequestInterface } from '../types/params/request/loadNotificationTemplatesRequest.interface'
import { loadNotificationTemplatesResponseInterface } from '../types/params/response/loadNotificationTemplatesResponse.interface'
import { CreateNewNotificationTemplateCommandInterface } from '../types/params/request/CreateNewNotificationTemplateCommand.interface'
import { CreateNewNotificationTemplateResponseInterface } from '../types/params/response/CreateNewNotificationTemplateResponse.interface'
import { ImageModel } from '../models/image.model'
import { loadImagesListRequestInterface } from '../types/params/request/loadImagesListRequest.interface'
import { loadImagesListResponseInterface } from '../types/params/response/loadImagesListResponse.interface'
import { deleteImageCommandInterface } from '../types/params/request/deleteImageCommand.interface'
import { DeleteImageResponseInterface } from '../types/params/response/DeleteImageResponse.interface'


@Injectable()
export class ImagesService {
  constructor(private http: HttpClient) {}

  errorHandler(error: HttpErrorResponse, caught:any) {
      console.log(error);
      return throwError(error.statusText || "server error.");
      //return Observable.throw(error.statusText);
    // return Observable.throw(error.message || "server error.");
  }

  deleteImage(data:deleteImageCommandInterface): Observable<Boolean> {
    const url =  environment.apiUrl+'images/delete-image';

    console.log(data);
    var body = data.request;
   return this.http.post(url, body)
        .pipe(
          map((response: DeleteImageResponseInterface) => {
            // return response.result
            console.log(response);
              return response.status != 0;

          }),
          catchError(this.errorHandler)
    )
  }

  getImagesList(data:loadImagesListRequestInterface): Observable<PagedListModel<ImageModel[]>> {
    const url =  environment.apiUrl+'images/upload-images-list?currentPage='+data.request.currentPage+'&pageSize='+data.request.pageSize;

    return this.http.get(url)
          .pipe(
            map((response: loadImagesListResponseInterface) => {
              return response.result
            }),
            catchError(this.errorHandler)
      )
    // let imagesList:ImageModel[] = [{
    //   id:"7ade3cfc-c667-40ed-9494-0e7ef74e3075",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a new image"
    // },{
    //   id:"3ade6cfc-c667-41ed-9494-0e7ef74e3075",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a another image1"
    // },
    // {
    //   id:"7ade3cfc-c667-13ed-9494-0e7ef74e3075",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a new image2"
    // },{
    //   id:"3ade6cfc-c667-43ed-9494-0e7ef54e3075",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a another image3"
    // },
    // {
    //   id:"7ade3cfc-c667-44ed-9494-0e7ef64e3075",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a new image4"
    // },{
    //   id:"3ade6cfc-c667-45ed-9494-0e7ef74e3075",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a another image5"
    // },{
    //   id:"7ade3cfc-c667-40ed-9494-1e7ef74e3075",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a new image"
    // },{
    //   id:"3ade6cfc-c667-41ed-9494-0e7ef74e3076",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a another image1"
    // },
    // {
    //   id:"7ade3cfc-c667-42ed-9494-0e7ef74e3070",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a new image2"
    // },{
    //   id:"3ade6cfc-c667-43ed-9494-0e7ef74e3078",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a another image3"
    // },
    // {
    //   id:"7ade3cfc-c667-44ed-9494-0e7ef74e8775",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a new image4"
    // },{
    //   id:"3ade6cfc-c667-45ed-9494-0e7ef74e8875",
    //   remoteUrl:"https://storage.googleapis.com/redone_th_oneapp_prod/images/img-logo20200929170714217.png",
    //   description: "this is a another image5"
    // }]

    // let filtered:ImageModel[]
    // if(data.request.currentPage == 1 ){
    //   filtered = [...imagesList.slice(0,5)]
    // } else if(data.request.currentPage == 2){
    //   filtered = [...imagesList.slice(5,11)]
    // }

    // let imagesPagedList: PagedListModel<ImageModel[]> = {
    //   currentPage:data.request.currentPage,
    //   totalPage:10,
    //   pageSize: 5,
    //   items: filtered
    // }


    // return of(imagesPagedList);
  }
}
