import { createAction, props } from '@ngrx/store';
import { CreateNewStaffCommandInterface } from 'src/app/shared/types/params/request/CreateNewStaffCommand.interface';
import { loadStaffListRequestInterface } from 'src/app/shared/types/params/request/loadStaffListRequest.interface';
import {  UpdateStaffInfoCommandInterface } from 'src/app/shared/types/params/request/UpdateStaffInfoCommand.interface';

/* load images*/
export const loadStaffList = createAction(
  "[Admin Page] Load staff List",
  props<{request: loadStaffListRequestInterface}>()
  );

  export const goNextSTPage = createAction(
    "[Admin Page] Go Next staff list Page",
    props<{request: loadStaffListRequestInterface}>()
    );


  export const goPrevSTPage = createAction(
    "[Admin Page] Go Prev staff list Page",
    props<{request: loadStaffListRequestInterface}>()
    );

  export const updateStaffInfo = createAction(
      "[Admin Page] Update staff info",
      props<{request: UpdateStaffInfoCommandInterface}>()
  );

  export const discardNewStaff = createAction(
      "[Admin Page] Discard staff"
  );

  export const createNewStaff = createAction(
    "[Admin Page] Create new staff",
    props<{request: CreateNewStaffCommandInterface}>()
  );


export type AdminActions = ReturnType<
| typeof loadStaffList
| typeof goNextSTPage
| typeof goPrevSTPage
| typeof updateStaffInfo
| typeof createNewStaff
| typeof discardNewStaff
>
