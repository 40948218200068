<div class="relative flex flex-col pt-12 pb-6 pl-16 pr-16">
<div class="absolute top-0 right-0 mr-6 mt-6">
  <button class="ml-3 w-5" (click)="onClickClearMessageTemplateHandler()">
    <img src="/assets/images/delete-icon.png" class="w-full block" />
  </button>
</div>

  <div class="">
    <p class="header text-5xl font-bold leading-tight">
      {{
        "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.TITLE" | translate
      }}
    </p>
  </div>
  <div class="subheader-caption border-b-2 mt-12 border-solid border-gray-300">
    <p class="text-gray-600 text-xs pb-3 leading-tight tracking-widest">
      {{
        "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.SUBHEADER_CAPTION"
          | translate
      }}
    </p>
  </div>
  <div class="subheader mt-2">
    <p class="text-3xl text-gray-900 leading-tight">
      {{
        "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.SUBHEADER_TITLE"
          | translate
      }}
    </p>
  </div>

  <form class="flex flex-col pl-3 mt-8">
    <app-toggleable-labelfield
      (click)="openClickAddTemplateModal('add-template-modal')"
      [isPositive]="true"
      class="text-gray-600 text-sm"
      > {{
        "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE"
          | translate
      }}</app-toggleable-labelfield
    >

    <app-toggleable-labelfield
      *ngIf="initializedNotificationRecord.notificationType != 1"
      (click)="openClickAddActionModal('add-action-modal')"
      [isPositive]="true"
      class="block pt-4 text-gray-600 text-sm"
      > {{
        "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION"
          | translate
      }}</app-toggleable-labelfield
    >
    <div class="mt-3 text-xs text-red-700"
        *ngIf="submitted && (
        initializedNotificationRecord.templatePageTitle == null ||
        initializedNotificationRecord.templatePageTitle == '' ||
        initializedNotificationRecord.templateBottomButtonTitle == null ||
        initializedNotificationRecord.templateBottomButtonTitle == '')
        ">
        {{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ERROR_REQUIRED_FIELD"
            | translate
        }}
    </div>

    <div *ngIf="(errorSelector$ | async)!=null">
      <p class="ml-10 mt-3 text-xs text-red-700">{{
        "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ERROR_PARTIAL_LABEL"
          | translate
      }} {{errorSelector$ | async}}</p>
    </div>
  </form>

  <div class="button-group flex mt-12 self-end">
    <app-round-outline-button
      (click)="onClickPreviousButtonHandler($event)"
      color="dark"
    >
    {{
      "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.PREVIOUS_BUTTON"
        | translate
    }}
    </app-round-outline-button>
    <app-round-button
      (click)="onClickContinueButtonHandler($event)"
      color="yellow"
      class="pl-4"
    >
    {{
      "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.CREATE_BUTTON"
        | translate
    }}
    </app-round-button>
  </div>
</div>




<!-------------------   MODAL WINDOW - Add template  ------------->
<jw-modal id="add-template-modal">
  <div class="max-w-4xl ml-auto mr-auto">
    <form class="p-12" [formGroup]="addTemplateForm" (submit)="submitAddTemplateEvent($event)">
      <p class="text-5xl font-bold mb-12 leading-tight">
        {{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.MODAL_TITLE"
            | translate
        }}</p>
      <!-- <app-dark-textfield
        class="block"
        type="text"
        placeholderText="Enter page title here"
        >Page Title
      </app-dark-textfield> -->

      <div class="flex flex-col">
        <div class="">
          <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
            {{
              "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.PAGE_TITLE_LABEL"
                | translate
            }}

          </label>
        </div>
        <div class="pt-1">
          <input
          formControlName="templatePageTitle"
          type="text" placeholderText="Enter page title here"
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
        </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addTemplateFormSubmitted && addTemplateForm.controls.templatePageTitle.errors?.required">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ERRORS.REQUIRED_PAGE_TITLE"
              | translate
          }}
        </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addTemplateFormSubmitted && addTemplateForm.controls.templatePageTitle.errors?.maxlength">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ERRORS.MAX_PAGE_TITLE"
              | translate
          }}
        </div>
      </div>

      <div
      *ngIf="templateContents.controls.length > 0"
      style="min-height: 4rem;"
        class="mb-6 mt-6 p-4 pl-10 pr-10  flex flex-col place-items-start rounded-md bg-gray-200 "
      >
      <ng-container formArrayName="templateContents">
        <div class="relative w-full border-b-2 border-gray-400 pb-2" *ngFor="let control of templateContents.controls; index as i">

          <ng-container *ngIf="control?.value.templateContentType == 1" >
            <div class=" w-full text-left pt-6">{{templateContents.controls[i] |transformHtmlObjectForDisplay }} </div>
            <input  hidden="true" [formControl]="templateContents.controls[i]"   [value]= "templateContents.controls[i] |transformHtmlObjectForDisplay" readonly />
            <div class="flex flex-row absolute  top-0 right-0 pr-2 ">
              <button class="mr-2 text-gray-700" (click)="openUpdateContentModel('update-content-html-modal',i)"><span  class="text-xs"> {{
                "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.UPDATE_LABEL"
                  | translate
              }}</span></button>
              <button class="mr-2  text-gray-700" (click)="removeContentHtmlRecord(i)"><span class="text-xs"> {{
                "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.REMOVE_LABEL"
                  | translate
              }}</span></button>
              <button class="mr-2  text-gray-700"  type="button"  (click)="moveUpContentHtmlRecord(i)"><span class="text-xs"> {{
                "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.UP_LABEL"
                  | translate
              }}</span></button>
              <button class="mr-2  text-gray-700"  type="button"  (click)="moveDownContentHtmlRecord(i)"><span class="text-xs"> {{
                "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.DOWN_LABEL"
                  | translate
              }}</span></button>
            </div>
          </ng-container>

          <ng-container *ngIf="control?.value.templateContentType == 2">
            <div class="h-full w-full text-left pt-6 ">
            <img  [src]="templateContents.controls[i] |transformImageObjectForDisplay"
            class="block border-2 rounded-lg ml-auto mr-auto"
            style="height:240px; width:480px; background-position: center center;
            background-repeat: no-repeat;object-fit: cover"
            />
            </div>

            <input  hidden="true" [formControl]="templateContents.controls[i]"   [value]= "templateContents.controls[i] |transformImageObjectForDisplay" readonly />
            <div class="flex flex-row absolute  top-0 right-0 pr-2 ">
              <!-- <button class="mr-2 text-gray-700" (click)="openUpdateContentImageModel('update-content-image-modal',i)"><span  class="text-xs">Update</span></button> -->
              <button class="mr-2  text-gray-700"  type="button" (click)="removeContentImageRecord(i)"><span class="text-xs">{{
                "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.REMOVE_LABEL"
                  | translate
              }}</span></button>
              <button class="mr-2  text-gray-700"  type="button"  (click)="moveUpContentImageRecord(i)"><span class="text-xs">{{
                "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.UP_LABEL"
                  | translate
              }}</span></button>
              <button class="mr-2  text-gray-700"  type="button"  (click)="moveDownContentImageRecord(i)"><span class="text-xs">{{
                "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.DOWN_LABEL"
                  | translate
              }}</span></button>
            </div>
          </ng-container>


        </div>
      </ng-container>
    </div>

      <div
        class="rounded-md bg-gray-200 ml-auto mr-auto mt-6 p-6 flex flex-col items-center"
      >
        <app-round-outline-button
          (click)="openAddContentModel('add-new-content-modal')"
          class="text-gray-800"
          type="button"
          color="dark"
        >
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ADD_NEW_CONTENT_BUTTON"
              | translate
          }}
        </app-round-outline-button>
        <p class="text-gray-600 text-center inline-block pt-2 pb-2">or</p>
        <app-round-outline-button
          (click)="openAddImageModel('add-new-image-modal')"
          type="button"
          class="text-gray-800"
          color="dark"
        >
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ADD_NEW_IMAGE_BUTTON"
              | translate
          }}
        </app-round-outline-button>
      </div>

      <!-- <app-dark-textfield
        class="block mt-6"
        type="text"
        placeholderText="Enter bottom button title here"
        >Bottom Button Title</app-dark-textfield
      > -->

      <div class="flex flex-col mt-6">
        <div class="">
          <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
            {{
              "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.BOTTOM_BUTTON_LABEL"
                | translate
            }}
          </label>
        </div>
        <div class="pt-1">
          <input
          formControlName="templateBottomButtonTitle"
          type="text" placeholderText="Enter bottom button title here"
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
        </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addTemplateFormSubmitted &&  addTemplateForm.controls.templateBottomButtonTitle.errors?.required">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ERRORS.REQUIRED_BOTTOM_BUTTON_TITLE"
              | translate
          }}
        </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addTemplateFormSubmitted &&  addTemplateForm.controls.templateBottomButtonTitle.errors?.maxlength">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ERRORS.MAX_BOTTOM_BUTTON_TITLE"
              | translate
          }}
        </div>
      </div>

      <div class="button-group flex flex-row justify-end mt-12">
        <app-round-outline-button
          color="dark"
          type="button"
          (click)="closeModal('add-template-modal')"
          >  {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.CLOSE_WINDOW_BUTTON"
              | translate
          }}</app-round-outline-button
        >
        <app-round-button
          class="pl-4"
          color="yellow"
          type="button"
          (click)="submitAddTemplateEvent($event)"
          >  {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.SAVE_BUTTON"
              | translate
          }}</app-round-button
        >
      </div>
    </form>
  </div>
</jw-modal>

<!-- <jw-modal id="add-new-image-modal">
  <div class="h-screen">
    <h1>Add new image</h1>
    <app-dark-textfield
      class="p-6"
      type="text"
      placeholderText="Enter an image url"
      >Image url</app-dark-textfield
    >
    <button (click)="closeModal('add-new-image-modal')">Close</button>
  </div>
</jw-modal>
 -->





<!-------------------   MODAL WINDOW - Add new content (image)  ------------->
<jw-modal id="add-new-image-modal">
  <form class="max-w-4xl ml-auto mr-auto " [formGroup]="addImageForm">
    <div class="flex flex-col pt-12 pb-12 pl-16 pr-16">
      <div class="">
        <p class="header text-5xl font-bold leading-tight">{{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ADD_IMAGE_MODAL.TITLE"
            | translate
        }}</p>
      </div>
      <div class="mt-12 flex flex-col">

          <div class="table w-full">

            <app-create-template-images-list [data]="(imagesList$ | async)?.items" [currentPage]="(imagesListCurrentPage$ | async)" (IMGoToNextEvent)="onIMGoNextEvent()" (IMGoToPrevEvent)="onIMGoPrevEvent()"  (CopyUrlEvent)="onClickCopyImageUrl($event)"></app-create-template-images-list>


          </div>
          <div *ngIf="addImageForm?.get('templateImageUrl').value" class="mt-12 flex flex-col items-center">
            <img  [src]="addImageForm.get('templateImageUrl').value"
            class="block border-2 rounded-lg"
            style="height:240px; width:480px; background-position: center center;
            background-repeat: no-repeat;object-fit: cover"
            />
          </div>
          <div class="mt-12">


          <div class="flex flex-col">
            <div class="">
              <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
                {{
                  "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ADD_IMAGE_MODAL.IMAGE_URL_LABEL"
                    | translate
                }}
              </label>
            </div>
            <div class="pt-1">
              <input
              formControlName="templateImageUrl"
              type="text" placeholderText="Enter an image url"
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="button-group flex flex-row justify-end mt-12 pl-16 pr-16 mb-12">
      <app-round-outline-button
        color="dark"
        type="button"
        (click)="closeAddImageModel('add-new-image-modal')"
        > {{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ADD_IMAGE_MODAL.CLOSE_WINDOW_BUTTON"
            | translate
        }}</app-round-outline-button
      >
      <app-round-button
        class="pl-4"
        color="yellow"
        type="button"
        (click)="submitAddImageUrlEvent($event)"
        > {{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ADD_IMAGE_MODAL.SAVE_BUTTON"
            | translate
        }}</app-round-button
      >
    </div>
  </form>
</jw-modal>




<!-------------------   MODAL WINDOW - Add new content (html tag)  ------------->
<jw-modal  id="add-new-content-modal" >
  <form class="w-6xl ml-auto mr-auto" [formGroup]="addContentForm">
    <div class="flex flex-col pt-12 pb-12 pl-16 pr-16">
        <div class="">
          <p class="header text-5xl font-bold leading-tight">{{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ADD_CONTENT_MODAL.TITLE"
              | translate
          }}</p>
        </div>
        <ckeditor
          class="unreset mt-12"
          #editor
          [config]="{ removeButtons: 'Image,Anchor,Spell Checker,Maximize' }"
          id="description"
          name="description"
          type="divarea"
          formControlName="editorField"
        >
        </ckeditor>
      </div>

    <div class="button-group flex flex-row justify-end mt-12 pl-16 pr-16 mb-12">
      <app-round-outline-button
        color="dark"
        type="button"
        (click)="closeAddContentModel('add-new-content-modal')"
        >{{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ADD_CONTENT_MODAL.CLOSE_WINDOW_BUTTON"
            | translate
        }}</app-round-outline-button
      >
      <app-round-button
        class="pl-4"
        color="yellow"
        type="submit"
        (click)="submitAddHtmlContentEvent($event)"
        >{{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.ADD_CONTENT_MODAL.SAVE_BUTTON"
            | translate
        }}</app-round-button
      >
    </div>

</form>
</jw-modal>



<!-------------------   MODAL WINDOW - Update new content (html tag)  ------------->
<jw-modal  id="update-content-html-modal" >
  <form class="w-6xl ml-auto mr-auto" [formGroup]="updateContentHtmlForm">
    <div class="flex flex-col pt-12 pb-12 pl-16 pr-16">
        <div class="">
          <p class="header text-5xl font-bold leading-tight">{{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.UPDATE_CONTENT_MODAL.TITLE"
              | translate
          }}</p>
        </div>
        <ckeditor
          class="unreset"
          #editor
          [config]="{ removeButtons: 'Image,Anchor,Spell Checker,Maximize' }"
          id="description"
          name="description"
          type="divarea"
          formControlName="editorField"

        >
        <!-- formControlName="editorField" -->
        </ckeditor>
      </div>

    <div class="button-group flex flex-row justify-end mt-12 pl-16 pr-16 mb-12">
      <app-round-outline-button
        color="dark"
        type="button"
        (click)="closeUpdateContentModel('update-content-html-modal')"
        >{{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.UPDATE_CONTENT_MODAL.CLOSE_WINDOW_BUTTON"
            | translate
        }}</app-round-outline-button
      >
      <app-round-button
        class="pl-4"
        color="yellow"
        type="submit"
        (click)="submitUpdateContentEvent($event)"
        >{{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.UPDATE_CONTENT_MODAL.SAVE_BUTTON"
            | translate
        }}</app-round-button
      >
    </div>

</form>
</jw-modal>



<!-------------------   MODAL WINDOW - Update new content (image)  ------------->
<jw-modal id="update-content-image-modal">
  <form class="max-w-4xl ml-auto mr-auto " [formGroup]="updateContentImageForm">
    <div class="flex flex-col pt-12 pb-12 pl-16 pr-16">
      <div class="">
        <p class="header text-5xl font-bold leading-tight">{{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.UPDATE_IMAGE_MODAL.TITLE"
            | translate
        }}</p>
      </div>
      <div class="mt-12 flex flex-col">

          <div class="table w-full">

            <app-create-template-images-list [data]="(imagesList$ | async)?.items" [currentPage]="(imagesListCurrentPage$ | async)" (IMGoToNextEvent)="onIMGoNextEvent()" (IMGoToPrevEvent)="onIMGoPrevEvent()"  (CopyUrlEvent)="onClickCopyImageUrlUpdate($event)"></app-create-template-images-list>


          </div>
          <div *ngIf="addImageForm?.get('templateImageUrl').value" class="mt-12 flex flex-col items-center">
            <img  [src]="addImageForm.get('templateImageUrl').value"
            class="block border-2 rounded-lg"
            style="height:240px; width:480px; background-position: center center;
            background-repeat: no-repeat;object-fit: cover"
            />
          </div>
          <div class="mt-12">


          <div class="flex flex-col">
            <div class="">
              <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
                {{
                  "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.UPDATE_IMAGE_MODAL.IMAGE_URL_LABEL"
                    | translate
                }}
              </label>
            </div>
            <div class="pt-1">
              <input
              formControlName="templateImageUrl"
              type="text" placeholderText="Enter an image url"
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="button-group flex flex-row justify-end mt-12 pl-16 pr-16 mb-12">
      <app-round-outline-button
        color="dark"
        type="button"
        (click)="closeUpdateImageModel('update-content-image-modal')"
        >{{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.UPDATE_IMAGE_MODAL.CLOSE_WINDOW_BUTTON"
            | translate
        }}</app-round-outline-button
      >
      <app-round-button
        class="pl-4"
        color="yellow"
        type="button"
        (click)="submitAddImageUrlEvent($event)"
        >{{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_TEMPLATE_MODAL.UPDATE_IMAGE_MODAL.SAVE_BUTTON"
            | translate
        }}</app-round-button
      >
    </div>
  </form>
</jw-modal>








<jw-modal id="add-action-modal">
  <div class="min-h-screen max-w-4xl ml-auto mr-auto">
    <form class="p-12" [formGroup]="addActionForm">
      <p class="text-5xl font-bold mb-12 leading-tight">{{
        "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.MODAL_TITLE"
          | translate
      }}</p>

      <div class="form-label-group">
        <p class="form-label"></p>
      </div>

      <app-dark-labelfield
        class="mb-6 block"
        type="text"
        [labelValue]="notificationTypeString"
        >{{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.MESSAGE_TYPE_LABEL"
            | translate
        }}
      </app-dark-labelfield>

      <div class="flex flex-col mt-4">
        <div class="">
          <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
            {{
              "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ADD_PRODUCT_ID"
                | translate
            }}
          </label>
        </div>
        <div class="pt-1">
          <input
          formControlName="actionProductId"
          type="text" placeholderText="Enter product id here"
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
        </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionProductId.errors?.required">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.REQUIRED_PRODUCT_ID"
              | translate
          }}
       </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionProductId.errors?.maxlength">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.MAX_PRODUCT_ID"
              | translate
          }}
        </div>
      </div>

      <div class="flex flex-col mt-4">
        <div class="">
          <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
            {{
              "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ADD_CONFIRMATION_MESSAGE_TITLE"
                | translate
            }}
          </label>
        </div>
        <div class="pt-1">
          <input
          formControlName="actionMessageTitle"
          type="text" placeholderText="Enter confirmation message title here"
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
        </div>
        <!--123-->
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionMessageTitle.errors?.required">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.REQUIRED_CONFIRMATION_MESSAGE"
              | translate
          }}
       </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionMessageTitle.errors?.maxlength">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.MAX_CONFIRMATION_MESSAGE"
              | translate
          }}
        </div>

      </div>

      <div class="flex flex-col mt-4">
        <div class="">
          <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
            {{
              "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ADD_CONFIRMATION_BODY_MESSAGE"
                | translate
            }}
          </label>
        </div>
        <div class="pt-1">
          <input
          formControlName="actionMessageBody"
          type="text" placeholderText="Enter body message here"
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
        </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionMessageBody.errors?.required">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.REQUIRED_CONFIRMATION_BODY"
              | translate
          }}
       </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionMessageBody.errors?.maxlength">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.MAX_CONFIRMATION_BODY"
              | translate
          }}
        </div>
      </div>

      <div class="flex flex-col mt-4">
        <div class="">
          <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
            {{
              "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ADD_POSITIVE_BUTTON_TEXT"
                | translate
            }}
          </label>
        </div>
        <div class="pt-1">
          <input
          formControlName="actionPositiveButtonText"
          type="text" placeholderText="Enter positive button text"
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
        </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionPositiveButtonText.errors?.required">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.REQUIRED_POSITIVE_BUTTON"
              | translate
          }}
       </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionPositiveButtonText.errors?.maxlength">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.MAX_POSITIVE_BUTTON"
              | translate
          }}
        </div>
      </div>



      <div class="flex flex-col mt-4">
        <div class="">
          <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
            {{
              "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ADD_POSITIVE_BUTTON_SUCCESS_MESSAGE_TEXT"
                | translate
            }}
          </label>
        </div>
        <div class="pt-1">
          <input
          formControlName="actionPositiveButtonSuccessMessageText"
          type="text" placeholderText="Enter positive button text"
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
        </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionPositiveButtonSuccessMessageText.errors?.required">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.REQUIRED_POSITIVE_BUTTON_SUCCESS_MESSAGE"
              | translate
          }}
       </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionPositiveButtonSuccessMessageText.errors?.maxlength">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.MAX_POSITIVE_BUTTON_SUCCESS_MESSAGE"
              | translate
          }}
        </div>
      </div>






      <div class="flex flex-col mt-4">
        <div class="">
          <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
            {{
              "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ADD_NEGATIVE_BUTTON_TEXT"
                | translate
            }}
          </label>
        </div>
        <div class="pt-1">
          <input
          formControlName="actionNegativeButtonText"
          type="text" placeholderText="Enter negative button text"
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
        </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionNegativeButtonText.errors?.required">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.REQUIRED_NEGATIVE_BUTTON"
              | translate
          }}
       </div>
        <div class="mt-1 text-xs text-red-700" *ngIf="addActionFormSubmitted && addActionForm.controls.actionNegativeButtonText.errors?.maxlength">
          {{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.ERRORS.MAX_NEGATIVE_BUTTON"
              | translate
          }}
        </div>
      </div>




      <div class="button-group flex flex-row justify-end mt-12 ">
        <app-round-outline-button
          color="dark"
          type="button"
          (click)="closeModal('add-action-modal')"
          >{{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.CLOSE_WINDOW_BUTTON"
              | translate
          }}</app-round-outline-button
        >
        <app-round-outline-button
        class="pl-4"
        color="dark"
        type="button"
        (click)="resetAndSaveActionFormEvent($event)"
        >{{
          "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.RESET_SAVE_BUTTON"
            | translate
        }}</app-round-outline-button
      >
        <app-round-button
          class="pl-4"
          color="yellow"
          type="button"
          (click)="submitAddActionEvent($event)"
          >{{
            "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_THREE.ADD_ACTION_MODAL.SAVE_BUTTON"
              | translate
          }}</app-round-button
        >
      </div>
    </form>
  </div>
</jw-modal>
