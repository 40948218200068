import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DarkTextfieldComponent } from './dark-textfield/dark-textfield.component';
import { ToggleableLabelfieldComponent } from './toggleable-labelfield/toggleable-labelfield.component';
import { DarkLabelfieldComponent } from './dark-labelfield/dark-labelfield.component';


@NgModule({
    imports: [CommonModule],
    declarations: [DarkTextfieldComponent, ToggleableLabelfieldComponent, DarkLabelfieldComponent],
    exports: [DarkTextfieldComponent,ToggleableLabelfieldComponent,DarkLabelfieldComponent]
})
export class FormFieldModule {



 }
