import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { ModalService } from 'src/app/_modal';
import * as fromRoot from "src/app/shared/state";
import { loadNotificationTemplatesRequestInterface } from 'src/app/shared/types/params/request/loadNotificationTemplatesRequest.interface';

import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

import { SpinnerOverlayService } from '../../../shared/components/spinner/spinner-overlay/spinner-overlay.service';
import { HttpClient } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: "app-silent-refresh",
  templateUrl: "./silent-refresh.component.html",
  styleUrls: ["./silent-refresh.component.scss"]
})
export class SilentRefreshComponent implements OnInit, OnDestroy {

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private httpClient:HttpClient,
    private modalService:ModalService,
    private _router:Router,
    private store: Store<fromRoot.State>,
    private spinnerOverlayService:SpinnerOverlayService
    ) {

    }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {
  }



}
