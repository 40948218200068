import { NotificationTemplateModel } from "src/app/shared/models/notificationTemplate.model";
import { createAction, props } from "@ngrx/store";
import { PagedListModel } from 'src/app/shared/models/pagedList.model';

import { NotificationRecordModel } from 'src/app/shared/models/notificationRecord.model';
import { ActionHistoryModel } from 'src/app/shared/models/actionHistory.model';
import { SegmentAggregateModel, SegmentModel } from 'src/app/shared/models/segment.model';

export const NTLoadedSuccess = createAction(
  "[Notifications API] Notification Templates Loaded Success",
  props<{ notificationTemplates: PagedListModel<NotificationTemplateModel[]> }>()
);


export const DeleteNotificationFailed = createAction(
  "[Notifications API] Delete Notification Failed",
  props<{ errors: string }>()
);

export const DeleteNotificationSuccess = createAction(
  "[Notifications API] Delete Notification Success",
  props<{ messageId: string }>()
);


export const NTLoadedFailed = createAction(
  "[Notifications API] Notification Templates Loaded Failed",
  props<{ errors: string }>()
);

export const DNLoadedSuccess = createAction(
  "[Notifications API] Action History Loaded Success",
  props<{ actionHistories : PagedListModel<ActionHistoryModel[]> }>()
);

export const NewNotificationInitializedSuccess = createAction(
  "[Notifications API] New Notification Initialized Success",
  props<{ newNotification: NotificationRecordModel }>()
);

export const MissingIdentifierIdDetected = createAction(
  "[Notifications API] Missing identifier detected"

);


export const SelectedNotificationTypeUpdatedSuccess = createAction(
  "[Notifications API] Selected Notification Type Updated Success",
  props<{ newNotification: NotificationRecordModel }>()
);

export const NewNotificationRecordLoadedSuccess = createAction(
  "[Notifications API] New Notification Loaded Success",
  props<{ newNotification: NotificationRecordModel }>()
);

/* STEP TWO */
export const NotificationMessageContentUpdatedSuccess = createAction(
  "[Notifications API] New Notification notification message Updated Success",
  props<{ newNotification: NotificationRecordModel }>()
);


 /* STEP THREE */
 export const NewContentTemplateCreatedSuccess = createAction(
    "[Notifications API] New content template created success",
     props<{newNotification: NotificationRecordModel}>()
  );


  export const NewActionCreatedSuccess = createAction(
    "[Notifications API] New action created success",
    props<{newNotification: NotificationRecordModel}>()
  )

  export const ActionResetAndSaveSuccess = createAction(
    "[Notifications API] Action reset and save success",
    props<{newNotification: NotificationRecordModel}>()
  )

  export const ActionResetAndSaveFailed = createAction(
    "[Notifications API] Action reset and save failed",
    props<{errors: string}>()
  )


  export const NewMessageTemplateDiscardSuccess = createAction(
    "[Notifications API] Action new message template discard successfully"
  )


  export const NewNotificationTemplateCreatedSuccess = createAction(
    "[Notifications API] new notification template created success"
  )

  export const NewNotificationTemplateCreatedFailed = createAction(
    "[Notifications API] new notification template created failed",
    props<{errors: string}>()
  )

  export const SendNotificationToAllSuccess = createAction(
    "[Notifications API] notification sent to all success"
  )

  export const SendNotificationToAllFailed = createAction(
    "[Notifications API] notification sent to all failed",
    props<{errors: string}>()
  )

  export const SendNotificationToSingleSuccess = createAction(
    "[Notifications API] notification sent to single success"
  )

  export const SendNotificationToSingleFailed = createAction(
    "[Notifications API] notification sent to single failed",
    props<{errors: string}>()
  )

  export const SendNotificationToGroupSuccess = createAction(
    "[Notifications API] notification sent to single success"
  )

  export const SendNotificationToGroupFailed = createAction(
    "[Notifications API] notification sent to single failed",
    props<{errors: string}>()
  )



  export const SendNotificationToSegmentsSuccess = createAction(
    "[Notifications API] notification sent to segments success"
  )

  export const SendNotificationToSegmentsFailed = createAction(
    "[Notifications API] notification sent to segments failed",
    props<{errors: string}>()
  )


  export const LoadSelectedNotificationTemplateSuccess = createAction(
    "[Notifications API] load selected notification template success",
    props<{selectedNotification: NotificationRecordModel}>()
  )

  export const LoadSelectedNotificationTemplateFailed= createAction(
    "[Notifications API] load selected notification template  failed",
    props<{errors: string}>()
  )


 export const LoadSegmentsSuccess = createAction(
    "[Notifications API] segments loaded success",
    props<{segments: SegmentAggregateModel}>()
  )

  export const LoadSegmentsFailed = createAction(
    "[Notifications API] segments loaded failed",
    props<{errors: string}>()
  )


export type NotificationApiActions = ReturnType<

| typeof NTLoadedSuccess
| typeof NTLoadedFailed
  | typeof DNLoadedSuccess
  | typeof NewNotificationInitializedSuccess
  | typeof MissingIdentifierIdDetected
  | typeof SelectedNotificationTypeUpdatedSuccess
  | typeof NewNotificationRecordLoadedSuccess

  | typeof NotificationMessageContentUpdatedSuccess
  | typeof NewContentTemplateCreatedSuccess
  | typeof NewActionCreatedSuccess

  |typeof ActionResetAndSaveSuccess
  | typeof NewMessageTemplateDiscardSuccess

  | typeof NewNotificationTemplateCreatedSuccess
  | typeof NewNotificationTemplateCreatedFailed

  | typeof SendNotificationToAllSuccess
  | typeof SendNotificationToAllFailed

  | typeof SendNotificationToSingleSuccess
  | typeof SendNotificationToSingleFailed

  | typeof SendNotificationToGroupSuccess
  | typeof SendNotificationToGroupFailed

  | typeof DeleteNotificationSuccess
  | typeof DeleteNotificationFailed

  | typeof LoadSegmentsSuccess
  | typeof LoadSegmentsFailed
>;

