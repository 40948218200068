import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Pipe({name: 'truncate'})
export class TruncatePipe implements PipeTransform {
  transform(value: string, max?:number): string {

    let limit = max > 0 ? max : 10;
    let trail = '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;

  }
}

