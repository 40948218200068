<div class="flex flex-col pt-12  pl-16 pr-16">
  <div>
    <p class="header text-5xl font-bold leading-tight">{{ 'TITLE.GROUPS' | translate}}</p>
  </div>
</div>
<div class="table w-full pl-16 pr-16 mt-10">
  <app-group-list [currentPage]="currentPage$ | async"  [data]="(groups$|async)?.items" (GoToNextEvent)="goToNext($event)" (GoToPrevEvent)="goToPrev($event)" (CheckGroupEvent)="checkGroup($event)" (DeleteGroupEvent)="deleteGroup($event)" ></app-group-list>
</div>
<div class="empty_space h-20"></div>



<app-floating-button  (click)="onClickFloatingButtonHandler($event)"  class="absolute top-0 right-0 mt-12 mr-12"></app-floating-button>



<jw-modal id="view-group-modal">

  <form  *ngIf="selectedGroup" class="max-w-4xl ml-auto mr-auto border-2 ">

     <h3 *ngIf="selectedGroup.idType==2" class="block pt-12  pl-16 pr-16 font-medium text-2xl">
      {{'PAGE_GROUPS.LIST_GROUP.TOP1000_ACCOUNT_IDS'| translate}}
    </h3>
    <h3 *ngIf="selectedGroup.idType==1" class="block pt-12  pl-16 pr-16 font-medium text-2xl">
      {{'PAGE_GROUPS.LIST_GROUP.TOP1000_CALLER_IDS'| translate}}
    </h3> -

    <div *ngIf="selectedGroupUsers != null" class="divide-y-2 divide-gray-500 divide-dashed flex flex-col items-stretch justify-center pt-12  pl-16 pr-16">
      <div class="mb-2" *ngFor="let groupUser of selectedGroupUsers">
        <p class="inline ">
          {{groupUser.id}}
        </p>

      </div>

    </div>
    <div class="button-group flex flex-row justify-end mt-10 pl-16 pr-16 mb-8">
      <app-round-outline-button
        color="dark"
        type="button"
        (click)="closeModal('view-group-modal')"
        > {{'PAGE_GROUPS.LIST_GROUP.CLOSE_WINDOW_BUTTON'| translate}}</app-round-outline-button
      >
    </div>
  </form>
</jw-modal>
