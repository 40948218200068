<div class="flex flex-col">
  <div class="">
    <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
      <ng-content></ng-content>
    </label>
  </div>
  <div class="pt-1">
    <input
    class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" [type]="type" [placeholder]="placeholderText">
  </div>
</div>
