<div class="relative flex flex-col pt-12 pl-16 pr-16">
  <div class="absolute top-0 right-0 mr-6 mt-10">
    <button class="ml-3 w-5" (click)="onClickClearMessageTemplateHandler()">
      <img src="/assets/images/delete-icon.png" class="w-full block" />
    </button>
  </div>
  <div class="">
    <p class="header text-5xl font-bold leading-tight">{{ 'PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_TWO.TITLE' | translate }}</p>
  </div>
  <div class="subheader-caption border-b-2 mt-12 border-solid border-gray-300">
    <p class="text-gray-600 text-xs pb-3 leading-tight tracking-widest">
      {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_TWO.SUBHEADER_CAPTION" | translate }}
    </p>
  </div>
  <div class="subheader mt-2">
    <p class="text-3xl text-gray-900 leading-tight">
      {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_TWO.SUBHEADER_TITLE" | translate }}
    </p>
  </div>



  <form  class="flex flex-col pl-3"  [formGroup]="form">
    <div class="flex flex-col mt-8">
      <div class="">
        <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
          {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_TWO.MESSAGE_TITLE" | translate }}
        </label>
      </div>
      <div class="pt-1">
        <input
        formControlName="messageTitle"
        type="text" placeholderText="Enter title here"
        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
      </div>
    </div>
    <div class="mt-1 text-xs text-red-700" *ngIf="submitted && form.controls.messageTitle.errors?.required">
      {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_TWO.ERRORS.REQUIRED_MESSAGE_TITLE" | translate }}
    </div>
    <div class="mt-1 text-xs text-red-700" *ngIf="submitted && form.controls.messageTitle.errors?.maxlength">
      {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_TWO.ERRORS.MAX_MESSAGE_TITLE" | translate }}
    </div>
    <div class="mt-1 text-xs text-red-700" *ngIf="submitted && form.controls.messageTitle.errors?.minlength">
      {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_TWO.ERRORS.MIN_MESSAGE_TITLE" | translate }}
    </div>


    <div class="flex flex-col mt-4">
      <div class="">
        <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
          {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_TWO.MESSAGE_BODY" | translate }}
        </label>
      </div>
      <div class="pt-1">
        <input
        formControlName="messageBody"
        type="text" placeholderText="Enter body here"
        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
      </div>
    </div>
    <div class="mt-1 text-xs text-red-700" *ngIf="submitted && form.controls.messageBody.errors?.required">
      {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_TWO.ERRORS.REQUIRED_MESSAGE_BODY" | translate }}
    </div>
    <div class="mt-1 text-xs text-red-700" *ngIf="submitted && form.controls.messageBody.errors?.maxLength">
      {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_TWO.ERRORS.MAX_MESSAGE_BODY" | translate }}
    </div>
    <div class="mt-1 text-xs text-red-700" *ngIf="submitted && form.controls.messageBody.errors?.minlength">
      {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_TWO.ERRORS.MIN_MESSAGE_BODY" | translate }}
    </div>


    <!-- <app-dark-textfield name="messageTitle"  formGroupName="messageTitle" class="mt-8" type="text" placeholderText="Enter title here">Title Message</app-dark-textfield> -->

    <!-- <div *ngIf="form.controls['messageTitle'].errors.required">
      message title is required
   </div>
   <div *ngIf="form.controls['messageTitle'].errors.maxlength">
     message title is required
  </div> -->

    <!-- <app-dark-textfield name="messageBody"  formGroupName="messageBody"  class="pt-4" type="text" placeholderText="Enter body here">Body Message</app-dark-textfield> -->
    <!-- <div *ngIf="form.controls['messageBody'].errors.required">
       message body is required
    </div>
    <div *ngIf="form.controls['messageBody'].errors.maxlength">
      message body is required
   </div> -->

  </form>



  <div class="button-group flex mt-12 mb-10 self-end">
    <app-round-outline-button
    (click)="onClickPreviousButtonHandler($event)"
    color="dark">
        Previous
    </app-round-outline-button>
      <app-round-button
        (click)="onClickContinueButtonHandler($event)"
        color="yellow" class="pl-4">
          Continue
      </app-round-button>
  </div>
</div>
