<div class="relative flex flex-col pt-12 pl-16 pr-16">
  <div class="absolute top-0 right-0 mr-6 mt-10">
    <button class="ml-3 w-5" (click)="onClickReturnPreviousPage($event)">
      <img src="/assets/images/delete-icon.png" class="w-full block" />
    </button>
  </div>
  <div class="">
    <p class="header text-5xl font-bold leading-tight">{{'PAGE_ADMIN.CREATE_USER.STEP_ONE.TITLE' | translate }}</p>
  </div>
  <div class="subheader-caption border-b-2 mt-12 border-solid border-gray-300">
    <p class="text-gray-600 text-xs pb-3 leading-tight tracking-widest">
      {{'PAGE_ADMIN.CREATE_USER.STEP_ONE.SUBHEADER_CAPTION' | translate }}
    </p>
  </div>
  <div class="subheader mt-2">
    <p class="text-3xl text-gray-900 leading-tight">
      {{'PAGE_ADMIN.CREATE_USER.STEP_ONE.SUBHEADER_TITLE' | translate }}
    </p>
  </div>



  <form  class="flex flex-col pl-3"  [formGroup]="form">
    <div class="flex flex-col mt-8">
      <div class="">
        <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
          {{'PAGE_ADMIN.CREATE_USER.STEP_ONE.LABEL.STAFF_EMAIL_LABEL' | translate }}
        </label>
      </div>
      <div class="pt-1">
        <input
        formControlName="emailAddress"
        type="email" placeholderText="Enter title here"
        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
      </div>
    </div>
    <div class="mt-1 text-xs text-red-700" *ngIf="submitted && form.controls.emailAddress.errors?.email">
      {{'PAGE_ADMIN.CREATE_USER.STEP_ONE.ERROR_MESSAGE.INVALID_EMAIL' | translate }}
    </div>
    <div class="mt-1 text-xs text-red-700" *ngIf="submitted && form.controls.emailAddress.errors?.required">
      {{'PAGE_ADMIN.CREATE_USER.STEP_ONE.ERROR_MESSAGE.REQUIRED_EMAIL' | translate }}
    </div>
    <div class="mt-1 text-xs text-red-700" *ngIf="submitted && form.controls.emailAddress.errors?.maxlength">
      {{'PAGE_ADMIN.CREATE_USER.STEP_ONE.ERROR_MESSAGE.MAX_EMAIL_LENGTH' | translate }}
    </div>



    <div class="flex flex-col mt-4">
      <div class="">
        <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
          {{'PAGE_ADMIN.CREATE_USER.STEP_ONE.LABEL.STAFF_ROLE_LABEL' | translate }}
        </label>
      </div>
      <div class="mt-1 bg-gray-200 rounded-md">
        <select class="p-3 bg-transparent" formControlName="roleId" id="staff-roles">
          <option *ngFor="let role of roles; let i = index" [value]="roles[i].id">
            <!-- {{roles[i].name}} -->
            <span *ngIf="roles[i].name==='Member'">{{'PAGE_ADMIN.CREATE_USER.STEP_ONE.VALUE.MEMBER_VALUE'| translate}}</span>
            <span *ngIf="roles[i].name==='Admin'">{{'PAGE_ADMIN.CREATE_USER.STEP_ONE.VALUE.ADMIN_VALUE'| translate}}</span>
          </option>
        </select>

        <!-- <input
        formControlName="roleId"
        type="text" placeholderText="Enter body here"
        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" > -->
      </div>
    </div>
    <div class="mt-1 text-xs text-red-700" *ngIf="submitted && form.controls.roleId.errors?.required">
      {{'PAGE_ADMIN.CREATE_USER.STEP_ONE.ERROR_MESSAGE.ROLE_REQUIRED' | translate }}
    </div>

  </form>

  <div class="button-group flex mt-12 mb-10 self-end">
      <app-round-button color="yellow" class="pl-4" (click)="onClickSubmitForm($event)">
          Submit
      </app-round-button>
  </div>
</div>
