import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../shared/components/buttons/buton.module';
import { FormFieldModule } from '../shared/components/form-field';
import { PersistanceService } from '../shared/services/persistance.service';
import { ModalModule } from '../_modal';
import { DndDirective } from '../shared/directives/dnd.directive';
import { GraphModule } from '../shared/components/graphs/graph.module';
import { AuthenticationContainerComponent } from './containers/authentication-container.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { SilentRefreshComponent } from './components/silent-refresh/silent-refresh.component';


const routes: Routes = [
  // Site routes goes here
   {
     path: '',
     redirectTo:'auth',
     pathMatch:'full'

   }
  //  {
  //   path: 'silent-renew',
  //   pathMatch:'full',
  //   component: SilentRefreshComponent,
  // }
   ,
   {
     path: 'auth',
     component: AuthenticationContainerComponent,
     children:[
      {path: '', redirectTo: 'login',  pathMatch:'full'},
      {
        path:'login',
        component: LoginPageComponent
      }]

   }
  ]

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FormFieldModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule.forChild(routes),
    // EffectsModule.forFeature([ImagesApiEffects]),
GraphModule
  ],
  declarations: [
    AuthenticationContainerComponent,
    LoginPageComponent,
    SilentRefreshComponent

  ],
  providers: [
    PersistanceService
  ]
})
export class AuthenticationModule {}
