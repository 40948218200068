import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import {  NotificationTemplateModel } from '../models/notificationTemplate.model';
import { of } from 'rxjs/internal/observable/of'
import { PagedListModel } from '../models/pagedList.model'
import { loadStaffListRequestInterface } from '../types/params/request/loadStaffListRequest.interface'
import { StaffModel } from '../models/staff.model'
import { loadStaffListResponseInterface } from '../types/params/response/loadStaffListResponse.interface'
import { UpdateStaffInfoCommandInterface } from '../types/params/request/UpdateStaffInfoCommand.interface'
import { updateStaffInfoResponseInterface } from '../types/params/response/updateStaffInfoResponse.interface'
import { CreateNewStaffCommandInterface } from '../types/params/request/CreateNewStaffCommand.interface'
import { createNewStaffResponseInterface } from '../types/params/response/createNewStaffResponse.interface'


@Injectable()
export class AdminService {
  constructor(private http: HttpClient) {}

  errorHandler(error: HttpErrorResponse, caught:any) {
      console.log(error);
      return throwError(error.statusText || "server error.");
      //return Observable.throw(error.statusText);
    // return Observable.throw(error.message || "server error.");
  }

  getStaffList(data:loadStaffListRequestInterface): Observable<PagedListModel<StaffModel[]>> {
    const url =  environment.apiUrl+'auth/staff-list?currentPage='+data.request.currentPage+'&pageSize='+data.request.pageSize;

    return this.http.get(url)
          .pipe(
            map((response: loadStaffListResponseInterface) => {
              return response.result
            }),
            catchError(this.errorHandler)
      )
  }

  updateStaffInfo(data:UpdateStaffInfoCommandInterface): Observable<string> {
    const url =  environment.apiUrl+'auth/update-staff-info';

    return this.http.post(url, data.request)
          .pipe(
            map((response: updateStaffInfoResponseInterface) => {
              if(response.status == 0){
                throw new Error("Failed to update staff info!");
              }

              return response.result
            }),
            catchError(this.errorHandler)
      )
  }


  createNewStaff(data: CreateNewStaffCommandInterface): Observable<string> {
    const url =  environment.apiUrl+'auth/new-staff';

    return this.http.post(url, data.request)
          .pipe(
            map((response: createNewStaffResponseInterface) => {

              if(response.status == 0){

                throw new Error("Failed to create new staff!");
              }

              return response.result
            }),
            catchError(this.errorHandler)
      )
  }
}
