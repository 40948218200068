import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { NotificationTemplateModel } from 'src/app/shared/models/notificationTemplate.model';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { ModalService } from 'src/app/_modal';
import * as fromRoot from "src/app/shared/state";
import { loadNotificationTemplatesRequestInterface } from 'src/app/shared/types/params/request/loadNotificationTemplatesRequest.interface';
import { NotificationsPageActions } from '../../actions';
import { environment } from 'src/environments/environment';
import { loadDeliveredNotificationsRequestInterface } from 'src/app/shared/types/params/request/loadDeliveredNotificationsRequest.interface';
import { initializeNewNotificationCommandInterface as InitializeNewNotificationCommandInterface } from '../../../shared/types/params/request/initializeNewNotificationCommand.interface';
import * as uuid from 'uuid';
import { SpinnerOverlayService } from 'src/app/shared/components/spinner/spinner-overlay/spinner-overlay.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ActionHistoryModel } from 'src/app/shared/models/actionHistory.model';
import { deleteNotificationCommandInterface } from 'src/app/shared/types/params/request/deleteNotificationCommand.interface';

@Component({
  selector: "app-notifications-page",
  templateUrl: "./notifications-page.component.html",
  styleUrls: ["./notifications-page.component.scss"]
})
export class NotificationsPageComponent implements OnInit, OnDestroy {

  // request = {
  //   currentPage:1,
  //   pageSize:5
  // }

  notificationTemplates$: Observable<PagedListModel<NotificationTemplateModel[]>>;
  NTcurrentPage$: Observable<number>;
  NTcurrentPage:number;
  NTcurrentPageSubscription: Subscription;
  NTLoadingStateSubscription: Subscription;
  NTLoadingState$:Observable<boolean>;

  actionHistories$: Observable<PagedListModel<ActionHistoryModel[]>>;
  DNcurrentPage$: Observable<number>;
  DNcurrentPage:number;
  DNcurrentPageSubscription: Subscription;
  isAuthenticatedSubscription:Subscription;


  constructor(

    private oidcSecurityService:OidcSecurityService,
    private modalService:ModalService,
    private _router:Router,
    private store: Store<fromRoot.State>,
    private spinnerOverlayService:SpinnerOverlayService
    ) {
      this.notificationTemplates$ = this.store.pipe(select(fromRoot.selectAllNotificationTemplates));
      this.NTcurrentPage$ = this.store.pipe(select(fromRoot.selectNotificationTemplatesCurrentPage));

      this.actionHistories$ = this.store.pipe(select(fromRoot.selectAllDeliveredNotifications));
      this.DNcurrentPage$ = this.store.pipe(select(fromRoot.selectAllDeliveredNotificationsCurrentPage));

      this.NTLoadingState$ = this.store.pipe(select(fromRoot.selectNotificationTemplatesLoadingState));


      this.NTLoadingStateSubscription = this.NTLoadingState$.
      subscribe((res)=>{

        console.log("loading"+res);
        if(res){

          this.spinnerOverlayService.show();
        } else {

          var me = this;
          setTimeout(function(){
            me.spinnerOverlayService.hide();
          },1000);
        }
      })

      this.NTcurrentPageSubscription = this.NTcurrentPage$.subscribe((res)=>{
        if(res == null){
          res = 1;
        }
        this.NTcurrentPage = res;
      })

      this.DNcurrentPageSubscription = this.DNcurrentPage$.subscribe((res)=>{
        if(res == null){
          res = 1;
        }
        this.DNcurrentPage = res;
      })

    }
  ngOnDestroy(): void {
    this.NTLoadingStateSubscription.unsubscribe();
    this.NTcurrentPageSubscription.unsubscribe();
    this.DNcurrentPageSubscription.unsubscribe();
    this.isAuthenticatedSubscription.unsubscribe();
  }

  ngOnInit(): void {

    this.isAuthenticatedSubscription = this.oidcSecurityService.isAuthenticated$.subscribe(
      g => {
        if(g==true){
          this.getNotifications();
        }
      }
    )

  }

  getNotifications() {
    const NTRequestParams: loadNotificationTemplatesRequestInterface = {
        request: {
          currentPage : this.NTcurrentPage,
          pageSize : environment.pageSize
        }
    }
    this.store.dispatch(NotificationsPageActions.loadNT({request:NTRequestParams}));

    const DNRequestParams: loadDeliveredNotificationsRequestInterface = {
      request: {
        currentPage : this.DNcurrentPage,
        pageSize : environment.pageSize
      }
  }
  this.store.dispatch(NotificationsPageActions.loadDN({request:DNRequestParams}));
  }


  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  NTGoToNext(event:Event){

    const requestParams: loadNotificationTemplatesRequestInterface = {
      request: {
        currentPage : this.NTcurrentPage + 1,
        pageSize : environment.pageSize
      }
    }
    this.store.dispatch(NotificationsPageActions.goNextNTPage({request:requestParams}));


  }

  NTGoToPrev(event:Event){
    const requestParams: loadNotificationTemplatesRequestInterface = {
      request: {
        currentPage : (this.NTcurrentPage>1)?this.NTcurrentPage - 1:1,
        pageSize : environment.pageSize
      }
    }

    this.store.dispatch(NotificationsPageActions.goPrevNTPage({request:requestParams}));

  }

  DNGoToNext(event:Event){

    const requestParams: loadDeliveredNotificationsRequestInterface = {
      request: {
        currentPage : this.DNcurrentPage + 1,
        pageSize : environment.pageSize
      }
    }
    this.store.dispatch(NotificationsPageActions.goNextDNPage({request:requestParams}));


  }

  DNGoToPrev(event:Event){
    const requestParams: loadDeliveredNotificationsRequestInterface = {
      request: {
        currentPage : (this.DNcurrentPage>1)?this.DNcurrentPage - 1:1,
        pageSize : environment.pageSize
      }
    }
    this.store.dispatch(NotificationsPageActions.goPrevDNPage({request:requestParams}));

  }

  onClickFloatingButtonHandler(ev:Event){

    let commandParams:InitializeNewNotificationCommandInterface = {
      request: {
        newId: uuid.v4(),
        newTemplateId: uuid.v4(),
        newActionId: uuid.v4(),

      }
    }

    this.store.dispatch(NotificationsPageActions.InitializeNewNotification({command:commandParams}));
  }

  onClickDeleteNotification(d:NotificationTemplateModel){
    var answer = confirm("Confirm delete this notification template?" );
    if (answer) {
      const requestParams: deleteNotificationCommandInterface = {
        request: {
          messageId : d.id
        }
      }

      this.store.dispatch(NotificationsPageActions.DeleteNotification({command:requestParams}));
    } else {

    }
  }


}
