import { Route } from '@angular/compiler/src/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationRecordModel } from 'src/app/shared/models/notificationRecord.model';
import * as fromRoot from "src/app/shared/state";
import { loadNewNotificationRecordRequestInterface } from 'src/app/shared/types/params/request/loadNewNotificationRecordRequest.interface';
import { NotificationTypeEnum } from '../../../shared/enums/notificationType.enum';
import { NotificationsPageActions } from '../../actions';
import { UpdateSelectedNotificationTypeCommandInterface } from '../../../shared/types/params/request/UpdateSelectedNotificationTypeCommand.interface';

@Component({
  selector: 'app-create-notification-step-one',
  templateUrl: './create-notification-step-one.component.html',
  styleUrls: ['./create-notification-step-one.component.scss']
})
export class CreateNotificationStepOneComponent implements OnInit , OnDestroy{

  initializedNotificationRecord:NotificationRecordModel =  {
    id:"0",
    notificationType: null,
    messageTitle: null,
    messageBody: null,
    templateId: null,// uuid
    templatePageTitle:  null,
    templateBottomButtonTitle:null,
    templateContents:null,
    actionId: null ,// uuid
    actionProductId: null,
    actionMessageTitle:null,
    actionMessageBody:null,
    actionPositiveButtonText:null,
    actionNegativeButtonText :  null,
    actionPositiveButtonSuccessMessageText: null
    }

  newNotificationRecord$ : Observable<NotificationRecordModel>;

  newNotificationRecordSubscription: Subscription

  form:FormGroup

  slug: string

  // optionsList = {
  //   notificationType: [
  //     { name: 'Announcements',  selected: false, id: 1 },
  //     { name: 'Offers',  selected: false, id: 2 },
  //     { name: 'Rewards',  selected: false, id: 3 },
  //   ]
  // }

  constructor(
    private _router:Router,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
     private route:ActivatedRoute) {

    }



  ngOnDestroy(): void {
    this.newNotificationRecordSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeValues();
    this.fetchData();
    this.setSubscription()
  }

  fetchData():void {

    let requestParams: loadNewNotificationRecordRequestInterface = {
      request: {
        slug: this.slug
      }
    }


    this.store.dispatch(NotificationsPageActions.LoadNewNotificationRecord({request: requestParams}));
  }

  setSubscription():void {
    this.newNotificationRecordSubscription = this.newNotificationRecord$
    .subscribe((res)=>{
      if(res != null){
      this.initializedNotificationRecord = res
      }

      // if the form is not being initialized
      if( this.form == null ){
        this.initializeForm()
      }

      // if(res != null){
      //   if(res.notificationType != null){
      //     this.form.patchValue({notificationType:res.notificationType})

      //   }
      // }
    })
  }


  initializeValues():void {
    this.slug = this.route.snapshot.paramMap.get('id')
    this.newNotificationRecord$ = this.store.pipe(select(fromRoot.selectNewNotificationRecordSelector));

  }


  initializeForm ():void {

    this.form
       = this.fb.group({
        notificationType: [ this.initializedNotificationRecord.notificationType, Validators.required]
      });
    }


  onClickCancelButtonHandler(ev:Event){
    this._router.navigate(['./notifications/list-notifications'])
  }

  onClickContinueButtonHandler(ev:Event){
    if(this.form.valid == true){
        this._router.navigate(['./notifications/create/step-two',this.slug]);
    } else{
      alert("please fill in the required field.");
    }
  }

  changeNotificationType(e) {

    let commandParams:UpdateSelectedNotificationTypeCommandInterface = {
      request: {
        notificationType: e.target.value
      }
    }

    this.store.dispatch(NotificationsPageActions.UpdateSelectedNotificationType({command: commandParams}));
  }

}
