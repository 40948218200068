import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { PagedListModel } from '../shared/models/pagedList.model';
import { PersistanceService } from '../shared/services/persistance.service';
import { GroupApiActions, GroupPageActions } from './actions';
import { loadGroupList } from './actions/group-page.actions';
import { ImageModel } from '../shared/models/image.model';
import { EMPTY, of } from 'rxjs';
import { ImagesService } from '../shared/services/images.service';
import { GroupModel } from '../shared/models/group.model';
import { GroupService } from '../shared/services/group.service';

@Injectable()
export class GroupApiEffects {

  @Effect()
  loadGroups$ = this.actions$.pipe(
    ofType(GroupPageActions.loadGroupList.type),
    exhaustMap(({request} ) =>
      this.groupService.getGroupList(request).pipe(
        map((groupList:PagedListModel<GroupModel[]>) => GroupApiActions.GroupListLoadedSuccess({ groupList:groupList })),
        catchError((err:string) => of(GroupApiActions.GroupListLoadedFailed({ errors:err})))
      )
    )
  );

  @Effect()
  loadGroupsPrevPage$ = this.actions$.pipe(
    ofType(GroupPageActions.goPrevGroupListPage.type),
    exhaustMap(({request}) => {

      return this.groupService.getGroupList( request ).pipe(
        map((groupList:PagedListModel<GroupModel[]>) => GroupApiActions.GroupListLoadedSuccess({ groupList: groupList })),
        catchError((err:string) => of(GroupApiActions.GroupListLoadedFailed({ errors:err})))
      )
    }
    )
  );

  @Effect()
  loadGroupsNextPage$ = this.actions$.pipe(
    ofType(GroupPageActions.goNextGroupListPage.type),
    switchMap(({request}) => {
      return this.groupService.getGroupList( request ).pipe(
        map((groupList:PagedListModel<GroupModel[]>) => GroupApiActions.GroupListLoadedSuccess({ groupList: groupList })),
        catchError((err:string) => of(GroupApiActions.GroupListLoadedFailed({ errors:err})))
      )
    }
    )
  );


  showErrorMesssageAfterGroupListLoadedFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupApiActions.GroupListLoadedFailed),
        tap((err) => {
          console.log(err)
          alert(`Failed to load Group page. [error:${err.errors}]`);
        })
      ),
    {dispatch: false}
  )

  @Effect()
  deleteGroup$ = this.actions$.pipe(
    ofType(GroupPageActions.DeleteGroup.type),
    switchMap(({command}) => {
      return this.groupService.deleteGroup( command ).pipe(
        map((isTrue:boolean) => {
            if(isTrue){
             return  GroupApiActions.DeleteGroupSuccess({groupId:command.request.groupId});
            } else {
             return GroupApiActions.DeleteGroupFailed({errors:"Delete group failed."});
            }
          }
          ),
          catchError((err:string) => of(GroupApiActions.DeleteGroupFailed({ errors:err})))
      )
    }
   )
  );


  showAlertAfterDeleteGroupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupApiActions.DeleteGroupSuccess),
        tap(() => {
          alert("Group deleted success!");
        })
      ),
    {dispatch: false}
  )

  showAlertAfterDeleteGroupFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupApiActions.DeleteGroupFailed),
        tap((err) => {
          console.log(err)
          alert(`Failed to delete group. [error:${err.errors}]`);
        })
      ),
    {dispatch: false}
  )




  constructor(
  private groupService: GroupService,
  private persistanceService: PersistanceService,
  private router:Router,
  private actions$: Actions<
    GroupPageActions.GroupActions | GroupApiActions.GroupActions
  >){

  }
}
