import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { PagedListModel } from '../shared/models/pagedList.model';
import { PersistanceService } from '../shared/services/persistance.service';

import { EMPTY, of } from 'rxjs';
import { AdminApiActions, AdminPageActions } from './actions';
import { StaffModel, NewStaffModel } from '../shared/models/staff.model';
import { AdminService } from '../shared/services/admin.service';
import { environment } from 'src/environments/environment';
import { loadStaffListRequestInterface } from '../shared/types/params/request/loadStaffListRequest.interface';
import { CreateNewStaffCommandInterface } from '../shared/types/params/request/CreateNewStaffCommand.interface';
import { CreateNewStaffSuccess } from './actions/admin-api.actions';

@Injectable()
export class AdminApiEffects {

  @Effect()
  loadStaffList$ = this.actions$.pipe(
    ofType(AdminPageActions.loadStaffList.type),
    exhaustMap(({request} ) =>
      this.adminService.getStaffList(request).pipe(
        map((staffList:PagedListModel<StaffModel[]>) => AdminApiActions.STLoadedSuccess({ staffList })),
        catchError((err:string) => of(AdminApiActions.STLoadedFailed({ errors:err})))
      )
    )
  );

  @Effect()
  loadSTPrevPage$ = this.actions$.pipe(
    ofType(AdminPageActions.goPrevSTPage.type),
    exhaustMap(({request}) => {
      return this.adminService.getStaffList( request ).pipe(
        map((staffList:PagedListModel<StaffModel[]>) => AdminApiActions.STLoadedSuccess({ staffList })),
        catchError((err:string) => of(AdminApiActions.STLoadedFailed({ errors:err})))
      )
    }
    )
  );

  @Effect()
  loadSTNextPage$ = this.actions$.pipe(
    ofType(AdminPageActions.goNextSTPage.type),
    switchMap(({request}) => {
      return this.adminService.getStaffList( request ).pipe(
        map((staffList:PagedListModel<StaffModel[]>) => {

          return AdminApiActions.STLoadedSuccess({ staffList });
        }),
        catchError((err:string) => of(AdminApiActions.STLoadedFailed({ errors:err})))
      )
    }
    )
  );

  @Effect()
  updateStaffInfo$ = this.actions$.pipe(
    ofType(AdminPageActions.updateStaffInfo.type),
    switchMap(({request}) => {
      return this.adminService.updateStaffInfo( request ).pipe(
        switchMap(res => {

          var request:loadStaffListRequestInterface =  {
              request :{
                currentPage:1,
                pageSize:environment.pageSize
              }
          };

          return [
            AdminApiActions.UpdateStaffInfoSuccess(),
            AdminPageActions.loadStaffList({request})
          ];

        }),
        // map(() => {
        //   return AdminApiActions.UpdateStaffInfoSuccess();
        // }),
        catchError((err:string) => of(AdminApiActions.UpdateStaffInfoFailed({ errors:err})))
      )
    }
    )
  );


  @Effect()
  createStaffInfo$ = this.actions$.pipe(
    ofType(AdminPageActions.createNewStaff.type),
    switchMap(({request}) => {
      return this.adminService.createNewStaff(request).pipe(
        map(() => {
          return  AdminApiActions.CreateNewStaffSuccess();
        }),
        catchError((err:string) => of(AdminApiActions.CreateNewStaffFailed({ errors:err})))
      )
    }
    )
  );


  redirectAfterStaffCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminApiActions.CreateNewStaffSuccess),
        tap(() => {
          this.router.navigate(['./admin/staff-list'])
        })
      ),
    {dispatch: false}
  )





  showErrorMesssageAfterCreateStaffFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminApiActions.CreateNewStaffFailed),
        tap((err) => {
          console.log(err)
          alert(`Failed to create staff. [error:${err.errors}]`);
        })
      ),
    {dispatch: false}
  )


  showErrorMesssageAfterUpdateStaffInfoFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminApiActions.UpdateStaffInfoFailed),
        tap((err) => {
          console.log(err)
          alert(`Failed to update staff info. [error:${err.errors}]`);
        })
      ),
    {dispatch: false}
  )


  showErrorMesssageAfterSTLoadedFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminApiActions.STLoadedFailed),
        tap((err) => {
          console.log(err)
          alert(`Failed to load staff List. [error:${err.errors}]`);
        })
      ),
    {dispatch: false}
  )



  constructor(
  private adminService: AdminService,
  private persistanceService: PersistanceService,
  private router:Router,
  private actions$: Actions<
    AdminPageActions.AdminActions | AdminApiActions.AdminActions
  >){

  }
}
