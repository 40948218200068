import { createAction, props } from '@ngrx/store';
import { ImageModel } from 'src/app/shared/models/image.model';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';

export const IMLoadedSuccess = createAction(
  "[Images API] Images List Loaded Success",
  props<{ imagesList: PagedListModel<ImageModel[]>}>()
);

export const IMLoadedFailed = createAction(
  "[Images API] Images List Loaded Failed",
  props<{ errors: string}>()
);

export const DeleteImageFailed = createAction(
  "[Images API] Delete Image Failed",
  props<{ errors: string }>()
);

export const DeleteImageSuccess = createAction(
  "[Images API] Delete Image Success",
  props<{ imageId: string}>()
);



export type ImagesActions = ReturnType<
| typeof IMLoadedSuccess
| typeof IMLoadedFailed
| typeof DeleteImageFailed
| typeof DeleteImageSuccess
>
