import { ImageModel } from './../../../shared/models/image.model';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { NotificationTemplateModel } from 'src/app/shared/models/notificationTemplate.model';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { ModalService } from 'src/app/_modal';
import * as fromRoot from "src/app/shared/state";
import { loadNotificationTemplatesRequestInterface } from 'src/app/shared/types/params/request/loadNotificationTemplatesRequest.interface';

import { environment } from 'src/environments/environment';
import { loadDeliveredNotificationsRequestInterface } from 'src/app/shared/types/params/request/loadDeliveredNotificationsRequest.interface';
import { initializeNewNotificationCommandInterface as InitializeNewNotificationCommandInterface } from '../../../shared/types/params/request/initializeNewNotificationCommand.interface';
import * as uuid from 'uuid';

import { ImagesPageActions } from '../../actions';
import { loadImagesListRequestInterface } from 'src/app/shared/types/params/request/loadImagesListRequest.interface';
import { SpinnerOverlayService } from '../../../shared/components/spinner/spinner-overlay/spinner-overlay.service';
import { deleteImageCommandInterface } from 'src/app/shared/types/params/request/deleteImageCommand.interface';

@Component({
  selector: "app-images-page",
  templateUrl: "./images-page.component.html",
  styleUrls: ["./images-page.component.scss"]
})
export class ImagesPageComponent implements OnInit, OnDestroy {

  selectedImageUrl: string;

  images$: Observable<PagedListModel<ImageModel[]>>;
  IMcurrentPage$: Observable<number>;
  IMcurrentPage:number;
  IMcurrentPageSubscription: Subscription;
  IMLoadingState$: Observable<boolean>;
  IMLoadingStateSubscription: Subscription;

  constructor(
    private modalService:ModalService,
    private _router:Router,
    private store: Store<fromRoot.State>,
    private spinnerOverlayService:SpinnerOverlayService
    ) {
      this.images$ = this.store.pipe(select(fromRoot.selectAllImagesList));
      this.IMcurrentPage$ = this.store.pipe(select(fromRoot.selectAllImagesListCurrentPage));
      this.IMLoadingState$ = this.store.pipe(select(fromRoot.selectAllImagesListLoadingState));
      this.IMcurrentPageSubscription = this.IMcurrentPage$.subscribe((res)=>{
        if(res == null){
          res = 1;
        }
        this.IMcurrentPage = res;
      })

      this.IMLoadingStateSubscription = this.IMLoadingState$.subscribe((res)=> {
        if(res){
          this.spinnerOverlayService.show();
        }else {
          var me = this;
          setTimeout(function(){
            me.spinnerOverlayService.hide();
          },1000)

        }
      });


    }
  ngOnDestroy(): void {
    this.IMcurrentPageSubscription.unsubscribe();
    this.IMLoadingStateSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.getImages();
  }

  getImages() {
    const IMRequestParams: loadImagesListRequestInterface = {
        request: {
          currentPage : this.IMcurrentPage,
          pageSize : environment.pageSize
        }
    }
    this.store.dispatch(ImagesPageActions.loadImagesList({request:IMRequestParams}));

  }


  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  IMGoToNext(event:Event){

    const requestParams: loadImagesListRequestInterface = {
      request: {
        currentPage : this.IMcurrentPage + 1,
        pageSize : environment.pageSize
      }
    }
    this.store.dispatch(ImagesPageActions.goNextIMPage({request:requestParams}));


  }

  IMGoToPrev(event:Event){
    const requestParams: loadNotificationTemplatesRequestInterface = {
      request: {
        currentPage : (this.IMcurrentPage>1)?this.IMcurrentPage - 1:1,
        pageSize : environment.pageSize
      }
    }

    this.store.dispatch(ImagesPageActions.goPrevIMPage({request:requestParams}));

  }

  CheckImage(imageModel:ImageModel){
    console.log(imageModel);

    this.selectedImageUrl = "";
    this.selectedImageUrl = imageModel.remoteUrl;

    this.openModal('view-image-modal');
  }

  DeleteImage(imageModel:ImageModel){
    var answer = confirm("Confirm delete group [" + imageModel.id + "]" );
    if (answer) {
      const requestParams: deleteImageCommandInterface = {
        request: {
          imageId : imageModel.id
        }
      }

      this.store.dispatch(ImagesPageActions.DeleteImage({command:requestParams}));
    } else {

    }
  }


  onClickFloatingButtonHandler(ev:Event){
    this._router.navigate(['./images/upload-image']);
  }


}
