import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { NewStaffModel } from 'src/app/shared/models/staff.model';
import * as fromRoot from "src/app/shared/state";
import { AdminPageActions } from '../../actions';
import { selectNewStaff } from '../../../shared/state/createStaff.reducer';
import { selectNewStaffState } from '../../../shared/state/index';
import { CreateNewStaffCommandInterface } from 'src/app/shared/types/params/request/CreateNewStaffCommand.interface';
import { SelectOptions } from '../staff-page/staff-page.component';
import * as uuid from 'uuid';

@Component({
  selector: 'app-create-staff',
  templateUrl: './create-staff.component.html',
  styleUrls: ['./create-staff.component.scss']
})
export class CreateStaffComponent implements OnInit, OnDestroy {

  initializedStaffRecord:NewStaffModel =  {
    staffId: null,
    emailAddress: null,
    roleId: 1
  }

  selectRoleSubscription:Subscription;
  slug:string

  form:FormGroup
  roles = [];
  submitted:boolean = false

  constructor(
    private _router:Router,
    private fb: FormBuilder,
    private route:ActivatedRoute,
    private store: Store<fromRoot.State>) {
      this.selectRoleSubscription = of(this.getRolesOptions()).subscribe(rolesItem => {
        this.roles = rolesItem;
      });
     }

    ngOnDestroy(): void {

    }

    ngOnInit(): void {
    this.initializeValues();
    this.setSubscription()

      // if the form is not being initialized
      if( this.form == null){
        this.initializeForm()
      }
    }


  get formControl() { return this.form.controls; }


  initializeValues():void {
    this.slug = this.route.snapshot.paramMap.get('id')

  }

  setSubscription():void {
  }


  initializeForm ():void {
    this.form
       =  new FormGroup({
          "emailAddress":new FormControl(this.initializedStaffRecord.emailAddress, [Validators.required,Validators.maxLength(200),Validators.email]),
          "roleId":new FormControl(this.initializedStaffRecord.roleId,[ Validators.required])
        });
  }

  onClickClearMessageTemplateHandler(){
    var answer = confirm("Confirm to discard this staff?");
    if (answer) {
        //some code
        this.store.dispatch(AdminPageActions.discardNewStaff());
    }
    else {
        //some code
    }
  }


  onClickReturnPreviousPage(ev:Event){
    this._router.navigate(['./admin/staff-list'])
  }



  onClickSubmitForm(ev:Event){
    this.submitted = true;

    if(this.initializedStaffRecord.staffId == null){
      this.initializedStaffRecord.staffId = uuid.v4();
   }

    if (this.form.valid) {
      let commandParams:CreateNewStaffCommandInterface = {
        request: {
            ...this.initializedStaffRecord,
            emailAddress: this.form.value.emailAddress,
            roleId: this.form.value.roleId
        }
      }
      this.store.dispatch(AdminPageActions.createNewStaff( {request: commandParams} ));
    }
  }

  getRolesOptions():SelectOptions[]{
    return [
      { id: '1', name: 'Member'},
      { id: '2', name: 'Admin'},
    ];
  }




}
