import { createAction, props } from '@ngrx/store';
import { ImageModel } from 'src/app/shared/models/image.model';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { StaffModel } from 'src/app/shared/models/staff.model';
import { NewStaffModel } from '../../shared/models/staff.model';

export const STLoadedSuccess = createAction(
  "[Admin API] Staff List Loaded Success",
  props<{ staffList: PagedListModel<StaffModel[]>}>()
);

export const STLoadedFailed = createAction(
  "[Admin API] Staff List Loaded Failed",
  props<{ errors: string}>()
);

export const UpdateStaffInfoSuccess = createAction(
  "[Admin API] Update Staff Info Success"
);

export const UpdateStaffInfoFailed = createAction(
  "[Admin API] Update Staff Info Failed",
  props<{ errors: string}>()
);


export const CreateNewStaffSuccess = createAction(
  "[Admin API] Create New Staff Sucess"
);


export const CreateNewStaffFailed = createAction(
  "[Admin API] Create New Staff Failed",
  props<{ errors: string}>()
);


export type AdminActions = ReturnType<
| typeof STLoadedSuccess
| typeof STLoadedFailed
| typeof UpdateStaffInfoSuccess
| typeof UpdateStaffInfoFailed
| typeof CreateNewStaffSuccess
| typeof CreateNewStaffFailed
>
