import { Route } from '@angular/compiler/src/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from 'src/app/shared/state';
import { NotificationTypeEnum } from '../../../shared/enums/notificationType.enum';
import { NotificationsPageActions } from '../../actions';
import { UpdateSelectedNotificationTypeCommandInterface } from '../../../shared/types/params/request/UpdateSelectedNotificationTypeCommand.interface';
import { SendNotificationTargetTypeEnum } from 'src/app/shared/enums/sendNotificationTargetType.enum';
import * as uuid from 'uuid';
import { SendNotificationToAllCommandInterface } from '../../../shared/types/params/request/SendNotificationToAllCommand.interface';
import { CultureIdTypeEnum } from 'src/app/shared/enums/cultureIdType.enum';
import { SendNotificationToIndividualCommandInterface } from 'src/app/shared/types/params/request/SendNotificationToIndividualCommand.interface';

export interface SelectOptions{
  name:string
  id:number
}


@Component({
  selector: 'app-send-notification-options',
  templateUrl: './send-notification-options.component.html',
  styleUrls: ['./send-notification-options.component.scss']
})
export class SendNotificationOptionsComponent implements OnInit , OnDestroy{

  form:FormGroup
  slug: string
  slug2 :string
  transactionId:string

  optionsList: SelectOptions[] = [];

  constructor(
    private _router:Router,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
     private route:ActivatedRoute) {
    }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.initializeValues();
    this.initializeForm();
  }


  initializeValues():void {
    if( this.transactionId == null){
      this.transactionId = uuid.v4();
    }
    this.slug = this.route.snapshot.paramMap.get('msgid');
    this.slug2 = this.route.snapshot.paramMap.get('sendtarget');
    this.optionsList = this.optionsList.concat([
      { name: 'Send To All',   id: SendNotificationTargetTypeEnum.SEND_TO_ALL },
      { name: 'Send To All (English)',  id: SendNotificationTargetTypeEnum.SEND_TO_ALL_ENGLISH },
      { name: 'Send To All (Thai)', id: SendNotificationTargetTypeEnum.SEND_TO_ALL_THAI },
      { name: 'Send To All (Burmese)', id: SendNotificationTargetTypeEnum.SEND_TO_ALL_BURMESE },
      { name: 'Send To Group',   id: SendNotificationTargetTypeEnum.SEND_TO_GROUP },
      { name: 'Send To Single (CallerId)',   id: SendNotificationTargetTypeEnum.SEND_TO_SINGLE_CALLERID },
      { name: 'Send To Single (SubscriptionId)',   id: SendNotificationTargetTypeEnum.SEND_TO_SINGLE_SUBSCRIPTIONID },
      { name: 'Send To Segments',   id: SendNotificationTargetTypeEnum.SEND_TO_SEGMENTS }
    ]);
  }


  initializeForm ():void {
    this.form = this.fb.group({
        sendNotificationTarget: [ this.optionsList[0].id, Validators.required],
        singleAccountidExtraValue: [''],
        singleCalleridExtraValue: ['']
      });
  }


  onClickCancelButtonHandler(ev:Event){
    this._router.navigate(['./notifications/list-notifications'])
  }

  onClickContinueButtonHandler(ev:Event){
    if(this.form.valid == true){
      // 'sendnotification/send-to-all/:msgid/:sendtarget'
      var sendNotificationTarget:number =  this.form.value.sendNotificationTarget;
      var targetString:string = "";
      var cultureId:number;

    if(
        sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_ALL ||
        sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_ALL_ENGLISH ||
        sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_ALL_THAI ||
        sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_ALL_BURMESE
      ){

        if(sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_ALL){
          targetString = "";
          cultureId = CultureIdTypeEnum.All;
        } else if(sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_ALL_ENGLISH)
        {
          targetString = "(English)";
          cultureId = CultureIdTypeEnum.English;
        }else if(sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_ALL_THAI)
        {
          targetString = "(Thai)";
          cultureId = CultureIdTypeEnum.Thailand;
        }else if(sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_ALL_BURMESE)
        {
          targetString = "(Burmese)";
          cultureId = CultureIdTypeEnum.Burmese;
        }

        var answer = confirm("Confirm to send the notification to All " + targetString);
        if (answer) {
            //some code
          var command:SendNotificationToAllCommandInterface = {
            request :{
              transactionId: this.transactionId,
              messageId : this.slug,
              cultureId : cultureId
            }
          }

          this.store.dispatch(NotificationsPageActions.SendNotificationToAll({command}));
        }
        else {
            //some code


        }
      } else if( sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_GROUP)
      {

          this._router.navigate(['./notifications/sendnotification/send-to-group',this.slug,this.form.value.sendNotificationTarget ]);

      }
      else if( sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_SEGMENTS)
      {

          this._router.navigate(['./notifications/sendnotification/send-to-segments',this.slug ]);

      }
      else if( sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_SINGLE_CALLERID)
      {
        if(this.form.value.singleCalleridExtraValue == '' || this.form.value.singleCalleridExtraValue == null){
          return;
        }
        var command2:SendNotificationToIndividualCommandInterface = {
          request :{
            transactionId: this.transactionId,
            targetType : 2,
            targetValue : this.form.value.singleCalleridExtraValue,
            messageId : this.slug,
            cultureId : 99
          }
        }

        var answer = confirm("Confirm to send the notification to individual caller id = " +this.form.value.singleCalleridExtraValue );
        if (answer) {

        this.store.dispatch(NotificationsPageActions.SendNotificationToSingle({command:command2}));
        } else {

        }

       // this._router.navigate(['./notifications/sendnotification/send-to-single-user',this.slug, ]);//this.form.value.sendNotificationTarget]);
      }

      else if( sendNotificationTarget == SendNotificationTargetTypeEnum.SEND_TO_SINGLE_SUBSCRIPTIONID)
      {
        if(this.form.value.singleAccountidExtraValue == '' || this.form.value.singleAccountidExtraValue == null){
          return;
        }
        var command2:SendNotificationToIndividualCommandInterface = {
          request :{
            transactionId: this.transactionId,
            targetType : 3,
            targetValue : this.form.value.singleAccountidExtraValue,
            messageId : this.slug,
            cultureId : cultureId
          }
        }

        var answer = confirm("Confirm to send the notification to individual subscription id = " +this.form.value.singleAccountidExtraValue );
        if (answer) {
          this.store.dispatch(NotificationsPageActions.SendNotificationToSingle({command:command2}));
        } else {

        }

      }

    } else{
      alert("please fill in the required field.");
    }
  }

  changeNotificationType(e) {

    let commandParams:UpdateSelectedNotificationTypeCommandInterface = {
      request: {
        notificationType: e.target.value
      }
    }

    this.store.dispatch(NotificationsPageActions.UpdateSelectedNotificationType({command: commandParams}));
  }

}
