<div class="flex flex-col pt-12 pb-6 pl-16 pr-16">
  <div class="">
    <p class="header text-5xl font-bold leading-tight">{{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_OPTIONS.TITLE' | translate }}</p>
  </div>
  <div class="subheader-caption border-b-2 mt-12 border-solid border-gray-300">
    <p class="text-gray-600 tracking-widest text-xs pb-3 leading-tight">
      {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_OPTIONS.SUBHEADER_CAPTION' | translate }}
    </p>
  </div>
  <div class="subheader mt-2">
    <p class="text-3xl text-gray-900 leading-tight">
      {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_OPTIONS.SUBHEADER_TITLE' | translate }}
    </p>
  </div>
  <div class="content w-full mt-8 ml-3">
    <form class="w-full"  [formGroup]="form" (submit)="onClickContinueButtonHandler($event)">

      <div class="content__radio-group" *ngIf="optionsList.length>0">

        <div  *ngFor="let item of optionsList; let i = index">
          <label class="inline-flex items-center">
            <input type="radio" class="form-radio" name="sendNotificationTarget" [value]="item.id"  formControlName="sendNotificationTarget" (change)="changeNotificationType($event)">
            <span class="ml-6 text-gray-600 text-sm">{{item.name}}</span>
          </label>
        </div>
        <!-- <div>
          <label class="inline-flex items-center">
            <input type="radio" class="form-radio" name="notificationType" value="1" formControlName="notificationType" (change)="changeNotificationType($event)">
            <span class="ml-6 text-gray-600 text-sm">Announcements</span>
          </label>
        </div>
        <div>
          <label class="inline-flex items-center">
            <input type="radio" class="form-radio" name="notificationType" value="2"  formControlName="notificationType" (change)="changeNotificationType($event)">
            <span class="ml-6 text-gray-600 text-sm">Offers</span>
          </label>
        </div>
        <div>
           <label class="inline-flex items-center">
            <input type="radio" class="form-radio" name="notificationType" value="3" formControlName="notificationType" (change)="changeNotificationType($event)">
            <span class="ml-6 text-gray-600 text-sm">Rewards</span>
          </label>
        </div> -->
        <div class="flex flex-col mt-4" *ngIf="form.get('sendNotificationTarget')?.value ==8">
          <div class="">
            <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
              {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_OPTIONS.ACCOUNTID_LABEL' | translate }}
            </label>
          </div>
          <div class="pt-1">
            <input
            formControlName="singleAccountidExtraValue"
            type="text" placeholderText="Enter subscription id here"
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
          </div>
        </div>

        <div class="flex flex-col mt-4" *ngIf="form.get('sendNotificationTarget')?.value ==6">
          <div class="">
            <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
              {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_OPTIONS.CALLERID_LABEL' | translate }}
            </label>
          </div>
          <div class="pt-1">
            <input
            formControlName="singleCalleridExtraValue"
            type="text" placeholderText="Enter caller id here"
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" >
          </div>
        </div>
    </div>
    </form>
  </div>

  <div class="button-group flex mt-12 self-end">
    <app-round-outline-button
    (click)="onClickCancelButtonHandler($event)"
    color="dark">
    {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_OPTIONS.CANCEL_BTN' | translate }}
    </app-round-outline-button>
      <app-round-button
        (click)="onClickContinueButtonHandler($event)"
        color="yellow" class="pl-4">
        {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_OPTIONS.CONTINUE_BTN' | translate }}
      </app-round-button>
  </div>
</div>
