import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from '@angular/router';

import { NotificationTemplateModel } from 'src/app/shared/models/notificationTemplate.model';
import { ModalService } from 'src/app/_modal';

@Component({
  selector: "app-notifications-delivered-list",
  templateUrl: "./notifications-delivered-list.component.html",
  styleUrls: ["./notifications-delivered-list.component.scss"]
})
export class NotificationsDeliveredListComponent implements OnInit {
  @Input() data: NotificationTemplateModel[];
  @Input() currentPage: number;

  @Output('DNGoToNextEvent') goToNextEvent = new EventEmitter<number>();
  @Output('DNGoToPrevEvent') goToPrevEvent = new EventEmitter<number>();



  constructor(
    private modalService:ModalService,
    private _router:Router
  ) {

  }

  ngOnInit(): void {

  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }



  onClickOpenSendNotificationPageHandler(ev:Event){
    this._router.navigate(['./notifications/send/step-one'])
  }

  onClickNextPage(ev:Event){
    this.goToNextEvent.emit(1);
    ev.preventDefault();

  }

  onClickPrevPage(ev:Event){
    this.goToPrevEvent.emit(1);


  }


}
