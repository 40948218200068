import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'transformImageObjectForDisplay'})
export class TransformImageObjectForDisplayPipe implements PipeTransform {
  transform(fc: AbstractControl): string {
    return fc.value.templateContent;
  }
}
