import { createSelector } from '@ngrx/store';
import { PagedListModel } from '../models/pagedList.model';
import { ImageModel } from '../models/image.model';
import { ImagesApiActions, ImagesPageActions } from 'src/app/images/actions';
import { StaffModel } from '../models/staff.model';
import { AdminApiActions, AdminPageActions } from 'src/app/admin/actions';

export interface StaffStateInterface {
  data: PagedListModel<StaffModel[]> | null;
  isLoading: boolean;
  error: string | null;

}

export const initialState: StaffStateInterface = {
  data: null,
  isLoading: false,
  error: null,
};

export function reducer(
  state = initialState,
  action: AdminPageActions.AdminActions | AdminApiActions.AdminActions
): StaffStateInterface {
  switch (action.type) {
    case AdminApiActions.STLoadedSuccess.type:
      return {
        ...state,
        isLoading: false,
        data: action.staffList,
      };

    case AdminApiActions.STLoadedFailed.type:
      return {
        ...state,
        isLoading: false,
        error: action.errors,
      };

    case AdminPageActions.loadStaffList.type:
      return {
        ...state,
        isLoading: true,
      };
    case AdminPageActions.goNextSTPage.type:
      return {
        ...state,
        isLoading: true,
      };
    case AdminPageActions.goPrevSTPage.type:
      return {
        ...state,
        isLoading: true,
      };
    case AdminPageActions.updateStaffInfo.type:
        return {
          ...state,
          isLoading: true,
        };
    case AdminPageActions.updateStaffInfo.type:
        return {
          ...state,
          isLoading: true,
        };
   case AdminApiActions.UpdateStaffInfoSuccess.type:
        return {
         ...state,
          isLoading: false,
        };
   case AdminApiActions.UpdateStaffInfoFailed.type:
        return {
          ...state,
          isLoading: false,
          error: action.errors,
        };
    default:
      return state;
  }
}

/**
 * Selectors
 */

export const selectAllStaff = (state: StaffStateInterface) => state.data;
export const selectAllStaffState = (state: StaffStateInterface) => state;
export const getAllStaffSelector = createSelector(
  selectAllStaff,
  (data: PagedListModel<StaffModel[]>) => {
    return data;
  }
);

export const getAllStaffSelectorLoadingState = createSelector(
  selectAllStaffState,
  (data: StaffStateInterface) => {
    return data.isLoading;
  }
);


export const getStaffListCurrentPageSelector = createSelector(
  selectAllStaff,
  (data: PagedListModel<StaffModel[]>) => {
    if (data == null) {
      return 1;
    }
    if (data.currentPage == null) {
      return 1;
    }

    if (data.currentPage <= 0) {
      return 1;
    }

    return data.currentPage;
  }
);



