import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FloatingButtonComponent } from './floating-button/floating-button.component';
import { RoundButtonComponent } from './round-button/round-button.component';
import { RoundOutlineButtonComponent } from './round-outline-button/round-outline-button.component';

@NgModule({
    imports: [CommonModule, TranslateModule],
    declarations: [FloatingButtonComponent,RoundButtonComponent,RoundOutlineButtonComponent],
    exports: [FloatingButtonComponent,RoundButtonComponent,RoundOutlineButtonComponent]
})
export class ButtonModule {




 }
