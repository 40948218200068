<div class="flex flex-col pt-12 pl-16 pr-16">
  <div>
    <p class="header text-5xl font-bold leading-tight">{{ 'TITLE.NOTIFICATIONS' | translate }}</p>
  </div>
</div>
<div class="table w-full pl-16 pr-16 mt-10">
  <app-notification-templates-list [currentPage]="NTcurrentPage$ | async"  [data]="(notificationTemplates$|async)?.items" (NTGoToNextEvent)="NTGoToNext($event)" (NTGoToPrevEvent)="NTGoToPrev($event)" (DeleteNotificationEvent)="onClickDeleteNotification($event)" ></app-notification-templates-list>
</div>
<div class="empty_space h-20"></div>

<app-notifications-delivered-list [currentPage]="DNcurrentPage$ | async"  [data]="(actionHistories$|async)?.items" (DNGoToNextEvent)="DNGoToNext($event)" (DNGoToPrevEvent)="DNGoToPrev($event)"></app-notifications-delivered-list>

<div class="empty_space h-20"></div>
<app-floating-button  (click)="onClickFloatingButtonHandler($event)"  class="absolute top-0 right-0 mt-12 mr-12"></app-floating-button>

