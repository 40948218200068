import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import {  NotificationTemplateModel } from '../models/notificationTemplate.model';
import { of } from 'rxjs/internal/observable/of'
import { PagedListModel } from '../models/pagedList.model'
import { loadNotificationTemplatesRequestInterface } from '../types/params/request/loadNotificationTemplatesRequest.interface'

import { ImageModel } from '../models/image.model';

import { GroupModel } from '../models/group.model'
import { loadGroupListRequestInterface } from '../types/params/request/loadGroupListRequest.interface'
import { loadGroupListResponseInterface } from '../types/params/response/loadGroupListResponse.interface'
import { DeleteGroupResponseInterface } from '../types/params/response/DeleteGroupResponse.interface'
import { deleteGroupCommandInterface } from '../types/params/request/deleteGroupCommand.interface'

@Injectable()
export class GroupService {
  constructor(private http: HttpClient) {}

  deleteGroup(data:deleteGroupCommandInterface): Observable<Boolean> {
    const url =  environment.apiUrl+'groups/delete-group';

    console.log(data);
    var body = data.request;
   return this.http.post(url, body)
        .pipe(
          map((response: DeleteGroupResponseInterface) => {
            // return response.result
            console.log(response);
              return response.status != 0;

          }),
          catchError(this.errorHandler)
    )
  }


  errorHandler(error: HttpErrorResponse, caught:any) {
      console.log(error);
      return throwError(error.statusText || "server error.");
      //return Observable.throw(error.statusText);
    // return Observable.throw(error.message || "server error.");
  }

  getGroupList(data:loadGroupListRequestInterface): Observable<PagedListModel<GroupModel[]>> {
    const url =  environment.apiUrl+'groups/group-list?currentPage='+data.request.currentPage+'&pageSize='+data.request.pageSize;

    return this.http.get(url)
          .pipe(
            map((response: loadGroupListResponseInterface) => {
              return response.result
            }),
            catchError(this.errorHandler)
      )
  }
}
