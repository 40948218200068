import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
// import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';


import { RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { ModalModule } from './_modal';
import { CKEditorModule } from 'ckeditor4-angular';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { NotificationsModule } from './notifications/notifications.module';


import { reducers, metaReducers } from "./shared/state";
import { ButtonModule } from './shared/components/buttons/buton.module';
import { ImagesModule } from './images/images.module';
import { DndDirective } from './shared/directives/dnd.directive';
import { GroupModule } from './group/group.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_INITIALIZER } from '@angular/core';
import { AuthenticationModule } from './authentication/authentication.module';
import { AuthModule, LogLevel, OidcConfigService } from 'angular-auth-oidc-client';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import { TokenInterceptor } from './authentication/interceptors/TokenInterceptor.interceptor';
import { JwtInterceptor } from './authentication/interceptors/JwtInterceptor.interceptor';
import { AdminModule } from './admin/admin.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


export function configureAuth(oidcConfigService: OidcConfigService) {
  return () =>
      oidcConfigService.withConfig({

          stsServer: environment.authUrl.stsServer,
          clientId: environment.authUrl.clientId,

          scope: environment.authUrl.scope,
          responseType: environment.authUrl.responseType,

          redirectUrl:  environment.authUrl.redirectUrl,
          postLogoutRedirectUri:  environment.authUrl.postLogoutRedirectUri,
           silentRenew: true,
          silentRenewUrl: environment.authUrl.silentRenewUrl,//"https://dev.pnpportal.redone.co.th/silent-renew.html",//`${window.location.origin}/silent-renew.html`,
          logLevel: LogLevel.Warn


      });
}


@NgModule({
  declarations: [
    AppComponent,
    DndDirective,
    TruncatePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AuthModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
                  provide: TranslateLoader,
                  useFactory: HttpLoaderFactory,
                  deps: [HttpClient]
      }
    }),
    FormsModule,
    ModalModule,
    CKEditorModule,
    NotificationsModule,
    AuthenticationModule,
    ImagesModule,
    GroupModule,
    AdminModule,
    // StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    // StoreModule.forRoot({}, {}),
    StoreRouterConnectingModule.forRoot(),

    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([]),
    ButtonModule,
    StoreModule.forRoot(reducers, {
      metaReducers
    })
  ],
  providers: [
    OidcConfigService,
    {
        provide: APP_INITIALIZER,
        useFactory: configureAuth,
        deps: [OidcConfigService],
        multi: true,
    },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
