import { createSelector } from '@ngrx/store';
import { PagedListModel } from '../models/pagedList.model';

import { GroupModel } from '../models/group.model';
import { GroupApiActions, GroupPageActions } from 'src/app/group/actions';
import { goPrevGroupListPage } from '../../group/actions/group-page.actions';


export interface GroupStateInterface {
  data: PagedListModel<GroupModel[]> | null
  isLoading: boolean
  error: string | null
}

export const initialState : GroupStateInterface =  {
    data : null,
    isLoading : false,
    error : null
}

export function reducer(
  state = initialState,
  action: GroupPageActions.GroupActions | GroupApiActions.GroupActions
): GroupStateInterface {
  switch (action.type) {
    case GroupApiActions.GroupListLoadedSuccess.type:
      return   {
        ...state,
        isLoading: false,
        data:action.groupList
      }

    case GroupPageActions.loadGroupList.type:
        return   {
          ...state,
          isLoading: true
        }
    case GroupPageActions.goNextGroupListPage.type:
        return   {
            ...state,
            isLoading: true
         }
   case GroupPageActions.goPrevGroupListPage.type:
          return   {
              ...state,
              isLoading: true
           }

      case GroupPageActions.DeleteGroup.type:
            return   {
                ...state,
                isLoading: true
             };
          case GroupApiActions.DeleteGroupSuccess.type:
           {

            var toberemove = state.data.items.findIndex(a => a.groupId == action.groupId);

            return   {
              ...state,
             data:  <PagedListModel<GroupModel[]>>{
                items:[
                  ...state.data.items.slice(0, toberemove),
                  ...state.data.items.slice(toberemove + 1)
                ]
              },
                isLoading: false
            };

           }

         case GroupApiActions.DeleteGroupFailed.type:
                return   {
                    ...state,
                    isLoading: false,
                    error: action.errors
                };
    default:
      return state;
  }
}


/**
 * Selectors
 */

export const selectAllGroups= (state:GroupStateInterface ) => state.data;
export const selectAllGroupsState= (state:GroupStateInterface ) => state;
export const getAllGroupsSelector= createSelector(
  selectAllGroups,
  (data:PagedListModel<GroupModel[]>) => {
     return data
  }
);

export const getAllGroupsSelectorLoadingState = createSelector(
  selectAllGroupsState,
  (data: GroupStateInterface) => {
    return data.isLoading;
  }
);



export const getGroupsCurrentPageSelector= createSelector(
  selectAllGroups,
  (data:PagedListModel<GroupModel[]>) => {

    if(data == null){
      return 1;
    }
    if(data.currentPage == null){
      return 1;
    }

    if(data.currentPage <= 0){
      return 1;
    }

     return data.currentPage
  }
);
