<div class="table w-full pl-16 pr-16" >
  <div class="table__header flex content-evenly items-center mb-6">
    <div class="pr-4 font-medium text-2xl leading-tight">{{'PAGE_NOTIFICATIONS.LIST_NOTIFICATIONS.DELIVERED' | translate }}</div>
    <div class="flex-grow"></div>
    <button type="button" class="pl-4 w-8" (click)="onClickPrevPage($event)">
      <img src="/assets/images/back-icon.png" class="w-full block" />
    </button>
    <div class="pl-4 w-8 text-center text-gray-800">
      <p>{{currentPage}}</p>
      <!-- page number here  -->
    </div>
    <button type="button" class="pl-4 w-8" (click)="onClickNextPage($event)">
      <img src="/assets/images/forward-icon.png" class="w-full block" />
    </button>
  </div>

  <div *ngFor="let d of  data" class="table__content">
    <div class="table__row flex content-evenly items-center pb-3">
      <div class="pr-4 w-6">
        <img class="w-full block" src="/assets/images/point-icon.png" />
      </div>
      <div class="flex-grow table__description">
        <div class="row">
          {{d.actionDescription}}
        </div>
        <div class="row">
          {{d.actionDescriptionThai}}
        </div>
      </div>
      <div style="flex-basis: 5%;">

      </div>
      <div class="" style="flex-basis: 35%;">
        <p class="text-right">{{d.dtCreated}}</p>
      </div>
    </div>
  </div>
</div>
