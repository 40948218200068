import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-simple-spinner',
  templateUrl: './simple-spinner.component.html',
  styleUrls: ['./simple-spinner.component.scss']
})
export class SimpleSpinnerComponent implements OnInit {

  @Input() message = '';

  constructor() { }

  ngOnInit() {
  }
}
