<div
  [@flyInOut]
  class="relative rounded-lg overflow-y-scroll flex-auto bg-white h-full landing-page__nestedcontainer--right"
>
  <div class="flex flex-col pt-12 pb-6 pl-16 pr-16">
    <div class="flex flex-row items-center">
      <button class="pl-4 mr-4 w-8" (click)="onClickPrevPage($event)">
        <img src="/assets/images/back-icon.png" class="w-full block" />
      </button>

      <p class="header text-5xl font-bold leading-tight">Details page</p>
    </div>

    <!-- <div class="subheader-caption border-b-2 mt-12 border-solid border-gray-300">
      <p class="text-gray-600 tracking-widest text-xs pb-3 leading-tight">
        STEP TWO
      </p>
    </div> -->
    <!-- <div class="subheader mt-2">
      <p class="text-3xl text-gray-900 leading-tight">
       What is the target group you want to send to?
      </p>
    </div> -->
    <div class="table__content pt-6 ml-8 mr-8">
      <!-- {{notificationTemplateState$|async|json}} -->

      <div class="subheader-caption border-b-2 border-solid border-gray-300">
        <p class="text-gray-600 tracking-widest text-xs pb-3 leading-tight">
          Notification info
        </p>
      </div>

      <div>
        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Notification Type:</p>
          <p class="text-base text-gray-900">
            {{
              notificationType[
                (notificationTemplateState$ | async)?.notificationType
              ]
            }}
          </p>
        </div>
        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Message Title:</p>
          <p class="text-base text-gray-900">
            {{ (notificationTemplateState$ | async)?.messageTitle }}
          </p>
        </div>
        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Message Body:</p>
          <p class="text-base text-gray-900">
            {{ (notificationTemplateState$ | async)?.messageTitle }}
          </p>
        </div>
      </div>


      <div class="subheader-caption border-b-2 mt-12 border-solid border-gray-300">
        <p class="text-gray-600 tracking-widest text-xs pb-3 leading-tight">
          Template info
        </p>
      </div>
      <div>
        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Template Page Title:</p>
          <p class="text-base text-gray-900">
            {{ (notificationTemplateState$ | async)?.templatePageTitle }}
          </p>
        </div>
        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Bottom Button Title:</p>
          <p class="text-base text-gray-900">
            {{ (notificationTemplateState$ | async)?.templateBottomButtonTitle }}
          </p>
        </div>
        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Template Body:</p>
          <div>

        </div>
        <!-- {{!((notificationTemplateState$ | async)?.templateContents != null)}} -->
        <div class="mt-2 flex flex-col items-center w-full">

            <ng-container *ngIf="!((notificationTemplateState$ | async)?.templateContents == null)">
              <div class="relative w-full border-b-2 border-gray-400 pb-2" *ngFor="let control of (notificationTemplateState$ | async)?.templateContents; index as i">

                <ng-container *ngIf="control?.templateContentType == 1" class="flex flex-col">
                  <div class="w-full text-left pt-6 flex-1" >{{control?.templateContent }} </div>

                </ng-container>

                <ng-container *ngIf="control?.templateContentType == 2">
                  <div class="h-full w-full text-left pt-6 ">
                  <img  [src]="control?.templateContent"
                  class="block border-2 rounded-lg ml-auto mr-auto"
                  style="height:240px; width:480px; background-position: center center;
                  background-repeat: no-repeat;object-fit: cover"
                  />
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>


      <div *ngIf="(notificationTemplateState$ | async)?.notificationType != 1" class="subheader-caption border-b-2 border-solid border-gray-300  mt-12 ">
        <p class="text-gray-600 tracking-widest text-xs pb-3 leading-tight">
          Action info
        </p>
      </div>

      <div *ngIf="(notificationTemplateState$ | async)?.notificationType != 1"  >
        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Message Title:</p>
          <p class="text-base text-gray-900">
            {{ (notificationTemplateState$ | async)?.actionMessageTitle }}
          </p>
        </div>
        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Message Body:</p>
          <p class="text-base text-gray-900">
            {{ (notificationTemplateState$ | async)?.actionMessageBody }}
          </p>
        </div>
        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Product Id:</p>
          <p class="text-base text-gray-900">
            {{ (notificationTemplateState$ | async)?.actionProductId }}
          </p>
        </div>

        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Negative Button:</p>
          <p class="text-base text-gray-900">
            {{ (notificationTemplateState$ | async)?.actionNegativeButtonText }}
          </p>
        </div>

        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Positive Button:</p>
          <p class="text-base text-gray-900">
            {{ (notificationTemplateState$ | async)?.actionPositiveButtonText }}
          </p>
        </div>

        <div class="mt-2 flex flex-row items-center">
          <p class="text-base text-gray-900 w-48">Positive Button Success Message:</p>
          <p class="text-base text-gray-900">
            {{ (notificationTemplateState$ | async)?.actionPositiveButtonSuccessMessageText }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
