import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-toggleable-labelfield',
  templateUrl: './toggleable-labelfield.component.html',
  styleUrls: ['./toggleable-labelfield.component.scss']
})
export class ToggleableLabelfieldComponent implements OnInit {

  @Input() isPositive:boolean;

  constructor() { }

  ngOnInit(): void {
    console.log(this.isPositive);
  }

}
