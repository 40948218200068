<button
[type]="type"
[ngClass]=
"{
  'border-yellow-600': color === 'yellow',
  'bg-yellow-600': color === 'yellow',
  'border-gray-700': color === 'dark',
  'bg-gray-700': color === 'dark',
  'text-white': color === 'yellow' || color === 'dark'
}"
class="rounded-full pl-10 pr-10 pt-2 pb-2 outline-none shadow-md">
  <ng-content></ng-content>
</button>
