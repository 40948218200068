import { trigger, transition, style, animate, state, group } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from '@angular/router';
import { GroupModel } from 'src/app/shared/models/group.model';
import { ImageModel } from 'src/app/shared/models/image.model';
import { ModalService } from 'src/app/_modal';

@Component({
  selector:'app-group-list',
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeInOut', [
      // ...
      state('fadeIn', style({
        opacity: 1
      })),
      state('fadeOut', style({
        opacity: 0,
      })),
      transition('in => out', [
        animate('1s ease-in')
      ]),
      transition('out => in', [
        animate('0.5s ease-out')
      ]),
    ]),
    trigger('showHidden', [
      state('in', style({
        opacity: 1
      })),
      transition('void => *', [
        style({  opacity:  1}),
        group([
          animate('0.5s ease', style({
            opacity: 1
          }))
        ])
      ]),
      transition('* => void', [
        group([
          animate('0.5s 0.2s ease', style({
            opacity:  0.6
          }))
        ])
      ])
    ]),
      trigger('flyInOut', [
        state('in', style({
          transform: 'translateX(0)', opacity: 1
        })),
        transition('void => *', [
          style({  transform: 'translateX(10px)', opacity:  1}),
          group([
            animate('0.5s 0.1s ease', style({
              transform: 'translateX(0)'
            })),
            animate('0.5s ease', style({
              opacity: 1
            }))
          ])
        ]),
        transition('* => void', [
          group([
            animate('0.5s ease', style({
              transform: 'translateX(10px)'
            })),
            animate('0.5s 0.2s ease', style({
              opacity:  0.6
            }))
          ])
        ])
      ])
  ],
  templateUrl: './group-list.component.html',
  styleUrls:['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {
  @Input() data: GroupModel[];
  @Input() currentPage: number;

  @Output('GoToNextEvent') goToNextEvent = new EventEmitter<number>();
  @Output('GoToPrevEvent') goToPrevEvent = new EventEmitter<number>();
  @Output('CheckGroupEvent') checkGroupEvent = new EventEmitter<GroupModel>();
  @Output('DeleteGroupEvent') deleteGroupEvent = new EventEmitter<GroupModel>();



  constructor(
    private modalService:ModalService,
    private _router:Router
  ) {

  }

  ngOnInit(): void {

  }

  onClickNextPage(ev:Event){

    this.goToNextEvent.emit(1);
  }

  onClickPrevPage(ev:Event){

    this.goToPrevEvent.emit(1);
  }


  onClickCheckGroup(groupModel:GroupModel){
   this.checkGroupEvent.emit(groupModel);
  }

  onClickDeleteGroup(groupModel:GroupModel){
    this.deleteGroupEvent.emit(groupModel);
  }



}
