import { createAction, props } from '@ngrx/store';
import { deleteImageCommandInterface } from 'src/app/shared/types/params/request/deleteImageCommand.interface';
import { loadImagesListRequestInterface } from 'src/app/shared/types/params/request/loadImagesListRequest.interface';

/* load images*/
export const loadImagesList = createAction(
  "[Images Page] Load images List",
  props<{request: loadImagesListRequestInterface}>()
  );

  export const goNextIMPage = createAction(
    "[Images Page] Go Next images list Page",
    props<{request: loadImagesListRequestInterface}>()
    );


  export const goPrevIMPage = createAction(
    "[Images Page] Go Prev images list Page",
    props<{request: loadImagesListRequestInterface}>()
    );

      /* delete image */
      export const DeleteImage = createAction(
        "[Images Page] Delete image",
        props<{command: deleteImageCommandInterface}>()
      );


export type ImagesActions = ReturnType<
| typeof loadImagesList
| typeof goNextIMPage
| typeof goPrevIMPage
| typeof DeleteImage

>
