import { ImageModel } from '../../../shared/models/image.model';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, concat, Observable, ReplaySubject, Subscription } from 'rxjs';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { ModalService } from 'src/app/_modal';
import * as fromRoot from "src/app/shared/state";

import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

import { SpinnerOverlayService } from '../../../shared/components/spinner/spinner-overlay/spinner-overlay.service';
import { StaffModel } from 'src/app/shared/models/staff.model';
import { AdminPageActions } from '../../actions';
import { loadStaffListRequestInterface } from 'src/app/shared/types/params/request/loadStaffListRequest.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs/internal/observable/of';
import { UpdateStaffInfoCommandInterface } from 'src/app/shared/types/params/request/UpdateStaffInfoCommand.interface';



export interface SelectOptions{
  name:string
  id:string
}

@Component({
  selector: "app-staff-page",
  templateUrl: "./staff-page.component.html",
  styleUrls: ["./staff-page.component.scss"]
})

export class StaffPageComponent implements OnInit, OnDestroy {

  staffList$: Observable<PagedListModel<StaffModel[]>>;
  STcurrentPage$: Observable<number>;
  STcurrentPage:number;
  STcurrentPageSubscription: Subscription;
  STLoadingState$: Observable<boolean>;
  STLoadingStateSubscription: Subscription;
  editRoleSubscription:Subscription;
  editStaffIsActiveSubscription:Subscription;
  editStaffForm: FormGroup;
  roles = [];
  staffIsActiveStates = [];
  selectedStaff: StaffModel;

  staffOptionsSubject: ReplaySubject<StaffModel>;

  constructor(
    private modalService:ModalService,
    private _router:Router,
    private store: Store<fromRoot.State>,
    private spinnerOverlayService:SpinnerOverlayService,
    private formBuilder:FormBuilder
    ) {
      this.staffList$ = this.store.pipe(select(fromRoot.selectAllStaffList));
      this.STcurrentPage$ = this.store.pipe(select(fromRoot.selectAllStaffListCurrentPage));
      this.STLoadingState$ = this.store.pipe(select(fromRoot.selectAllStaffListLoadingState));
      this.STcurrentPageSubscription = this.STcurrentPage$.subscribe((res)=>{
        if(res == null){
          res = 1;
        }
        this.STcurrentPage = res;
      })

      this.STLoadingStateSubscription = this.STLoadingState$.subscribe((res)=> {
        if(res){
          this.spinnerOverlayService.show();
        }else {
          var me = this;
          setTimeout(function(){
            me.spinnerOverlayService.hide();
          },1000)

        }
      });

      this.editStaffForm = this.formBuilder.group({
        roles:'',
        isActive:''
      });

      this.editRoleSubscription = of(this.getRolesOptions()).subscribe(rolesItem => {
        this.roles = rolesItem;
      });

      this.editStaffIsActiveSubscription = of(this.getStaffIsActiveOptions()).subscribe(isActiveState => {
        this.staffIsActiveStates = isActiveState;
      });

      this.staffOptionsSubject = new ReplaySubject<StaffModel>(1);
      this.staffOptionsSubject.subscribe(staff => {
        console.log(staff.roleId);
        this.editStaffForm.controls.roles.patchValue(staff.roleId.toString());
        this.editStaffForm.controls.isActive.patchValue((staff.active?1:0));
      })
    }

  ngOnDestroy(): void {
    this.STcurrentPageSubscription.unsubscribe();
    this.STLoadingStateSubscription.unsubscribe();
    this.editRoleSubscription.unsubscribe();
    this.editStaffIsActiveSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.getStaffList();
  }

  getStaffIsActiveOptions():SelectOptions[]{
    return [
      { id: '0', name: 'false'},
      { id: '1', name: 'true'},
    ];
  }

  getRolesOptions():SelectOptions[]{
    return [
      { id: '1', name: 'Member'},
      { id: '2', name: 'Admin'},
    ];
  }

  getStaffList() {
    const STRequestParams: loadStaffListRequestInterface = {
        request: {
          currentPage : this.STcurrentPage,
          pageSize : environment.pageSize
        }
    }
    this.store.dispatch(AdminPageActions.loadStaffList({request:STRequestParams}));

  }


  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  STGoToNext(event:Event){

    const requestParams: loadStaffListRequestInterface = {
      request: {
        currentPage : this.STcurrentPage + 1,
        pageSize : environment.pageSize
      }
    }
    this.store.dispatch(AdminPageActions.goNextSTPage({request:requestParams}));


  }

  STGoToPrev(event:Event){
    const requestParams: loadStaffListRequestInterface = {
      request: {
        currentPage : (this.STcurrentPage>1)?this.STcurrentPage - 1:1,
        pageSize : environment.pageSize
      }
    }

    this.store.dispatch(AdminPageActions.goPrevSTPage({request:requestParams}));

  }



  CheckImage(imageModel:ImageModel){
    // console.log(imageModel);

    // this.selectedImageUrl = "";
    // this.selectedImageUrl = imageModel.remoteUrl;

    // this.openModal('view-image-modal');
  }

  openEditStaffModal(staffModel:StaffModel){
    console.log(staffModel);
    this.selectedStaff = staffModel;
    this.openModal('view-staff-modal');
    this.staffOptionsSubject.next(this.selectedStaff);
  }


  onClickFloatingButtonHandler(ev:Event){
    this._router.navigate(['./admin/create-staff']);
  }


  onSubmitEditSaffForm(ev:Event){

    if(!this.editStaffForm.invalid){
      const requestParams: UpdateStaffInfoCommandInterface = {
        request: {
          staffId: this.selectedStaff.staffId,
          roleId : this.editStaffForm.value.roles,
          isActive: ((parseInt(this.editStaffForm.value.isActive) == 1) ? true: false)
        }
      }

      this.store.dispatch(AdminPageActions.updateStaffInfo({request:requestParams}));
      this.closeModal('view-staff-modal');
    }
  }
}
