

export const environment = {
  production: true,
  apiUrl:"https://staging.pnpapi.redone.co.th/api/",
  pageSize:10,
  authUrl: {
    stsServer: 'https://staging.login.redone.co.th/core',
    clientId: 'pushNotificationPortal.web',
    clientSecret: 'EesYptLYo6l8Wz2ldo1liuL44U7dLFeAEhxTYJir9BdyHASdmE',
    scope: 'openid profile email roles address red1ThaiPNPortalApi',
    responseType: 'code',
    silentRenewUrl:"https://staging.pnpportal.redone.co.th/silent-renew.html",
    redirectUrl: "https://staging.pnpportal.redone.co.th/#/",
    postLogoutRedirectUri:    "https://staging.pnpportal.redone.co.th/#/"
  }
};
