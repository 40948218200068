import { createSelector } from '@ngrx/store';
import { PagedListModel } from '../models/pagedList.model';
import { ImageModel } from '../models/image.model';
import { ImagesApiActions, ImagesPageActions } from 'src/app/images/actions';
import { NewStaffModel, StaffModel } from '../models/staff.model';
import { AdminApiActions, AdminPageActions } from 'src/app/admin/actions';

export interface NewStaffStateInterface {
  data: NewStaffModel | null;
  isLoading: boolean;
  error: string | null;

}

export const initialState: NewStaffStateInterface = {
  data: null,
  isLoading: false,
  error: null,
};

export function reducer(
  state = initialState,
  action: AdminPageActions.AdminActions | AdminApiActions.AdminActions
): NewStaffStateInterface {
  switch (action.type) {
    case AdminApiActions.CreateNewStaffSuccess.type:
      return {
        ...state,
        isLoading: false
      };

    case AdminApiActions.CreateNewStaffFailed.type:
      return {
        ...state,
        isLoading: false
      };

    case AdminPageActions.createNewStaff.type:
      return {
        ...state,
        isLoading: true
      };
    case AdminPageActions.discardNewStaff.type:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
}

/**
 * Selectors
 */

export const selectNewStaff = (state: NewStaffStateInterface) => state.data;
export const selectNewStaffState = (state: NewStaffStateInterface) => state;
export const getNewStaffSelector = createSelector(
  selectNewStaff,
  (data: NewStaffModel) => {
    return data;
  }
);

export const getNewStaffSelectorLoadingState = createSelector(
  selectNewStaffState,
  (data: NewStaffStateInterface) => {
    return data.isLoading;
  }
);



