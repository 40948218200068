import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-dark-textfield',
  templateUrl: './dark-textfield.component.html',
  styleUrls: ['./dark-textfield.component.scss']
})
export class DarkTextfieldComponent implements OnInit {

  @Input() placeholderText:string;
  @Input() type:string;


  constructor(public controlContainer: ControlContainer) {


   }

  ngOnInit(): void {
  }

}
