import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { NotificationRecordModel } from 'src/app/shared/models/notificationRecord.model';
import * as fromRoot from "src/app/shared/state";
import { loadNewNotificationRecordRequestInterface } from 'src/app/shared/types/params/request/loadNewNotificationRecordRequest.interface';
import { UpdateNotificationMessageContentCommandInterface } from 'src/app/shared/types/params/request/UpdateNotificationMessageContentCommand.Interface';
import { NotificationsPageActions } from '../../actions';

@Component({
  selector: 'app-create-notification-step-two',
  templateUrl: './create-notification-step-two.component.html',
  styleUrls: ['./create-notification-step-two.component.scss']
})
export class CreateNotificationStepTwoComponent implements OnInit, OnDestroy {

  initializedNotificationRecord:NotificationRecordModel =  {
    id:"0",
    notificationType: null,
    messageTitle: null,
    messageBody: null,
    templateId: null,// uuid
    templatePageTitle:  null,
    templateBottomButtonTitle:null,
    templateContents:null,
    actionId: null ,// uuid
    actionProductId: null,
    actionMessageTitle:null,
    actionMessageBody:null,
    actionPositiveButtonText:null,
    actionNegativeButtonText :  null,
    actionPositiveButtonSuccessMessageText:null
    }

  newNotificationRecord$ : Observable<NotificationRecordModel>;

  newNotificationRecordSubscription: Subscription

  slug:string

  form:FormGroup

  submitted:boolean = false

  constructor(
    private _router:Router,
    private fb: FormBuilder,
    private route:ActivatedRoute,
    private store: Store<fromRoot.State>) { }

    ngOnDestroy(): void {
      this.newNotificationRecordSubscription.unsubscribe();
    }
  ngOnInit(): void {
    this.initializeValues();
    this.fetchData();

    this.setSubscription()

    // if the form is not being initialized
      if( this.form == null){
        this.initializeForm()
      }
  }


  get formControl() { return this.form.controls; }



  initializeValues():void {
    this.slug = this.route.snapshot.paramMap.get('id')
    this.newNotificationRecord$ = this.store.pipe(select(fromRoot.selectNewNotificationRecordSelector));

  }

  setSubscription():void {
    this.newNotificationRecordSubscription = this.newNotificationRecord$
    .subscribe((res)=>{
      if(res != null){
      this.initializedNotificationRecord = res

      }

      // // if the form is not being initialized
      // if( this.form == null){
      //   this.initializeForm()
      // }
    })
  }

  fetchData():void {
    let requestParams: loadNewNotificationRecordRequestInterface = {
      request: {
        slug: this.slug
      }
    }

    this.store.dispatch(NotificationsPageActions.LoadNewNotificationRecord({request: requestParams}));
  }

  initializeForm ():void {
    this.form
       =  new FormGroup({
          "messageTitle":new FormControl(this.initializedNotificationRecord.messageTitle, [Validators.required,Validators.maxLength(50),Validators.minLength(5)]),
          "messageBody":new FormControl(this.initializedNotificationRecord.messageBody,[ Validators.required,Validators.maxLength(200),  Validators.minLength(10)])
        });
  }

  onClickClearMessageTemplateHandler(){
    var answer = confirm("Confirm to discard this message template?");
    if (answer) {
        //some code
        this.store.dispatch(NotificationsPageActions.DiscardNewMessageTemplate());

    }
    else {
        //some code
    }
}




  onClickPreviousButtonHandler(ev:Event){
    this._router.navigate(['./notifications/create/step-one', this.slug])
  }

  onClickContinueButtonHandler(ev:Event){
    this.submitted = true;

    if (this.form.valid) {
      let commandParams:UpdateNotificationMessageContentCommandInterface = {
        request: {
          newMessage:  {
            ...this.initializedNotificationRecord,
            messageTitle: this.form.value.messageTitle,
            messageBody: this.form.value.messageBody,
          }
        }
      }

      // console.log(commandParams);

      this.store.dispatch(NotificationsPageActions.UpdateNotificationMessageContent({command: commandParams}));

      // this._router.navigate(['./notifications/create/step-three', this.slug])
    }
  }



}
