import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { NotificationsPageComponent } from './notifications/components/notifications-page/notifications-page.component';
import { NotificationsModule } from './notifications/notifications.module';

const routes: Routes = [
  {
    path: '',
    redirectTo:'notifications',
    pathMatch:'prefix'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }),NotificationsModule],
  exports: [RouterModule,TranslateModule]
})
export class AppRoutingModule {}

