<div class="flex flex-col">
  <div class="">
    <label class="block text-gray-600 font-normal text-sm mb-1 pr-4" >
      <ng-content></ng-content>
    </label>
  </div>
  <div class="pt-1">
    <p class="bg-gray-200 w-full py-2 px-4 text-gray-700 leading-tight" >
      {{labelValue}}
    </p>
  </div>
</div>
