import { createAction, props } from '@ngrx/store';
import { deleteGroupCommandInterface } from 'src/app/shared/types/params/request/deleteGroupCommand.interface';
import { loadGroupListRequestInterface } from 'src/app/shared/types/params/request/loadGroupListRequest.interface';

/* load images*/
export const loadGroupList = createAction(
  "[Group Page] Load group List",
  props<{request: loadGroupListRequestInterface}>()
  );

  export const goNextGroupListPage = createAction(
    "[Group Page] Go Next group list Page",
    props<{request: loadGroupListRequestInterface}>()
    );


  export const goPrevGroupListPage = createAction(
    "[Group  Page] Go Prev group list Page",
    props<{request: loadGroupListRequestInterface}>()
    );

 /* delete group */
 export const DeleteGroup = createAction(
  "[Group Page] Delete group",
  props<{command: deleteGroupCommandInterface}>()
);


export type GroupActions = ReturnType<
| typeof loadGroupList
| typeof goNextGroupListPage
| typeof goPrevGroupListPage
| typeof DeleteGroup

>
