import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor( private oidcSecurityService: OidcSecurityService){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(

      tap({
        next: val => {
          // on next 11, etc.
          // console.log('on next', val);
          if (val instanceof HttpResponse) {
            // do stuff with response if you want

          }
        },
        error: error => {
          console.log('on error', error.message);
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              // redirect to the login route
              // or show a modal
              this.oidcSecurityService.logoff();

            }
          }
        },
        complete: () => console.log('on complete')
      })
    );
  }
}
