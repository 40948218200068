import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { EffectsModule } from "@ngrx/effects";
import { NotificationsApiEffects } from './notifications-api.effects';
import { NotificationsContainerComponent } from './containers/notifications-container.component';

import { NotificationsService } from '../shared/services/notifications.service';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationTemplatesListComponent } from './components/notification-templates-list/notification-templates-list.component';
import { NotificationsDeliveredListComponent } from './components/notifications-delivered-list/notifications-delivered-list.component';
import { NotificationsPageComponent } from './components/notifications-page/notifications-page.component';
import { ButtonModule } from '../shared/components/buttons/buton.module';
import { CreateNotificationStepOneComponent } from './components/create-notification-step-one/create-notification-step-one.component';
import { CreateNotificationStepTwoComponent } from './components/create-notification-step-two/create-notification-step-two.component';
import { CreateNotificationStepThreeComponent } from './components/create-notification-step-three/create-notification-step-three.component';
import { FormFieldModule } from '../shared/components/form-field';
import { ModalModule } from '../_modal';
import { CKEditorModule } from 'ckeditor4-angular';
import { PersistanceService } from '../shared/services/persistance.service';
import { TransformHtmlObjectForDisplayPipe } from './components/create-notification-step-three/transformHtmlObjectForDisplay.pipe';
import { TransformImageObjectForDisplayPipe } from './components/create-notification-step-three/transformImageObjectForDisplay.pipe';
import { SpinnerModule } from '../shared/components/spinner/spinner.module';
import { SpinnerOverlayService } from '../shared/components/spinner/spinner-overlay/spinner-overlay.service';
import { CreateTemplateImagesListComponent } from './components/create-template-images-list/create-template-images-list.component';
import { SendNotificationOptionsComponent } from './components/send-notification-options/send-notification-options.component';
import { SendToGroupComponent } from './components/send-to-group-user/send-to-group.component';
import { ViewNotificationTemplateComponent } from './components/view-notification-template/view-notification-template.component';
import { SendToSegmentsComponent } from './components/send-to-segments/send-to-segments.component';



const routes: Routes = [
  // Site routes goes here
   {
     path: '',
     redirectTo:'notifications',
     pathMatch:'full'
   },
   {
     path: 'notifications',
     component: NotificationsContainerComponent,
     children: [
       {path: '', redirectTo: 'list-notifications',  pathMatch:'full'},
       {path: 'list-notifications', component: NotificationsPageComponent},
       {path: 'create/step-one/:id', component: CreateNotificationStepOneComponent},
       {path: 'create/step-two/:id', component: CreateNotificationStepTwoComponent},
       {path: 'create/step-three/:id', component: CreateNotificationStepThreeComponent},
       {path: 'sendnotification/options/:msgid', component: SendNotificationOptionsComponent},
       {path: 'sendnotification/send-to-group/:msgid/:sendtarget', component: SendToGroupComponent},
       {path: 'sendnotification/send-to-segments/:msgid', component: SendToSegmentsComponent},
       {path: 'selected-notification-template/:id', component: ViewNotificationTemplateComponent}
     ]
   }
  ]

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FormFieldModule,
    ModalModule,
    CKEditorModule,
    FormsModule,

    ReactiveFormsModule,
    TranslateModule,
    SpinnerModule,
    RouterModule.forChild(routes),
    EffectsModule.forFeature([NotificationsApiEffects]),

  ],
  declarations: [
    NotificationsContainerComponent,
    NotificationsPageComponent,
    NotificationTemplatesListComponent,
    NotificationsDeliveredListComponent,
    CreateNotificationStepOneComponent,
    CreateNotificationStepTwoComponent,
    CreateNotificationStepThreeComponent,
    CreateTemplateImagesListComponent,
    TransformHtmlObjectForDisplayPipe,
    TransformImageObjectForDisplayPipe,
    SendNotificationOptionsComponent,
    SendToGroupComponent,
    ViewNotificationTemplateComponent,
    SendToSegmentsComponent
  ],
  providers: [
    NotificationsService,
    PersistanceService,
    SpinnerOverlayService
  ]
})
export class NotificationsModule {}
