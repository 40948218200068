import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GroupModel } from '../../../shared/models/group.model';
import * as uuid from 'uuid';
import { environment } from 'src/environments/environment';

//https://medium.com/@tarekabdelkhalek/how-to-create-a-drag-and-drop-file-uploading-in-angular-78d9eba0b854
@Component({
  selector: "app-upload-group-page",
  templateUrl: "./upload-group.component.html",
  styleUrls: ["./upload-group.component.scss"]
})
export class UploadGroupComponent implements OnInit, OnDestroy {
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  constructor(private http:HttpClient, private router:Router){

  }

  submitted : boolean;
  form:FormGroup;
  files: any[] = [];

  initializedFormValue:GroupModel = {
      identifier:"1",
      groupId:null,
      groupName:null,
      groupDescription:null,
      groupUsers:null,
      idType:null
  }



  ngOnInit(): void {
    this.initializeValues();
    this.initializeForm();
  }
  ngOnDestroy(): void {
  }

 /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
  }
/**
   * on file drop handler
   */
  onFileDropped($event:File[]) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files:File[]) {
    this.prepareFilesList(files);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {

    for (const item of files) {
      if((item as File).size <52001){
      item.progress = 0;
      this.files.push(item);
      } else {
        alert("upload image size less than 50kb");
      }
    }
    this.fileDropEl.nativeElement.value = "";
    this.onUpload(0);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

   /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  initializeValues() :void {
    this.initializedFormValue.groupId = uuid.v4()
  }

  initializeForm ():void {
    this.form
       =  new FormGroup({
          "identifier":new FormControl(this.initializedFormValue.identifier, [Validators.required]),
          "groupId":new FormControl(this.initializedFormValue.groupId, []),
          "groupName":new FormControl(this.initializedFormValue.groupName, [Validators.required,Validators.maxLength(100),Validators.minLength(2)]),
          "groupDescription":new FormControl(this.initializedFormValue.groupDescription,[ Validators.required,Validators.maxLength(200),  Validators.minLength(2)])
        });
  }



  onUpload(index:number) {
    this.submitted = true;
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const uploadData = new FormData();
        uploadData.append('myFile', this.files[index], this.files[index].name);
        uploadData.append('IDType', this.form.controls['identifier'].value);
        uploadData.append('GroupId', this.form.controls['groupId'].value);
        uploadData.append('GroupName', this.form.controls['groupName'].value);
        uploadData.append('GroupDescription',  this.form.controls['groupDescription'].value);


        this.http.post(environment.apiUrl+'groups/upload-group', uploadData, {
          reportProgress: true,
          observe: 'events'

        })
          .subscribe(event => {
            console.log(event); // handle event here
            var displayPercent:number=0;
            if (event.type === HttpEventType.Response) {
              console.log('Upload complete');
              console.log(event.body['status']);
              if(event.body['status'] == 1){
                  //this.onUpload(index + 1);
                  displayPercent = 100;
                  this.files[index].progress = displayPercent;

                  var me = this;
                  setTimeout(
                    function(){
                        // reset the form
                        me.form.reset();
                        me.initializeValues();
                        me.files = [];

                        // notification
                        alert("Group Csv file upload success");
                    },
                    1000
                  )


              } else {
                alert("Failed to upload the file");
                this.files.splice(index, 1);
                displayPercent = 0;
                this.files[index].progress = displayPercent;
              }
            }
            if (event.type === HttpEventType.UploadProgress) {
                const percentDone = Math.round(100 * event.loaded / event.total);
                console.log('Progress ' + percentDone + '%');

                if(percentDone == 100){
                  displayPercent = 99;
                } else{
                  displayPercent = percentDone;
                }
                this.files[index].progress = displayPercent;
            }

          });
      }
    }, 1000);
  }


  onClickBackHandler(ev:Event){

    this.router.navigate(['./groups/group-list']);


  }


}
