import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimpleSpinnerComponent } from './simple-spinner/simple-spinner.component';
import { SpinnerOverlayWrapperComponent } from './spinner-overlay-wrapper/spinner-overlay-wrapper.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner.overlay.component';


@NgModule({
    imports: [CommonModule,OverlayModule],
    declarations: [SimpleSpinnerComponent, SpinnerOverlayWrapperComponent, SpinnerOverlayComponent],
    exports: [SpinnerOverlayComponent]
})
export class SpinnerModule {




 }
