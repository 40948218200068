import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { PagedListModel } from '../shared/models/pagedList.model';
import { PersistanceService } from '../shared/services/persistance.service';
import { ImagesApiActions, ImagesPageActions } from './actions';
import { loadImagesList } from './actions/images-page.actions';
import { ImageModel } from '../shared/models/image.model';
import { EMPTY, of } from 'rxjs';
import { ImagesService } from '../shared/services/images.service';

@Injectable()
export class ImagesApiEffects {

  @Effect()
  loadIM$ = this.actions$.pipe(
    ofType(ImagesPageActions.loadImagesList.type),
    exhaustMap(({request} ) =>
      this.imagesService.getImagesList(request).pipe(
        map((imagesList:PagedListModel<ImageModel[]>) => ImagesApiActions.IMLoadedSuccess({ imagesList })),
        catchError((err:string) => of(ImagesApiActions.IMLoadedFailed({ errors:err})))
      )
    )
  );

  @Effect()
  loadNTPrevPage$ = this.actions$.pipe(
    ofType(ImagesPageActions.goPrevIMPage.type),
    exhaustMap(({request}) => {

      return this.imagesService.getImagesList( request ).pipe(
        map((imagesList:PagedListModel<ImageModel[]>) => ImagesApiActions.IMLoadedSuccess({ imagesList })),
        catchError((err:string) => of(ImagesApiActions.IMLoadedFailed({ errors:err})))
      )
    }
    )
  );

  @Effect()
  loadNTNextPage$ = this.actions$.pipe(
    ofType(ImagesPageActions.goNextIMPage.type),
    switchMap(({request}) => {
      return this.imagesService.getImagesList( request ).pipe(
        map((imagesList:PagedListModel<ImageModel[]>) => ImagesApiActions.IMLoadedSuccess({ imagesList })),
        catchError((err:string) => of(ImagesApiActions.IMLoadedFailed({ errors:err})))
      )
    }
    )
  );

  showErrorMesssageAfterIMLoadedFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ImagesApiActions.IMLoadedFailed),
        tap((err) => {
          console.log(err)
          alert(`Failed to load Image List. [error:${err.errors}]`);
        })
      ),
    {dispatch: false}
  )




  @Effect()
  deleteImage$ = this.actions$.pipe(
    ofType(ImagesPageActions.DeleteImage.type),
    switchMap(({command}) => {
      return this.imagesService.deleteImage( command ).pipe(
        map((isTrue:boolean) => {
            if(isTrue){
              return ImagesApiActions.DeleteImageSuccess({imageId:command.request.imageId});
            } else {
              return ImagesApiActions.DeleteImageFailed({errors:"Delete image failed."});
            }
          }
          ),
          catchError((err:string) => of(ImagesApiActions.DeleteImageFailed({ errors:err})))
      )
    }
   )
  );


  showAlertAfterDeleteImageSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ImagesApiActions.DeleteImageSuccess),
        tap(() => {
          alert("Image deleted success!");
        })
      ),
    {dispatch: false}
  )

  showAlertAfterDeleteImageFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ImagesApiActions.DeleteImageFailed),
        tap((err) => {
          console.log(err)
          alert(`Failed to delete image. [error:${err.errors}]`);
        })
      ),
    {dispatch: false}
  )



  constructor(
  private imagesService: ImagesService,
  private persistanceService: PersistanceService,
  private router:Router,
  private actions$: Actions<
    ImagesPageActions.ImagesActions | ImagesApiActions.ImagesActions
  >){

  }
}
