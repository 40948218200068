<!-- <div class="flex flex-col pt-12  pl-16 pr-16">
  <div>
    <p class="header text-5xl font-bold leading-tight">Login</p>
  </div>
</div> -->
<div class="table w-full pl-16 pr-16  h-full relative">
  <!-- <div>redONE PN Portal</div> -->
  <app-round-outline-button

  (click)="login($event)"
  type="button"
  class="text-gray-800 w-32 ml-auto mr-auto block absolute" style="top:50%;left:50%;transform: translateX(-50%) translateY(-50%);"
  color="dark"
>
  Login
</app-round-outline-button>
</div>
<div class="empty_space h-20"></div>

