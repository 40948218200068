<div class="flex flex-col pt-12 pb-6 pl-16 pr-16">
  <div class="">
    <p class="header text-5xl font-bold leading-tight">{{ 'PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_ONE.TITLE' | translate }}</p>
  </div>
  <div class="subheader-caption border-b-2 mt-12 border-solid border-gray-300">
    <p class="text-gray-600 tracking-widest text-xs pb-3 leading-tight">
      {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_ONE.SUBHEADER_CAPTION" | translate }}
    </p>
  </div>
  <div class="subheader mt-2">
    <p class="text-3xl text-gray-900 leading-tight">
      {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_ONE.SUBHEADER_TITLE" | translate }}
    </p>
  </div>
  <div class="content w-full mt-8 ml-3">
    <form class="w-full"  [formGroup]="form" (submit)="onClickContinueButtonHandler($event)">
      <div class="content__radio-group">
        <div>
          <label class="inline-flex items-center">
            <input type="radio" class="form-radio" name="notificationType" value="1" formControlName="notificationType" (change)="changeNotificationType($event)">
            <span class="ml-6 text-gray-600 text-sm">{{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_ONE.ANNOUNCEMENTS_LABEL" | translate }}</span>
          </label>
        </div>
        <div>
          <label class="inline-flex items-center">
            <input type="radio" class="form-radio" name="notificationType" value="2"  formControlName="notificationType" (change)="changeNotificationType($event)">
            <span class="ml-6 text-gray-600 text-sm">{{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_ONE.OFFERS_LABEL" | translate }}</span>
          </label>
        </div>
        <div>
           <label class="inline-flex items-center">
            <input type="radio" class="form-radio" name="notificationType" value="3" formControlName="notificationType" (change)="changeNotificationType($event)">
            <span class="ml-6 text-gray-600 text-sm">{{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_ONE.REWARDS_LABEL" | translate }}</span>
          </label>
      </div>
    </div>
    </form>
  </div>

  <div class="button-group flex mt-12 self-end">
    <app-round-outline-button
    (click)="onClickCancelButtonHandler($event)"
    color="dark">
    {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_ONE.CANCEL_BUTTON" | translate }}
    </app-round-outline-button>
      <app-round-button
        (click)="onClickContinueButtonHandler($event)"
        color="yellow" class="pl-4">
        {{ "PAGE_NOTIFICATIONS.CREATE_NOTIFICATIONS.STEP_ONE.CONTINUE_BUTTON" | translate }}
      </app-round-button>
  </div>
</div>
