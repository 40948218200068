import { Component, ElementRef, Renderer2, HostListener, Inject, LOCALE_ID, ViewChild, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import * as _ from 'lodash';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  group,
  // ...
} from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
// guideline to install tailwindcss
// https://notiz.dev/blog/angular-10-with-tailwindcss#remove-unused-css-styles
// https://medium.com/@jacobneterer/angular-and-tailwindcss-2388fb6e0bab
// https://phrase.com/blog/posts/angular-localization-i18n/
@Component({
  selector: 'app-root',
  animations: [
    trigger('showHidden', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({  opacity:  1}),
        group([
          animate('0.4s ease', style({
            opacity: 1
          }))
        ])
      ]),
      transition(':leave', [
        group([
          animate('0.4s 0.1s ease', style({
            opacity:  0
          }))
        ])
      ])
    ])
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  // https://dzone.com/articles/localization-in-angular-using-i18n-tools
  @ViewChild("hamburgermenu", {read: ElementRef}) hamburgerMenu: ElementRef;

  title = 'red1ThaiPnPortal';


  showMenu:boolean = false;
  isLogin:boolean;
  userName:string = '-';
  // languageList = [
  //   { code: 'en', label: 'English' },
  //   { code: 'th', label: 'ไทย' }
  // ];


  constructor(
    public oidcSecurityService: OidcSecurityService,
    private httpClient:HttpClient,
    private _router: Router,
    private _elElement: ElementRef,
    private renderer:Renderer2,
    @Inject(LOCALE_ID) protected localeId: string,
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'th']);
      translate.setDefaultLang('en');
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|th/) ? browserLang : 'en');
  }
  ngOnInit(): void {


    this.oidcSecurityService.userData$.subscribe(
      g => {
        if(g != null){
          this.userName = g["red1ThaiPnPortal.username"];
        }
      }
    );


    this.oidcSecurityService.checkAuth()
    .subscribe((auth) => {
    this.isLogin = auth;
    if(auth == false){
      this._router.navigate(['auth'])
    }
    }
    );

  }

  displayMenu(eventTargetElem:EventTarget){
    this.showMenu = !this.showMenu;
  }

  updateMenuElementActiveState(eventTargetElem:EventTarget){
    let menuElements = document.getElementsByClassName("navigation__menu");

    // clear all active state
    _.map(menuElements, (elem)=> {
        let elemItem = elem as HTMLElement;
        elemItem.classList.remove("active");
        });

    this.renderer.addClass(eventTargetElem, 'active');
    // add thre new active class to the target button element
    // targetElem.classList.add("active");
  }


  navigateToNotificationsPage(ev:Event) {
    this._router.navigate(['notifications'])
    this.updateMenuElementActiveState(ev.currentTarget);
  }


  navigateToRoutingTargetsPage(ev:Event) {
    this._router.navigate(['routing-targets']);
    this.updateMenuElementActiveState(ev.currentTarget);
  }



  collapseMenu(ev:Event)
  {

  }

  signOut()
  {
      this.oidcSecurityService.logoff();
  }
}

