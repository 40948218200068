import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../shared/components/buttons/buton.module';
import { FormFieldModule } from '../shared/components/form-field';
import { ImagesService } from '../shared/services/images.service';
import { PersistanceService } from '../shared/services/persistance.service';
import { ModalModule } from '../_modal';
import { ImagesListComponent } from './components/images-list/images-list.component';
import { ImagesContainerComponent } from './containers/images-container.component';
import { ImagesApiEffects } from './images-api.effects';
import { ImagesPageComponent } from './components/images-page/images-page.component';
import { DndDirective } from '../shared/directives/dnd.directive';
import { GraphModule } from '../shared/components/graphs/graph.module';


const routes: Routes = [
  // Site routes goes here
   {
     path: '',
     redirectTo:'images',
     pathMatch:'full'

   }
   ,
   {
     path: 'images',
     component: ImagesContainerComponent,
     children:[
      {path: '', redirectTo: 'images-list',  pathMatch:'full'},
      {
        path:'images-list',
        component: ImagesPageComponent
      },
      {
        path:'upload-image',
        component: UploadImageComponent
      }]

   }
  ]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ButtonModule,
    FormFieldModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    EffectsModule.forFeature([ImagesApiEffects]),
GraphModule
  ],
  declarations: [
    ImagesContainerComponent,
    ImagesListComponent,
    ImagesPageComponent,
    UploadImageComponent

  ],
  providers: [
    PersistanceService,
    ImagesService
  ]
})
export class ImagesModule {}
