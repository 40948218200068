
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createSelector } from '@ngrx/store';
import { NotificationsApiActions, NotificationsPageActions } from 'src/app/notifications/actions';
import { NotificationTemplateModel } from '../models/notificationTemplate.model';
import { PagedListModel } from '../models/pagedList.model';
import { NotificationRecordModel } from '../models/notificationRecord.model';

export interface CreateNotificationStateInterface {
  data: NotificationRecordModel | null
  isLoading: boolean
  error: string | null
}

export const initialState : CreateNotificationStateInterface =  {
    data : null,
    isLoading : false,
    error : null
}

export function reducer(
  state = initialState,
  action: NotificationsPageActions.NotificationsActions | NotificationsApiActions.NotificationApiActions
): CreateNotificationStateInterface {
  switch (action.type) {
    case NotificationsApiActions.NewNotificationInitializedSuccess.type:
      return   {
        ...state,
        isLoading: false,
        data:action.newNotification
      }

    case NotificationsPageActions.InitializeNewNotification.type:
        return   {
          ...state,
          isLoading: true
    }

    case NotificationsPageActions.UpdateSelectedNotificationType.type:
      return   {
        ...state,
        isLoading: true
    }

    case NotificationsApiActions.SelectedNotificationTypeUpdatedSuccess.type:
      return   {
        ...state,
        isLoading: false,
        data: action.newNotification
    }

    case NotificationsApiActions.NewNotificationRecordLoadedSuccess.type:
      return   {
        ...state,
        isLoading: false,
        data:action.newNotification
      }

      case NotificationsApiActions.NewActionCreatedSuccess.type:
        return   {
          ...state,
          isLoading: false,
          data:action.newNotification
        }

      case NotificationsApiActions.NewContentTemplateCreatedSuccess.type:
          return   {
            ...state,
            isLoading: false,
            data:action.newNotification
      }

      case NotificationsPageActions.CreateNewContentTemplate.type:
        return   {
          ...state,
          isLoading: true,
    }

    case NotificationsPageActions.UpdateNotificationMessageContent.type:
      return   {
        ...state,
        isLoading: true,
    }


    case NotificationsPageActions.CreateNewAction.type:
      return   {
        ...state,
        isLoading: true,
    }

    case NotificationsApiActions.NewActionCreatedSuccess.type:
      return   {
        ...state,
        isLoading: false,
        data:action.newNotification
    }


    case NotificationsPageActions.ResetAndSaveAction.type:
      return   {
        ...state,
        isLoading: true,
    }

    case NotificationsApiActions.ActionResetAndSaveSuccess.type:
      return   {
        ...state,
        isLoading: false,
        data:action.newNotification
    }



    case NotificationsPageActions.DiscardNewMessageTemplate.type:
      return   {
        ...state,
        isLoading: true,
    }

    case NotificationsApiActions.NewMessageTemplateDiscardSuccess.type:
      return   {
        ...state,
        isLoading: false,
    }

    case NotificationsApiActions.NewNotificationTemplateCreatedSuccess.type:
      return   {
        ...state,
        isLoading: false,
    }

    case NotificationsApiActions.NewNotificationTemplateCreatedFailed.type:
      return   {
        ...state,
        isLoading: false,
        error: action.errors
    }




    default:
      return state;
  }
}


/**
 * Selectors
 */
// export const  selectAllNotificationsTemplates:PagedListModel<NotificationTemplateModel[] = notificationTemplatesState.data
//  export const selectAllNotificationsTemplates =
// export const { selectAll, selectEntities } = adapter.getSelectors();

export const selectNewNotificationRecord = (state:CreateNotificationStateInterface ) => state.data;
export const selectNewNotificationRecordState = (state:CreateNotificationStateInterface ) => state;
export const getNewNotificationRecordSelector= createSelector(
  selectNewNotificationRecord,
  (data:NotificationRecordModel) => {
     return data
  }
);


export const selectCreateNotificationError= (state:CreateNotificationStateInterface ) => state.error;
export const getCreateNotificationErrorSelector= createSelector(
  selectCreateNotificationError,
  (error:string) => {
     return error;
  }
);


export const getCreateNotificationLoadingSelector= createSelector(
  selectNewNotificationRecordState,
  (state:CreateNotificationStateInterface) => {
     return state.isLoading;
  }
);






