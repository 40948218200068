import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { ModalService } from 'src/app/_modal';
import * as fromRoot from "src/app/shared/state";
import { loadNotificationTemplatesRequestInterface } from 'src/app/shared/types/params/request/loadNotificationTemplatesRequest.interface';

import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

import { SpinnerOverlayService } from '../../../shared/components/spinner/spinner-overlay/spinner-overlay.service';
import { HttpClient } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ImagesPageActions } from 'src/app/images/actions';
import { loadImagesListRequestInterface } from 'src/app/shared/types/params/request/loadImagesListRequest.interface';
// import { AuthOptions } from 'angular-auth-oidc-client/lib/login/auth-options';

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"]
})
export class LoginPageComponent implements OnInit, OnDestroy {

  selectedImageUrl: string;
  AuthLoadingStateSubscription:Subscription;
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private httpClient:HttpClient,
    private modalService:ModalService,
    private _router:Router,
    private store: Store<fromRoot.State>,
    private spinnerOverlayService:SpinnerOverlayService
    ) {

    }
  ngOnDestroy(): void {
    this.AuthLoadingStateSubscription.unsubscribe();
  }

  ngOnInit(): void {
    var me = this;
    this.checkIfLogin();
  }

  checkIfLogin() {
    var me = this;
    this.spinnerOverlayService.show();
    this.AuthLoadingStateSubscription  = this.oidcSecurityService
    .checkAuth()
    .subscribe((auth) => {
      console.log('is authenticated', auth);
      setTimeout(function(){
        me.spinnerOverlayService.hide();
      },200)
    });
  }

  login(ev:Event) {
    this.oidcSecurityService.authorize();
  }

}
