<div [@flyInOut] class="relative rounded-lg overflow-y-scroll flex-auto bg-white h-full landing-page__nestedcontainer--right">

  <div class="flex flex-col pt-12 pb-6 pl-16 pr-16">
    <div class="">
      <p class="header text-5xl font-bold leading-tight">{{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_TO_GROUP.TITLE' | translate }}</p>
    </div>
    <div class="subheader-caption border-b-2 mt-12 border-solid border-gray-300">
      <p class="text-gray-600 tracking-widest text-xs pb-3 leading-tight">
        {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_TO_GROUP.SUBHEADER_CAPTION' | translate }}
      </p>
    </div>
    <div class="subheader mt-2">
      <p class="text-3xl text-gray-900 leading-tight">
        {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_TO_GROUP.SUBHEADER_TITLE' | translate }}
      </p>
    </div>

    <div class="table__header flex content-evenly items-center mt-8 ml-8 mr-8">
      <div class="pr-4 font-medium text-2xl"></div>
      <div class="flex-grow"></div>
    <button class="pl-4 w-8" (click)="onClickPrevPage($event)">
      <img src="/assets/images/back-icon.png" class="w-full block" />
    </button>
    <div class="pl-4 w-8 text-center text-gray-800">
      <p>{{currentPage}}</p>
      <!-- page number here  -->
    </div>
    <button class="pl-4 w-8" (click)="onClickNextPage($event)">
      <img src="/assets/images/forward-icon.png" class="w-full block" />
    </button>
    </div>

    <!-- <div class="table__content pt-6">
      <div class="table__row flex content-evenly items-center pb-3">
        <div class="pr-4 w-6">
          <img class="w-full block" src="/assets/images/point-icon.png" />
        </div>
        <div class="flex-grow table__description">redCALL Promotion 1</div>
      </div>
    </div> -->

    <div class="table__content pt-6 ml-8 mr-8">
      <div *ngFor="let d of selectedGroupUsers" class="table__row flex content-evenly items-center pb-3">
        <div class="pr-4 w-6 self-start mt-2">
          <img class="w-full block" src="/assets/images/point-icon.png" />
        </div>
        <!-- <div class="flex-grow table__description">Welcome to redONE…</div> -->
        <div class="flex flex-col flex-grow table__description ">
          <div class="flex-grow ">{{d.groupName}}</div>
          <div class="flex-grow  text-gray-500 text-xs">{{d.groupDescription}}</div>
        </div>


        <div class="ml-3 w-4 cursor-pointer" (click)="onClickSendNotification(d)">
          <img src="/assets/images/send-icon.png"  class="w-full block" />
        </div>
      </div>
    </div>

</div>

  <!-- <app-floating-button  class="absolute top-0 right-0 mt-12 mr-12"></app-floating-button> -->
</div>
