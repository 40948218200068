import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dark-labelfield',
  templateUrl: './dark-labelfield.component.html',
  styleUrls: ['./dark-labelfield.component.scss']
})
export class DarkLabelfieldComponent implements OnInit {


  @Input() labelValue:string;

  constructor() { }

  ngOnInit(): void {
  }

}
