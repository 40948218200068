import { goPrevNTPage } from './../../notifications/actions/notifications-page.actions';

import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createSelector } from '@ngrx/store';
import { NotificationsApiActions, NotificationsPageActions } from 'src/app/notifications/actions';
import { NotificationTemplateModel } from '../models/notificationTemplate.model';
import { PagedListModel } from '../models/pagedList.model';
import { DeleteNotificationSuccess } from '../../notifications/actions/notifications-api.actions';


export interface NotificationTemplatesStateInterface {
  selected: NotificationTemplateModel | null
  data: PagedListModel<NotificationTemplateModel[]> | null
  isLoading: boolean
  error: string | null
}



export const initialState : NotificationTemplatesStateInterface =  {
    selected : null,
    data : null,
    isLoading : false,
    error : null
}

// export interface State extends EntityState<NotificationModel> {
//   activeNotificationId: string | null;
//   totalPage:number | 1;
//   currentPage: number | 1;
// }

// export const adapter = createEntityAdapter<NotificationTemplateModel>();

// export const initialState = adapter.getInitialState({
//   activeNotificationId: null,
//   totalPage:1,
//   currentPage:1
// });


export function reducer(
  state = initialState,
  action: NotificationsPageActions.NotificationsActions | NotificationsApiActions.NotificationApiActions
): NotificationTemplatesStateInterface {
  switch (action.type) {
    case NotificationsApiActions.NTLoadedSuccess.type:
      return   {
        ...state,
        isLoading: false,
        data:action.notificationTemplates
      }
    case NotificationsApiActions.NTLoadedFailed.type:
        return   {
          ...state,
          isLoading: false,
          error:action.errors
        }

    case NotificationsPageActions.loadNT.type:
        return   {
          ...state,
          isLoading: true
        }
    case NotificationsPageActions.goNextNTPage.type:
        return   {
            ...state,
            isLoading: true
         }
   case NotificationsPageActions.goPrevNTPage.type:
          return   {
              ...state,
              isLoading: true
           }
    case NotificationsPageActions.DeleteNotification.type:
          return   {
              ...state,
              isLoading: true
           }
    case NotificationsApiActions.DeleteNotificationSuccess.type:
      var toberemove = state.data.items.findIndex(a => a.id == action.messageId);

      return   {
        ...state,
       data:  <PagedListModel<NotificationTemplateModel[]>>{
          items:[
            ...state.data.items.slice(0, toberemove),
            ...state.data.items.slice(toberemove + 1)
          ]
        },
          isLoading: false
      };

    case NotificationsApiActions.DeleteNotificationFailed.type:
              return   {
                  ...state,
                  isLoading: false,
                  error: action.errors
               }
    case NotificationsPageActions.LoadSelectedNotificationTemplate.type:
    {
      if( state.data != null){
        var selectedItem = state.data.items.find(a => a.id == action.request.request.slug);

        return   {
          ...state,
        selected: selectedItem,
        isLoading: false
        };
      }

    }



      //  adapter.addAll(action.notifications.result, state);

    // case BooksPageActions.selectBook.type:
    //   return {
    //     ...state,
    //     activeBookId: action.bookId
    //   };

    // case BooksPageActions.clearSelectedBook.type:
    //   return {
    //     ...state,
    //     activeBookId: null
    //   };

    // case BooksApiActions.bookCreated.type:
    //   return adapter.addOne(action.book, {
    //     ...state,
    //     activeBookId: action.book.id
    //   });

    // case BooksApiActions.bookUpdated.type:
    //   return adapter.updateOne(
    //     { id: action.book.id, changes: action.book },
    //     { ...state, activeBookId: action.book.id }
    //   );

    // case BooksApiActions.bookDeleted.type:
    //   return adapter.removeOne(action.book.id, {
    //     ...state,
    //     activeBookId: null
    //   });

    default:
      return state;
  }
}


/**
 * Selectors
 */
// export const  selectAllNotificationsTemplates:PagedListModel<NotificationTemplateModel[] = notificationTemplatesState.data
//  export const selectAllNotificationsTemplates =
// export const { selectAll, selectEntities } = adapter.getSelectors();

export const selectAllNotificationTemplates = (state:NotificationTemplatesStateInterface ) => state.data;
export const selectAllNotificationTemplatesState = (state:NotificationTemplatesStateInterface ) => state;
export const selectedNotificationTemplateState = (state:NotificationTemplatesStateInterface ) => state.selected;
export const getAllNotificationTemplatesSelector= createSelector(
  selectAllNotificationTemplates,
  (data:PagedListModel<NotificationTemplateModel[]>) => {
     return data
  }
);

// export const selectNotificationTemplatesCurrentPage = (state:NotificationTemplatesStateInterface ) => state.data.currentPage;
export const getNotificationTemplatesCurrentPageSelector= createSelector(
  selectAllNotificationTemplates,
  (data:PagedListModel<NotificationTemplateModel[]>) => {

    if(data == null){
      return 1;
    }
    if(data.currentPage == null){
      return 1;
    }

    if(data.currentPage <= 0){
      return 1;
    }

     return data.currentPage
  }
);


export const getNotificationTemplatesLoadingState= createSelector(
  selectAllNotificationTemplatesState,
  (data:NotificationTemplatesStateInterface) => {
     return data.isLoading
  }
);



export const getSelectedNotificationTemplateStateSelector = createSelector(
  selectedNotificationTemplateState,
  (data:NotificationTemplateModel) => {
     return data;
  }
);

