<div class="flex flex-col pt-12 pl-16 pr-16">
  <div>
    <p class="header text-5xl font-bold leading-tight">{{ 'TITLE.IMAGES' | translate}}</p>
  </div>
</div>
<div class="table w-full pl-16 pr-16 ">

  <div class="subheader-caption border-b-2 mt-12 border-solid border-gray-300">
    <p class="text-gray-600 tracking-widest text-xs pb-3 leading-tight">
      {{'PAGE_IMAGES.UPLOAD_IMAGE.STEP_ONE.SUBHEADER_CAPTION'| translate}}
    </p>
  </div>
  <div class="subheader mt-2 mb-10">

    <p class="text-3xl text-gray-900 leading-tight">
      {{'PAGE_IMAGES.UPLOAD_IMAGE.STEP_ONE.SUBHEADER_TITLE'| translate}}
    </p>
  </div>
  <form>
  <div appDnd class="dropzone bg-gray-200 ml-auto mr-auto  rounded-lg flex flex-col justify-center items-center pt-16 pb-16">
          <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)"/>
          <div class="w-12">
            <img src="/assets/images/ic-upload-file.svg" class="w-full block" />
          </div>

          <div class=""><p class=" mt-8 text-gray-600  text-sm  leading-tight"> {{'PAGE_IMAGES.UPLOAD_IMAGE.STEP_ONE.DRAG_DROP_LABEL'| translate}}</p></div>
          <div class="mt-2 text-gray-600  text-xs  leading-tight">  {{'PAGE_IMAGES.UPLOAD_IMAGE.STEP_ONE.OR_LABEL'| translate}}</div>
          <div class="fileDropRef"><p class="mt-2 text-gray-600 text-sm leading-tight">{{'PAGE_IMAGES.UPLOAD_IMAGE.STEP_ONE.BROWSE_FILE'| translate}}</p></div>


  </div>
</form>

<div class="files-list">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <img src="assets/images/ic-file.svg" width="45px" alt="file">
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
      <app-progress [progress]="file?.progress"></app-progress>
    </div>
    <img src="assets/images/ic-delete-file.svg" class="delete" width="20px"  alt="file" (click)="deleteFile(i)">
  </div>
</div>


</div>
<div class="button-group flex flex-row justify-end mt-10 ml-auto mr-auto w-full pr-16">
  <app-round-outline-button

  (click)="onClickBackHandler($event)"
  color="dark">
  {{'PAGE_IMAGES.UPLOAD_IMAGE.STEP_ONE.BACK_TO_LIST_LABEL'| translate}}
  </app-round-outline-button>

</div>
<div class="empty_space h-20"></div>



