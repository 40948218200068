import { trigger, transition, style, animate, state, group } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { GroupModel, GroupUser } from 'src/app/shared/models/group.model';
import { ImageModel } from 'src/app/shared/models/image.model';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { ModalService } from 'src/app/_modal';
import * as fromRoot from "src/app/shared/state";
import { SpinnerOverlayService } from 'src/app/shared/components/spinner/spinner-overlay/spinner-overlay.service';
import { loadGroupListRequestInterface } from 'src/app/shared/types/params/request/loadGroupListRequest.interface';
import { environment } from 'src/environments/environment';
import { GroupPageActions } from 'src/app/group/actions';
import { SendNotificationToGroupCommandInterface } from 'src/app/shared/types/params/request/SendNotificationToGroupCommand.interface';
import { NotificationsPageActions } from '../../actions';
import * as uuid from 'uuid';

@Component({
  selector:'app-send-to-group',
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeInOut', [
      // ...
      state('fadeIn', style({
        opacity: 1
      })),
      state('fadeOut', style({
        opacity: 0,
      })),
      transition('in => out', [
        animate('1s ease-in')
      ]),
      transition('out => in', [
        animate('0.5s ease-out')
      ]),
    ]),
    trigger('showHidden', [
      state('in', style({
        opacity: 1
      })),
      transition('void => *', [
        style({  opacity:  1}),
        group([
          animate('0.5s ease', style({
            opacity: 1
          }))
        ])
      ]),
      transition('* => void', [
        group([
          animate('0.5s 0.2s ease', style({
            opacity:  0.6
          }))
        ])
      ])
    ]),
      trigger('flyInOut', [
        state('in', style({
          transform: 'translateX(0)', opacity: 1
        })),
        transition('void => *', [
          style({  transform: 'translateX(10px)', opacity:  1}),
          group([
            animate('0.5s 0.1s ease', style({
              transform: 'translateX(0)'
            })),
            animate('0.5s ease', style({
              opacity: 1
            }))
          ])
        ]),
        transition('* => void', [
          group([
            animate('0.5s ease', style({
              transform: 'translateX(10px)'
            })),
            animate('0.5s 0.2s ease', style({
              opacity:  0.6
            }))
          ])
        ])
      ])
  ],
  templateUrl: './send-to-group.component.html',
  styleUrls:['./send-to-group.component.scss']
})
export class SendToGroupComponent implements OnInit {
//  data: GroupModel[];
//   currentPage: number;


//   constructor(
//     private modalService:ModalService,
//     private _router:Router
//   ) {

//   }

//   ngOnInit(): void {

//   }

groups$: Observable<PagedListModel<GroupModel[]>>;
  selectedGroupUsers:GroupModel[];
  currentPage$: Observable<number>;
  currentPage:number;
  groupSubscription:Subscription;
  currentPageSubscription: Subscription;
  groupsLoadingState$:Observable<boolean>;
  groupsLoadingStateSubscription:Subscription;
  slug: string
  slug2 :string
  transactionId:string


  constructor(
    private modalService:ModalService,
    private _router:Router,
    private store: Store<fromRoot.State>,
    private spinnerOverlayService: SpinnerOverlayService,
    private route:ActivatedRoute
    ) {


      this.groups$ = this.store.pipe(select(fromRoot.selectAllGroupsList));
      this.currentPage$ = this.store.pipe(select(fromRoot.selectAllGroupsListCurrentPage));
      this.groupsLoadingState$ = this.store.pipe(select(fromRoot.selectAllGroupsListLoadingState));





      this.groupsLoadingStateSubscription = this.groupsLoadingState$.subscribe((res)=>{
        if(res){
          this.spinnerOverlayService.show();
        } else {
          var me = this;
          setTimeout(function(){
            me.spinnerOverlayService.hide();
          },1000);
        }

      })

      this.currentPageSubscription = this.currentPage$.subscribe((res)=>{
        if(res == null){
          res = 1;
        }
        this.currentPage = res;
      })


      this.groupSubscription = this.groups$.subscribe((res)=>{
        if(res != null){
          this.selectedGroupUsers = [];
          this.selectedGroupUsers = res.items;
        }
      })

    }
  ngOnDestroy(): void {
    this.currentPageSubscription.unsubscribe();
    this.groupSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeValues();
    this.getImages();
  }


  initializeValues(){
    if( this.transactionId == null){
      this.transactionId = uuid.v4();
    }
    this.slug = this.route.snapshot.paramMap.get('msgid');
    this.slug2 = this.route.snapshot.paramMap.get('sendtarget');
  }

  getImages() {
    const requestParams: loadGroupListRequestInterface = {
        request: {
          currentPage : this.currentPage,
          pageSize : environment.pageSize
        }
    }
    this.store.dispatch(GroupPageActions.loadGroupList({request:requestParams}));

  }


  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  onClickNextPage(event:Event){

    const requestParams: loadGroupListRequestInterface = {
      request: {
        currentPage : this.currentPage + 1,
        pageSize : environment.pageSize
      }
    }
    this.store.dispatch(GroupPageActions.goNextGroupListPage({request:requestParams}));


  }

  onClickPrevPage(event:Event){
    const requestParams: loadGroupListRequestInterface = {
      request: {
        currentPage : (this.currentPage>1)?this.currentPage - 1:1,
        pageSize : environment.pageSize
      }
    }

    this.store.dispatch(GroupPageActions.goPrevGroupListPage({request:requestParams}));

  }


  onClickSendNotification(groupModel:GroupModel){
    var answer = confirm("Confirm to send the notification to group : "+groupModel.groupName );
    if (answer) {

      // alert("send notification");
      var command:SendNotificationToGroupCommandInterface = {
        request :{
          transactionId: this.transactionId,
          messageId : this.slug,
          groupId : groupModel.groupId,
          cultureId : 99
        }
      }

      this.store.dispatch(NotificationsPageActions.SendNotificationToGroup({command}));

    } else {

    }



  }





}
