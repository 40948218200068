<div [@flyInOut] class="relative rounded-lg overflow-y-scroll flex-auto bg-white h-full landing-page__nestedcontainer--right">

    <div class="table__header flex content-evenly items-center">
      <div class="pr-4 font-medium text-2xl">{{'PAGE_GROUPS.TABLE_HEADER.GROUPS'| translate}}</div>
      <div class="flex-grow"></div>
    <button class="pl-4 w-8" (click)="onClickPrevPage($event)">
      <img src="/assets/images/back-icon.png" class="w-full block" />
    </button>
    <div class="pl-4 w-8 text-center text-gray-800">
      <p>{{currentPage}}</p>
      <!-- page number here  -->
    </div>
    <button class="pl-4 w-8" (click)="onClickNextPage($event)">
      <img src="/assets/images/forward-icon.png" class="w-full block" />
    </button>
    </div>
    <div class="table__content pt-6">
      <div *ngFor="let d of  data" class="table__row flex content-evenly items-center pb-3">
        <div class="pr-4 w-6 self-start mt-2">
          <img class="w-full block" src="/assets/images/point-icon.png" />
        </div>
        <!-- <div class="flex-grow table__description">Welcome to redONE…</div> -->
        <div class="flex flex-col flex-grow table__description ">
          <div class="flex-grow ">{{d.groupName}}</div>
          <div class="flex-grow  text-gray-500 text-xs">{{d.groupDescription}}</div>
        </div>

        <div class="ml-3 cursor-pointer" (click)="onClickCheckGroup(d)">
          <p class="text-sm text-gray-600" >
            {{'PAGE_GROUPS.LIST_GROUP.CHECK_BUTTON_TEXT'| translate}}
          </p>
        </div>
        <div class="ml-3 w-4 cursor-pointer" (click)="onClickDeleteGroup(d)">
          <img src="/assets/images/delete-icon.png"  class="w-full block" />
        </div>
      </div>
    </div>







  <!-- <app-floating-button  class="absolute top-0 right-0 mt-12 mr-12"></app-floating-button> -->
</div>
