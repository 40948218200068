<!-- <div>
  <app-landing-page></app-landing-page>
</div> -->
<!-- https://dzone.com/articles/localization-in-angular-using-i18n-tools -->
<div class="bg-main  h-screen flex landing-page pt-3 pr-3 pb-3">
  <div (click)="collapseMenu($event)" class="relative landing-page__nestedcontainer--left">
    <div class="large-screen-item image-wrapper w-8 absolute top-0 left-0 ml-8 mt-3 flex items-center">


      <img (click)="displayMenu($event)" class="hamburger-menu block" src="/assets/images/hamburger-icon.png" />

      <div [@showHidden] *ngIf="showMenu" #hamburgermenus class="hamburger-context-menu absolute mt-10 top-0 left-0 w-56 rounded-md shadow-lg">
        <div class="rounded-md bg-white shadow-xs">
          <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <button (click)="signOut()" type="button" class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                {{ 'HOME.LOGOUT' | translate }}
              </button>
          </div>
        </div>
      </div>






      <label class="ml-3">
        <!-- {{ 'HOME.SELECT' | translate }} -->
        <select class="p-1" #langSelect (change)="translate.use(langSelect.value)">
          <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
        </select>
      </label>
    </div>

    <div class="image-wrapper w-20 absolute right-0 top-0 mt-6 mr-6">
      <img class="w-full block" src="/assets/images/bg-dot-decorators-1.png" />
    </div>

    <div class="image-wrapper w-40 absolute bottom-0 left-0 ml-32">
      <img class="w-full block" src="/assets/images/bg-dot-decorators-2.png" />
    </div>
    <div
    *ngIf="!isLogin"
      class="shadow-2xl z-10 landing-left-content container h-full m-auto flex flex-wrap justify-center flex-col pl-16 pr-8"
    >
      <p class="text-3xl text-white large-screen-item">redONE Thailand Push Notification Portal</p>
    </div>
    <div
    *ngIf="isLogin"
      class="menu-container-wrapper shadow-2xl z-10 landing-left-content container h-full m-auto flex flex-wrap justify-center flex-col pl-16 pr-8"
    >

      <div class="large-screen-item">
        <p class="text-3xl text-white large-screen-item">{{ 'APP.GREET' | translate }} <span >{{userName | truncate :10  }}</span></p>
        <p class="text-base text-gray-600 large-screen-item">
          {{ 'APP.GREET_MORE' | translate }}
        </p>
      </div>
      <div class="pt-12 w-full large-screen-item">
        <p
          class="text-2xl text-white break-words whitespace-pre-wrap inline-block w-full large-screen-item"
        >
          {{ 'TITLE.NAVIGATION' | translate }}
        </p>
      </div>
      <!-- (click)="navigateToNotificationsPage($event)" -->
         <!-- (click)="navigateToRoutingTargetsPage($event)" -->
      <div class="menu-wrapper mb-8 w-full flex flex-wrap">
        <div
        class="small-screen-item cursor-pointer navigation__menu relative shadow-md w-16 h-16 m-4 mr-4 bg-black-700 rounded-lg flex items-center justify-center"
        (click)="signOut()"
        >
        <div>
          <p class="font-bold text-white text-center text-sm">LOGOUT</p>
        </div>
      </div>


        <div

        routerLink="notifications"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{exact: false}"
          class="cursor-pointer  navigation__menu relative shadow-md w-16 h-16 m-4 mr-4 bg-blue-700 rounded-lg flex items-center justify-center"
        >
          <div class="">
            <p class="font-bold text-white text-center text-lg">NT</p>
          </div>
        </div>
        <!-- <div
        routerLink="routing-targets"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{exact: true}"

          class="cursor-pointer navigation__menu  relative shadow-md w-16 h-16 m-4 mr-4 bg-yellow-700 rounded-lg flex items-center justify-center"
        >
          <div>
            <p class="font-bold text-white text-center text-lg">RT</p>
          </div>
        </div> -->
        <div
        routerLink="images"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{exact: false}"
          class="cursor-pointer navigation__menu relative shadow-md w-16 h-16 m-4 mr-4 bg-gray-900 rounded-lg flex items-center justify-center"
        >
          <div>
            <p class="font-bold text-white text-center text-lg">IM</p>
          </div>
        </div>
        <!-- <div

          class="cursor-pointer navigation__menu relative shadow-md w-16 h-16 m-4 mr-4 bg-red-700 rounded-lg flex items-center justify-center"
        >
          <div>
            <p class="font-bold text-white text-center text-lg">WS</p>
          </div>
        </div> -->
        <div
          routerLink="admin"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{exact: false}"
          class="cursor-pointer navigation__menu relative shadow-md w-16 h-16 m-4 mr-4 bg-red-700 rounded-lg flex items-center justify-center"
        >
          <div>
            <p class="font-bold text-white text-center text-lg">AD</p>
          </div>
        </div>

        <div
        routerLink="groups"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{exact: false}"
        class="cursor-pointer navigation__menu relative shadow-md w-16 h-16 m-4 mr-4 bg-green-700 rounded-lg flex items-center justify-center"
      >
        <div>
          <p class="font-bold text-white text-center text-lg">GR</p>
        </div>
      </div>


      </div>
    </div>
  </div>

  <div  class="overflow-y-scroll flex-auto bg-transparent h-full landing-page__nestedcontainer--right">
    <!-- <app-content-card-notifications></app-content-card-notifications> -->
    <router-outlet></router-outlet>
  </div>

</div>


