import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../shared/components/buttons/buton.module';
import { FormFieldModule } from '../shared/components/form-field';
import { PersistanceService } from '../shared/services/persistance.service';
import { ModalModule } from '../_modal';
import { DndDirective } from '../shared/directives/dnd.directive';
import { GraphModule } from '../shared/components/graphs/graph.module';
import { StaffListComponent } from './components/staff-list/staff-list.component';
import { AdminContainerComponent } from './containers/admin-container.component';
import { AdminService } from '../shared/services/admin.service';
import { StaffPageComponent } from './components/staff-page/staff-page.component';
import { AdminApiEffects } from './admin-api.effects';
import { CreateStaffComponent } from './components/create-staff/create-staff.component';


const routes: Routes = [
  // Site routes goes here
   {
     path: '',
     redirectTo:'admin',
     pathMatch:'full'

   },
   {

     path: 'admin',
     component: AdminContainerComponent,
     children:[
      {path: '', redirectTo: 'staff-list',  pathMatch:'full'},
      {
        path:'staff-list',
        component: StaffPageComponent
      },
      {
        path:'create-staff',
        component: CreateStaffComponent
      }]

   }
  ]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ButtonModule,
    FormFieldModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    EffectsModule.forFeature([AdminApiEffects]),
GraphModule
  ],
  declarations: [
    AdminContainerComponent,
    StaffListComponent,
    StaffPageComponent,
    CreateStaffComponent
  ],
  providers: [
    PersistanceService,
    AdminService
  ]
})
export class AdminModule {}
