<div class="flex flex-col pt-12  pl-16 pr-16">
  <div>
    <p class="header text-5xl font-bold leading-tight">{{'TITLE.ADMIN_CONTROLS'| translate}}</p>
  </div>
</div>
<div class="table w-full pl-16 pr-16 mt-10">
  <app-staff-list [currentPage]="STcurrentPage$ | async"  [data]="(staffList$|async)?.items" (STGoToNextEvent)="STGoToNext($event)" (STGoToPrevEvent)="STGoToPrev($event)" (OpenEditStaffEvent)="openEditStaffModal($event)" ></app-staff-list>
</div>
<div class="empty_space h-20"></div>



<app-floating-button  (click)="onClickFloatingButtonHandler($event)"  class="absolute top-0 right-0 mt-12 mr-12"></app-floating-button>



<jw-modal id="view-staff-modal">
  <div class="pt-12 pl-12 pr-12">
    <div class="" >
      <p class="header text-3xl font-bold leading-tight">{{'PAGE_ADMIN.EDIT_USER.TITLE'| translate}}</p>
    </div>
    <form class="mt-8" [formGroup]="editStaffForm" (ngSubmit)="onSubmitEditSaffForm($event)" >

    <div class="flex flex-row justify-center border-2  p-10">
      <div class="flex flex-col">
        <div class="flex flex-col justify-end">
          <label class="p-3 self-end"  for="isActive"><b>{{'PAGE_ADMIN.EDIT_USER.LABEL.ROLE_LABEL'| translate}}</b></label>
          <label class="p-3 self-end"  for="isActive"><b>{{'PAGE_ADMIN.EDIT_USER.LABEL.IS_ACTIVE_LABEL'| translate}}</b></label>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex flex-col justify-start">
          <select class="p-3" formControlName="roles" id="staff-roles">
            <option *ngFor="let role of roles; let i = index" [value]="roles[i].id">
              <!-- {{roles[i].name}} -->
              <span *ngIf="roles[i].name==='Member'">{{'PAGE_ADMIN.EDIT_USER.VALUE.MEMBER_VALUE'| translate}}</span>
              <span *ngIf="roles[i].name==='Admin'">{{'PAGE_ADMIN.EDIT_USER.VALUE.ADMIN_VALUE'| translate}}</span>
            </option>
          </select>

          <select class="p-3 pt-4" formControlName="isActive" id="staff-isActive">
            <option *ngFor="let isActive of staffIsActiveStates; let i = index" [value]="staffIsActiveStates[i].id">
              <!-- {{staffIsActiveStates[i].name}} -->
              <span *ngIf="staffIsActiveStates[i].name==='true'">{{'PAGE_ADMIN.EDIT_USER.VALUE.TRUE_VALUE'| translate}}</span>
              <span *ngIf="staffIsActiveStates[i].name==='false'">{{'PAGE_ADMIN.EDIT_USER.VALUE.FALSE_VALUE'| translate}}</span>
            </option>
          </select>
        </div>
      </div>
    </div>
      <div class="flex flex-row justify-end  mt-10  mb-8">
        <div class="button-group">
          <app-round-outline-button
            color="yellow"
            type="submit"
            >{{'PAGE_ADMIN.EDIT_USER.BUTTON.SUBMIT_BUTTON'| translate}}</app-round-outline-button
          >
        </div>

        <div class="button-group ml-4" >
          <app-round-outline-button
            color="dark"
            type="button"
            (click)="closeModal('view-staff-modal')"
            >{{'PAGE_ADMIN.EDIT_USER.BUTTON.CLOSE_WINDOW_BUTTON'| translate}}</app-round-outline-button
          >
        </div>
      </div>
    </form>


  </div>
</jw-modal>
