import { ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';
import * as fromNotificationTemplates from "./notificationTemplates.reducer";
import * as fromDeliveredNotifications from "./deliveredNotifications.reducer";
import * as fromCreateNotifications from "./createNotification.reducer";
import * as fromGroups from "./group.reducer";
import * as fromImages from "./images.reducer";
import * as fromAdmin from "./admin.reducer";
import * as fromSegments from "./segments.reducer";
import * as fromCreateStaff from "./createStaff.reducer";
import { getAllGroupsSelector } from './group.reducer';
import { NewStaffModel } from '../models/staff.model';


export interface State {
  newStaff: fromCreateStaff.NewStaffStateInterface
  notificationTemplates: fromNotificationTemplates.NotificationTemplatesStateInterface;
  deliveredNotifications:  fromDeliveredNotifications.ActionHistoriesStateInterface;
  createNotifications: fromCreateNotifications.CreateNotificationStateInterface;
  images: fromImages.ImagesStateInterface;
  groups: fromGroups.GroupStateInterface
  staff: fromAdmin.StaffStateInterface
  segments: fromSegments.SegmentsStateInterface
}

export const reducers: ActionReducerMap<State> = {
  newStaff: fromCreateStaff.reducer,
  notificationTemplates: fromNotificationTemplates.reducer,
  deliveredNotifications:  fromDeliveredNotifications.reducer,
  createNotifications: fromCreateNotifications.reducer,
  images:fromImages.reducer,
  groups: fromGroups.reducer,
  staff: fromAdmin.reducer,
  segments: fromSegments.reducer
};


export const metaReducers: MetaReducer<State>[] = [];

/// -------------- notification template state -------------------- ///
export const selectNotificationTemplatesState = (state: State) => state.notificationTemplates;


export const selectAllNotificationTemplates = createSelector(
  selectNotificationTemplatesState,
  fromNotificationTemplates.getAllNotificationTemplatesSelector
  // fromNotifications.selectAllNotificationsTemplates
);


// export const selectNotificationTemplatesCurrentPageState = (state: State) => state.notificationTemplates.data.currentPage;
export const selectNotificationTemplatesCurrentPage = createSelector(
  selectNotificationTemplatesState,
  fromNotificationTemplates.getNotificationTemplatesCurrentPageSelector
  // fromNotifications.selectAllNotificationsTemplates
);

export const selectNotificationTemplatesLoadingState = createSelector(
  selectNotificationTemplatesState,
  fromNotificationTemplates.getNotificationTemplatesLoadingState
  // fromNotifications.selectAllNotificationsTemplates
);


/// -------------- delivered notification state -------------------- ///
export const selectDeliveredNotificationsState = (state: State) => state.deliveredNotifications;

export const selectAllDeliveredNotifications = createSelector(
  selectDeliveredNotificationsState,
  fromDeliveredNotifications.getAllDeliveredNotificationsSelector
  // fromNotifications.selectAllNotificationsTemplates
);


// export const selectNotificationTemplatesCurrentPageState = (state: State) => state.notificationTemplates.data.currentPage;
export const selectAllDeliveredNotificationsCurrentPage = createSelector(
  selectDeliveredNotificationsState,
  fromDeliveredNotifications.getDeliveredNotificationsCurrentPageSelector
  // fromNotifications.selectAllNotificationsTemplates
);


/// -------------- new notification state -------------------- ///
export const selectNewNotificationRecordState = (state: State) => state.createNotifications;

export const selectNewNotificationRecordSelector = createSelector(
  selectNewNotificationRecordState,
  fromCreateNotifications.getNewNotificationRecordSelector
);


export const selectCreateNotificationErrorSelector = createSelector(
  selectNewNotificationRecordState,
  fromCreateNotifications.getCreateNotificationErrorSelector
);

export const selectCreateNotificationLoadingSelector = createSelector(
  selectNewNotificationRecordState,
  fromCreateNotifications.getCreateNotificationLoadingSelector
);


/// -------------- selected notification state -------------------- ///
export const selectNotificationTemplateState = (state: State) => state.notificationTemplates;

export const selectSelectedNotificationTemplateSelector = createSelector(
  selectNotificationTemplateState,
  fromNotificationTemplates.selectedNotificationTemplateState
);




/// -------------- images state -------------------- ///
export const selectImagesState = (state: State) => state.images;

export const selectAllImagesList = createSelector(
  selectImagesState,
  fromImages.getAllImagesSelector
);


export const selectAllImagesListCurrentPage = createSelector(
  selectImagesState,
  fromImages.getImagesListCurrentPageSelector
);

export const selectAllImagesListLoadingState = createSelector(
  selectImagesState,
  fromImages.getAllImagesSelectorLoadingState
);


/// -------------- group state -------------------- ///
export const selectGroupsState = (state: State) => state.groups;

export const selectAllGroupsList = createSelector(
  selectGroupsState,
  fromGroups.getAllGroupsSelector
);


export const selectAllGroupsListLoadingState = createSelector(
  selectGroupsState,
  fromGroups.getAllGroupsSelectorLoadingState
);


export const selectAllGroupsListCurrentPage = createSelector(
  selectGroupsState,
  fromGroups.getGroupsCurrentPageSelector
);



/// -------------- staff state -------------------- ///
export const selectStaffState = (state: State) => state.staff;

export const selectAllStaffList = createSelector(
  selectStaffState,
  fromAdmin.getAllStaffSelector
);

export const selectAllStaffListCurrentPage = createSelector(
  selectStaffState,
  fromAdmin.getStaffListCurrentPageSelector
);

export const selectAllStaffListLoadingState = createSelector(
  selectStaffState,
  fromAdmin.getAllStaffSelectorLoadingState
);


// ---------------- New staff state ------------------ ///
export const selectNewStaffState = (state: State) => state.newStaff;


export const getNewStaffSelector = createSelector(
  selectNewStaffState,
  fromCreateStaff.getNewStaffSelector
);

export const getNewStaffSelectorLoadingState = createSelector(
  selectNewStaffState,
  fromCreateStaff.getNewStaffSelectorLoadingState
);



// ---------------- Segments  state ------------------ ///
export const selectSegmentsState = (state: State) => state.segments;


export const getSegmentsSelector = createSelector(
  selectSegmentsState,
  fromSegments.getAllSegmentsSelector
);

export const getSegmentsSelectorLoadingState = createSelector(
  selectSegmentsState,
  fromSegments.getAllSegmentsSelectorLoadingState
);


