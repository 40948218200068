import { trigger, transition, style, animate, state, group } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { GroupModel, GroupUser } from 'src/app/shared/models/group.model';
import { ImageModel } from 'src/app/shared/models/image.model';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { ModalService } from 'src/app/_modal';
import * as fromRoot from "src/app/shared/state";
import { SpinnerOverlayService } from 'src/app/shared/components/spinner/spinner-overlay/spinner-overlay.service';
import { loadGroupListRequestInterface } from 'src/app/shared/types/params/request/loadGroupListRequest.interface';
import { environment } from 'src/environments/environment';
import { GroupPageActions } from 'src/app/group/actions';
import { SendNotificationToGroupCommandInterface } from 'src/app/shared/types/params/request/SendNotificationToGroupCommand.interface';
import { NotificationsPageActions } from '../../actions';
import * as uuid from 'uuid';
import { NotificationTemplateModel } from 'src/app/shared/models/notificationTemplate.model';
import { loadSelectedNotificationTemplateRequestInterface } from 'src/app/shared/types/params/request/loadSelectedNotificationTemplateRequest.interface';
import { NotificationTypeEnum } from 'src/app/shared/enums/notificationType.enum';

@Component({
  selector:'app-send-to-group',
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeInOut', [
      // ...
      state('fadeIn', style({
        opacity: 1
      })),
      state('fadeOut', style({
        opacity: 0,
      })),
      transition('in => out', [
        animate('1s ease-in')
      ]),
      transition('out => in', [
        animate('0.5s ease-out')
      ]),
    ]),
    trigger('showHidden', [
      state('in', style({
        opacity: 1
      })),
      transition('void => *', [
        style({  opacity:  1}),
        group([
          animate('0.5s ease', style({
            opacity: 1
          }))
        ])
      ]),
      transition('* => void', [
        group([
          animate('0.5s 0.2s ease', style({
            opacity:  0.6
          }))
        ])
      ])
    ]),
      trigger('flyInOut', [
        state('in', style({
          transform: 'translateX(0)', opacity: 1
        })),
        transition('void => *', [
          style({  transform: 'translateX(10px)', opacity:  1}),
          group([
            animate('0.5s 0.1s ease', style({
              transform: 'translateX(0)'
            })),
            animate('0.5s ease', style({
              opacity: 1
            }))
          ])
        ]),
        transition('* => void', [
          group([
            animate('0.5s ease', style({
              transform: 'translateX(10px)'
            })),
            animate('0.5s 0.2s ease', style({
              opacity:  0.6
            }))
          ])
        ])
      ])
  ],
  templateUrl: './view-notification-template.component.html',
  styleUrls:['./view-notification-template.component.scss']
})
export class ViewNotificationTemplateComponent implements OnInit {

  // @Input("NotificationTemplate") notificationTemplate:NotificationTemplateModel;
  slug: string
  notificationTemplateState$:Observable<NotificationTemplateModel>;
  notificationTemplates$:Observable<PagedListModel<NotificationTemplateModel[]>>;

  public get notificationType(): typeof NotificationTypeEnum {
    return NotificationTypeEnum;
  }
  constructor(
    private modalService:ModalService,
    private _router:Router,
    private store: Store<fromRoot.State>,
    private spinnerOverlayService: SpinnerOverlayService,
    private route:ActivatedRoute
    ) {
      this.notificationTemplates$ = this.store.pipe(select(fromRoot.selectAllNotificationTemplates));
      this.notificationTemplates$.subscribe(res=>{
          if(res==null){
            this._router.navigate(['./notifications/list-notifications']);
          }
      });
      this.notificationTemplateState$ = this.store.pipe(select(fromRoot.selectSelectedNotificationTemplateSelector));

    }


  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.initializeValues();
    this.fetchData();

  }

  fetchData():void {

    let requestParams: loadSelectedNotificationTemplateRequestInterface = {
      request: {
        slug: this.slug
      }
    }

    this.store.dispatch(NotificationsPageActions.LoadSelectedNotificationTemplate({request: requestParams}));
  }


  initializeValues(){
    this.slug = this.route.snapshot.paramMap.get('id')
  }


  onClickPrevPage(ev:Event){
    this._router.navigate(['./notifications/list-notifications']);
  }




}
