<div [@flyInOut] class="relative rounded-lg overflow-y-scroll flex-auto bg-white h-full landing-page__nestedcontainer--right">

  <div class="flex flex-col pt-12 pb-6 pl-16 pr-16">
    <div class="">
      <p class="header text-5xl font-bold leading-tight">{{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_TO_SEGMENTS.TITLE' | translate }}</p>
    </div>
    <div class="subheader-caption border-b-2 mt-12 border-solid border-gray-300">
      <p class="text-gray-600 tracking-widest text-xs pb-3 leading-tight">
        {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_TO_SEGMENTS.SUBHEADER_CAPTION' | translate }}
      </p>
    </div>
    <div class="subheader mt-2">
      <p class="text-3xl text-gray-900 leading-tight">
        {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_TO_SEGMENTS.SUBHEADER_TITLE' | translate }}
      </p>
    </div>
 <form  [formGroup]="form"  class="p-8 m-auto">
   <div *ngIf="segmentKeyList.length > 0">
    <div  *ngFor="let segment of t.controls;let i = index" class="flex flex-row">
    <div  [formGroup]="segment" class="flex flex-row">
      <select class="py-2 px-4 w-36" formControlName="keys" (change)="onChangeFirstOptions($event.target.value,i)" >
        <option>Select</option>

        <option *ngFor='let v of segmentKeyList'>{{ v }}</option>
      </select>
      <div class="w-12">

      </div>
      <select class="py-2 px-4 w-36"  formControlName="keysValue" (change)="onChangeSecondOptions($event.target.value,i)">
        <option>Select</option>
        <option *ngFor='let v of segmentsGroup[i].selectedSegmentValues'>{{ v }}</option>
      </select>

    </div>
    </div>
  </div>
  </form>
  <div class="button-group flex mt-6 self-end">
    <app-round-outline-button
    (click)="onClickCancelButtonHandler($event)"
    color="dark">
    {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_TO_SEGMENTS.CANCEL_BTN' | translate }}
    </app-round-outline-button>
      <app-round-button
        (click)="onClickContinueButtonHandler($event)"
        color="yellow" class="pl-4">
        {{'PAGE_NOTIFICATIONS.SEND_NOTIFICATIONS_TO_SEGMENTS.CONTINUE_BTN' | translate }}
      </app-round-button>
  </div>
  </div>
</div>
