
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createSelector } from '@ngrx/store';
import { NotificationsApiActions, NotificationsPageActions } from 'src/app/notifications/actions';
import { ActionHistoryModel } from '../models/actionHistory.model';
import { NotificationTemplateModel } from '../models/notificationTemplate.model';
import { PagedListModel } from '../models/pagedList.model';


export interface ActionHistoriesStateInterface {
  data: PagedListModel<ActionHistoryModel[]> | null
  isLoading: boolean
  error: string | null
}



export const initialState : ActionHistoriesStateInterface =  {
    data : null,
    isLoading : false,
    error : null
}

// export interface State extends EntityState<NotificationModel> {
//   activeNotificationId: string | null;
//   totalPage:number | 1;
//   currentPage: number | 1;
// }

// export const adapter = createEntityAdapter<NotificationTemplateModel>();

// export const initialState = adapter.getInitialState({
//   activeNotificationId: null,
//   totalPage:1,
//   currentPage:1
// });


export function reducer(
  state = initialState,
  action: NotificationsPageActions.NotificationsActions | NotificationsApiActions.NotificationApiActions
): ActionHistoriesStateInterface {
  switch (action.type) {
    case NotificationsApiActions.DNLoadedSuccess.type:
      return   {
        ...state,
        isLoading: false,
        data:action.actionHistories
      }

    case NotificationsPageActions.loadDN.type:
        return   {
          ...state,
          isLoading: true
        }
    case NotificationsPageActions.goNextDNPage.type:
        return   {
            ...state,
            isLoading: true
         }


      //  adapter.addAll(action.notifications.result, state);

    // case BooksPageActions.selectBook.type:
    //   return {
    //     ...state,
    //     activeBookId: action.bookId
    //   };

    // case BooksPageActions.clearSelectedBook.type:
    //   return {
    //     ...state,
    //     activeBookId: null
    //   };

    // case BooksApiActions.bookCreated.type:
    //   return adapter.addOne(action.book, {
    //     ...state,
    //     activeBookId: action.book.id
    //   });

    // case BooksApiActions.bookUpdated.type:
    //   return adapter.updateOne(
    //     { id: action.book.id, changes: action.book },
    //     { ...state, activeBookId: action.book.id }
    //   );

    // case BooksApiActions.bookDeleted.type:
    //   return adapter.removeOne(action.book.id, {
    //     ...state,
    //     activeBookId: null
    //   });

    default:
      return state;
  }
}


/**
 * Selectors
 */
// export const  selectAllNotificationsTemplates:PagedListModel<NotificationTemplateModel[] = notificationTemplatesState.data
//  export const selectAllNotificationsTemplates =
// export const { selectAll, selectEntities } = adapter.getSelectors();

export const selectAllActionHistories = (state:ActionHistoriesStateInterface ) => state.data;
export const getAllDeliveredNotificationsSelector= createSelector(
  selectAllActionHistories,
  (data:PagedListModel<ActionHistoryModel[]>) => {
     return data
  }
);

export const getDeliveredNotificationsCurrentPageSelector= createSelector(
  selectAllActionHistories,
  (data:PagedListModel<ActionHistoryModel[]>) => {

    if(data == null){
      return 1;
    }
    if(data.currentPage == null){
      return 1;
    }
    if(data.currentPage <= 0){
      return 1;
    }

     return data.currentPage
  }
);
