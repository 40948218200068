<button
[type]=type
[ngClass]=
"{
  'border-yellow-600': color === 'yellow',
  'text-yellow-600': color === 'yellow',
  'border-gray-600': color === 'dark',
  'text-gray-600': color === 'dark'
}"
class="rounded-full border-solid border-2 pl-10 pr-10 pt-2 pb-2 outline-none shadow-md">
  <ng-content></ng-content>
</button>
