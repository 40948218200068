import { Component, OnInit } from '@angular/core';

import * as fromRoot from "src/app/shared/state";

import {
  trigger,
  state,
  style,
  animate,
  transition,
  group,
  // ...
} from '@angular/animations';
import { ModalService } from 'src/app/_modal';
import { NotificationTemplateModel } from '../../shared/models/notificationTemplate.model';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { NotificationsPageActions } from '../actions';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { loadNotificationTemplatesRequestInterface } from '../../shared/types/params/request/loadNotificationTemplatesRequest.interface';
import { SpinnerOverlayService } from 'src/app/shared/components/spinner/spinner-overlay/spinner-overlay.service';
@Component({
  selector: 'redone-notifications-container',
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeInOut', [
      // ...
      state('fadeIn', style({
        opacity: 1
      })),
      state('fadeOut', style({
        opacity: 0,
      })),
      transition('in => out', [
        animate('1s ease-in')
      ]),
      transition('out => in', [
        animate('0.5s ease-out')
      ]),
    ]),
    trigger('showHidden', [
      state('in', style({
        opacity: 1
      })),
      transition('void => *', [
        style({  opacity:  1}),
        group([
          animate('0.5s ease', style({
            opacity: 1
          }))
        ])
      ]),
      transition('* => void', [
        group([
          animate('0.5s 0.2s ease', style({
            opacity:  0.6
          }))
        ])
      ])
    ]),

      trigger('flyInOut', [
        state('in', style({
          transform: 'translateX(0)', opacity: 1
        })),
        transition('void => *', [
          style({  transform: 'translateX(10px)', opacity:  1}),
          group([
            animate('0.5s 0.1s ease', style({
              transform: 'translateX(0)'
            })),
            animate('0.5s ease', style({
              opacity: 1
            }))
          ])
        ]),
        transition('* => void', [
          group([
            animate('0.5s ease', style({
              transform: 'translateX(10px)'
            })),
            animate('0.5s 0.2s ease', style({
              opacity:  0.6
            }))
          ])
        ])
      ])
    ],
  templateUrl: './notifications-container.component.html',
  styleUrls: ['./notifications-container.component.scss']
})
export class NotificationsContainerComponent implements OnInit {


  constructor(
    private modalService:ModalService,
    private spinnerOverlayService:SpinnerOverlayService
  ) {

  }

  ngOnInit(): void {
    // this.spinnerOverlayService.show("...Loading");
  }



  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
}

}
