import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from '@angular/router';

import { NotificationTemplateModel } from 'src/app/shared/models/notificationTemplate.model';
import { deleteNotificationCommandInterface } from 'src/app/shared/types/params/request/deleteNotificationCommand.interface';
import { ModalService } from 'src/app/_modal';
import { NotificationsPageActions } from '../../actions';

@Component({
  selector: "app-notification-templates-list",
  templateUrl: "./notification-templates-list.component.html",
  styleUrls: ["./notification-templates-list.component.scss"]
})
export class NotificationTemplatesListComponent implements OnInit {
  @Input() data: NotificationTemplateModel[];
  @Input() currentPage: number;

  @Output('NTGoToNextEvent') goToNextEvent = new EventEmitter<number>();
  @Output('NTGoToPrevEvent') goToPrevEvent = new EventEmitter<number>();
  @Output('DeleteNotificationEvent') deleteNotificationEvent = new EventEmitter<NotificationTemplateModel>();



  constructor(
    private modalService:ModalService,
    private _router:Router
  ) {

  }

  ngOnInit(): void {

  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  onClickNextPage(ev:Event){
    this.goToNextEvent.emit(1);
  }

  onClickPrevPage(ev:Event){
    this.goToPrevEvent.emit(1);
  }

  onClickFloatingButtonHandler(ev:Event){
    this._router.navigate(['./notifications/create/step-one']);
  }


  onClickOpenSendNotificationPageHandler(d:NotificationTemplateModel){
    this._router.navigate(['./notifications/sendnotification/options/',d.id]);
  }

  onClickDeleteNotification(d:NotificationTemplateModel){
    this.deleteNotificationEvent.emit(d);

  }

  onClickGoDetailPage(d:NotificationTemplateModel){
    this._router.navigate(['./notifications/selected-notification-template', d.id])
  }


}
