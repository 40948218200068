<div>
  <div class="field-wrapper flex flex-row items-center">
    <div *ngIf="isPositive" class="w-4">
      <img class="block" src="/assets/images/button-positive.png">
    </div>
    <div *ngIf="!isPositive" class="w-4">
      <img class="block" src="/assets/images/button-negative.png">
    </div>
    <div class="ml-6"><ng-content></ng-content></div>
  </div>
</div>
