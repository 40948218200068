import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/_modal';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { NotificationRecordModel } from 'src/app/shared/models/notificationRecord.model';
import * as fromRoot from 'src/app/shared/state';
import { loadNewNotificationRecordRequestInterface } from 'src/app/shared/types/params/request/loadNewNotificationRecordRequest.interface';
import { NotificationsPageActions } from '../../actions';
import * as uuid from 'uuid';
import { TemplateContentTypeEnum } from 'src/app/shared/enums/templateContentType.enum';
import * as _ from 'lodash';
import { CreateNewTemplateContentCommandInterface } from 'src/app/shared/types/params/request/CreateNewTemplateContentCommand.interface';
import { CreateNewActionCommandInterface } from 'src/app/shared/types/params/request/CreateNewActionCommand.interface';
import { CreateNewNotificationTemplate } from '../../actions/notifications-page.actions';
import { CreateNewNotificationTemplateCommandInterface } from '../../../shared/types/params/request/CreateNewNotificationTemplateCommand.interface';
import { SpinnerOverlayService } from 'src/app/shared/components/spinner/spinner-overlay/spinner-overlay.service';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';
import { ImageModel } from 'src/app/shared/models/image.model';
import { environment } from 'src/environments/environment';
import { loadImagesListRequestInterface } from 'src/app/shared/types/params/request/loadImagesListRequest.interface';
import { ImagesPageActions } from 'src/app/images/actions';
import { loadNotificationTemplatesRequestInterface } from 'src/app/shared/types/params/request/loadNotificationTemplatesRequest.interface';
import { NotificationTypeEnum } from 'src/app/shared/enums/notificationType.enum';

@Component({
  selector: 'app-create-notification-step-three',
  templateUrl: './create-notification-step-three.component.html',
  styleUrls: ['./create-notification-step-three.component.scss'],
})
export class CreateNotificationStepThreeComponent implements OnInit, OnDestroy {
  initializedNotificationRecord: NotificationRecordModel = {
    id: '0',
    notificationType: null,
    messageTitle: null,
    messageBody: null,
    templateId: null, // uuid
    templatePageTitle: null,
    templateBottomButtonTitle: null,
    templateContents: null,
    actionId: null, // uuid
    actionProductId: null,
    actionMessageTitle: null,
    actionMessageBody: null,
    actionPositiveButtonText: null,
    actionPositiveButtonSuccessMessageText: null,
    actionNegativeButtonText: null,
  };

  notificationTypeString:string;

  newNotificationRecord$: Observable<NotificationRecordModel>;

  imagesList$: Observable<PagedListModel<ImageModel[]>>;
  imagesListCurrentPage$: Observable<number>;
  errorSelector$: Observable<string>;
  createNotificationLoadingState$: Observable<boolean>;
  createNotificationLoadingStateSubscription: Subscription;

  newNotificationRecordSubscription: Subscription;

  slug: string;

  form: FormGroup;

  submitted: boolean = false;
  addActionFormSubmitted: boolean = false;
  addTemplateFormSubmitted: boolean = false;
  addTemplateForm: FormGroup;
  addImageForm: FormGroup;
  addContentForm: FormGroup;
  updateContentHtmlForm: FormGroup;
  updateContentImageForm: FormGroup;

  addActionForm: FormGroup;

  IMcurrentPage$: Observable<number>;
  IMcurrentPage:number;
  IMcurrentPageSubscription: Subscription;

  currentUpdateContentModelHtmlTemplateIndex:number;
  currentUpdateContentModelImageTemplateIndex:number;

  constructor(
    private _router: Router,
    private _modalService: ModalService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private spinnerOverlayService: SpinnerOverlayService
  ) {}

  ngOnDestroy(): void {
    this.IMcurrentPageSubscription.unsubscribe();
    this.newNotificationRecordSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeValues();
    this.fetchData();
    this.initializeForm();
    this.setSubscription();
  }

  setSubscription(): void {
    this.newNotificationRecordSubscription = this.newNotificationRecord$.subscribe(
      (res) => {
        if (res != null) {
          this.initializedNotificationRecord = res;


          this.notificationTypeString = NotificationTypeEnum[res.notificationType];
        }
        // clear all the assigned form
        this.addTemplateForm = null;
        this.addImageForm = null;

        this.addContentForm = null;

        this.updateContentHtmlForm = null;

        this.updateContentImageForm = null;

        // initialize form
        this.initializeForm();
      }
    );


    this.createNotificationLoadingStateSubscription = this.createNotificationLoadingState$.subscribe(
      (res) => {
        console.log('loading' + res);
        if (res) {
          this.spinnerOverlayService.show();
        } else {
          var me = this;
          setTimeout(function () {
            me.spinnerOverlayService.hide();
          }, 1000);
        }
      }
    );

    this.IMcurrentPageSubscription = this.IMcurrentPage$.subscribe((res)=>{
      if(res == null){
        res = 1;
      }
      this.IMcurrentPage = res;
    })
  }

  fetchData(): void {
    let requestParams: loadNewNotificationRecordRequestInterface = {
      request: {
        slug: this.slug,
      },
    };

    this.store.dispatch(
      NotificationsPageActions.LoadNewNotificationRecord({
        request: requestParams,
      })
    );

    const IMRequestParams: loadImagesListRequestInterface = {
      request: {
        currentPage : 1,
        pageSize : environment.pageSize
      }
    }
    this.store.dispatch(ImagesPageActions.loadImagesList({request:IMRequestParams}));

  }

  initializeValues(): void {
    this.slug = this.route.snapshot.paramMap.get('id');
    this.newNotificationRecord$ = this.store.pipe(
      select(fromRoot.selectNewNotificationRecordSelector)
    );
    this.errorSelector$ = this.store.pipe(
      select(fromRoot.selectCreateNotificationErrorSelector)
    );
    this.createNotificationLoadingState$ = this.store.pipe(
      select(fromRoot.selectCreateNotificationLoadingSelector)
    );

    this.imagesList$ = this.store.pipe(select(fromRoot.selectAllImagesList));
    this.imagesListCurrentPage$ = this.store.pipe(
      select(fromRoot.selectAllImagesListCurrentPage)
    );

    this.IMcurrentPage$ = this.store.pipe(select(fromRoot.selectAllImagesListCurrentPage));


  }


  initializeForm(): void {
    var me = this;
    this.addTemplateForm = new FormGroup({
      templatePageTitle: new FormControl(
        this.initializedNotificationRecord.templatePageTitle,
        [Validators.required, Validators.maxLength(50)]
      ),
      templateBottomButtonTitle: new FormControl(
        this.initializedNotificationRecord.templateBottomButtonTitle,
        [Validators.required, Validators.maxLength(50)]
      ),
      templateContents: new FormArray([]),
    });

    _.map(this.initializedNotificationRecord.templateContents, function (x) {
      let res = new FormControl({
        id: x.id,
        templateContentType: x.templateContentType,
        templateContent: x.templateContent,
      });

      (me.addTemplateForm.get('templateContents') as FormArray).push(res);

      return _.assign(
        x,
        me.fb.control({
          id: x.id,
          templateContentType: x.templateContentType,
          templateContent: x.templateContent,
        })
      );
    });

    this.addImageForm = new FormGroup({
      templateImageUrl: new FormControl('', [Validators.required]),
    });

    this.addContentForm = new FormGroup({
      editorField: new FormControl('', [Validators.required]),
    });

    this.updateContentHtmlForm = new FormGroup({
      editorField: new FormControl('', [Validators.required]),
    });

    this.updateContentImageForm = new FormGroup({
      templateImageUrl: new FormControl('', [Validators.required]),
    });

    this.addActionForm = new FormGroup({
      actionProductId: new FormControl(
        this.initializedNotificationRecord.actionProductId,
        [Validators.required, Validators.maxLength(50)]

      ),
      actionMessageTitle: new FormControl(
        this.initializedNotificationRecord.actionMessageTitle,
        [Validators.required, Validators.maxLength(100)]
      ),
      actionMessageBody: new FormControl(
        this.initializedNotificationRecord.actionMessageBody,
        [Validators.required, Validators.maxLength(400)]
      ),
      actionPositiveButtonText: new FormControl(
        this.initializedNotificationRecord.actionPositiveButtonText,
        [Validators.required, Validators.maxLength(20)]
      ),
      actionPositiveButtonSuccessMessageText:new FormControl(
        this.initializedNotificationRecord.actionPositiveButtonSuccessMessageText,
        [Validators.required, Validators.maxLength(200)]
      ),
      actionNegativeButtonText: new FormControl(
        this.initializedNotificationRecord.actionNegativeButtonText,
        [Validators.required, Validators.maxLength(20)]
      ),
    });
  }

  get templateContents() {
    return this.addTemplateForm.get('templateContents') as FormArray;
  }

  get generateIdentifierId() {
    return uuid.v4();
  }

  onClickClearMessageTemplateHandler() {
    var answer = confirm('Confirm to discard this message template?');
    if (answer) {
      //some code
      this.store.dispatch(NotificationsPageActions.DiscardNewMessageTemplate());
    } else {
      //some code
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////  Create new template Modal Window            /////////////////////////

  /** Add template modal window */
  submitAddTemplateEvent(ev: Event) {
    this.addTemplateFormSubmitted = true;
    if (this.addTemplateForm.valid) {
      var commandParam: CreateNewTemplateContentCommandInterface = {
        request: {
          templatePageTitle: this.addTemplateForm.value.templatePageTitle,
          templateBottomButtonTitle: this.addTemplateForm.value
            .templateBottomButtonTitle,
          templateContents: _.map(
            this.addTemplateForm.value.templateContents,
            function (x) {
              return _.assign(x, {
                id: x.id,
                templateContentType: x.templateContentType,
                templateContent: x.templateContent,
              });
            }
          ),
        },
      };

      this.store.dispatch(
        NotificationsPageActions.CreateNewContentTemplate({
          command: commandParam,
        })
      );
      this.closeModal('add-template-modal');
    } else {
      alert('Please fill in all the required field');
    }
  }

  // this method is invoked when add a content to the template modal window
  addTemplateContent(content: string) {
    this.templateContents.push(
      new FormControl({
        id: uuid.v4(),
        templateContentType: TemplateContentTypeEnum.HTMLCONTENT,
        templateContent: content,
      })
    );
  }

  updateTemplateContent(index:number,content: string) {

    var control =  this.templateContents.at(index);

    this.templateContents.removeAt( index)

    this.templateContents.insert(
      index,

      new FormControl({
        id:  control.value.id,
        templateContentType: control.value.templateContentType,
        templateContent: content,
      })
    );

  }

  // this method is invoked when add a content to the template modal window
  addImageContent(url: string) {
    this.templateContents.push(
      new FormControl({
        id: uuid.v4(),
        templateContentType: TemplateContentTypeEnum.IMAGECONTENT,
        templateContent: url,
      })
    );
  }

  /** Update content modal window */
  openUpdateContentModel(id: string, index: number) {
    this.openModal(id);
    this.closeModal('add-template-modal');

    let formValue = this.templateContents.at(index).value;

    console.log(formValue.id);
    this.currentUpdateContentModelHtmlTemplateIndex = index;

    this.updateContentHtmlForm.setValue({
      editorField: formValue.templateContent,
    });
  }

  /** Update content modal window */
  openUpdateContentImageModel(id: string, index: number) {
    this.openModal(id);
    this.closeModal('add-template-modal');

    let formValue = this.templateContents.at(index).value;
    console.log(formValue);

    this.updateContentImageForm.setValue({
      templateImageUrl: "https://www.googleapis.com/storage/v1/b/redone_th_oneapp_prod/o/images%2Fimg-logo20200930121721470.png"//formValue.templateContent,
    });
  }

  /** Update content modal window */
  removeContentImageRecord(index: number) {
    this.templateContents.removeAt(index);
  }

  moveUpContentImageRecord(index: number) {
    var control =  this.templateContents.at(index);
    console.log(control);
     this.templateContents.removeAt( index)
     if(index > 0){
      index = index -1;
     }
     this.templateContents.insert(index ,control);
    // this.templateContents.removeAt(index);
  }

  moveDownContentImageRecord(index: number) {

    var max =  this.templateContents.controls.length;
    var control =  this.templateContents.at(index);
     this.templateContents.removeAt( index)
     if(index < max){
      index = index + 1;
     }
     this.templateContents.insert(index ,control);
  }

  /** Update content modal window */
  removeContentHtmlRecord(index: number) {
    this.templateContents.removeAt(index);
  }

  moveUpContentHtmlRecord(index: number) {
    var control =  this.templateContents.at(index);
    console.log(control);
     this.templateContents.removeAt( index)
     if(index > 0){
      index = index -1;
     }
     this.templateContents.insert(index ,control);
    // this.templateContents.removeAt(index);
  }

  moveDownContentHtmlRecord(index: number) {

    var max =  this.templateContents.controls.length;
    var control =  this.templateContents.at(index);
     this.templateContents.removeAt( index)
     if(index < max){
      index = index + 1;
     }
     this.templateContents.insert(index ,control);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////  Add new content (HTML tag) Modal Window            //////////////////

  /* Add content modal window */
  openAddContentModel(id: string) {
    this.openModal(id);
    this.closeModal('add-template-modal');
  }

  closeAddContentModel(id: string) {
    this.closeModal(id);
    this.openModal('add-template-modal');
  }

  closeUpdateContentModel(id: string) {
    this.closeModal(id);
    this.openModal('add-template-modal');
  }

  openClickAddTemplateModal(id: string) {
    this._modalService.open(id);
    this.fetchData();
  }

  openClickAddActionModal(id: string) {
    this._modalService.open(id);
    this.fetchData();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////      Add new content (image) Modal Window            /////////////////
  /* add image modal window */
  openAddImageModel(id: string) {
    this.openModal(id);
    this.closeModal('add-template-modal');
  }

  closeAddImageModel(id: string) {
    this.closeModal(id);
    this.openModal('add-template-modal');
  }

  onClickCopyImageUrl(url: string) {
    this.addImageForm.setValue({
      templateImageUrl: url,
    });
  }

  onClickCopyImageUrlUpdate(url: string) {
    this.updateContentImageForm.setValue({
      templateImageUrl: url,
    });
  }

  submitAddImageUrlEvent(ev: Event) {
    if (this.addImageForm.valid) {
      console.log(this.addImageForm.value);

      this.addImageContent(this.addImageForm.value.templateImageUrl);

      this.addImageForm.setValue({
        templateImageUrl: '',
      });

      this.closeAddImageModel('add-new-image-modal');
    }
  }

  onIMGoPrevEvent(){
    const requestParams: loadNotificationTemplatesRequestInterface = {
      request: {
        currentPage : (this.IMcurrentPage>1)?this.IMcurrentPage - 1:1,
        pageSize : environment.pageSize
      }
    }

    this.store.dispatch(ImagesPageActions.goPrevIMPage({request:requestParams}));
  }

  onIMGoNextEvent(){
    const requestParams: loadImagesListRequestInterface = {
      request: {
        currentPage : this.IMcurrentPage + 1,
        pageSize : environment.pageSize
      }
    }

    this.store.dispatch(ImagesPageActions.goNextIMPage({request:requestParams}));
  }



  ///////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////      Update new content (image) Modal Window            /////////////////
  closeUpdateImageModel(id: string) {
    this.closeModal(id);
    this.openModal('add-template-modal');
  }

  onClickPreviousButtonHandler(ev: Event) {
    this._router.navigate(['./notifications/create/step-two', this.slug]);
  }

  onClickContinueButtonHandler(ev: Event) {
    this.submitted = true;
    if (
      this.initializedNotificationRecord.templatePageTitle != '' &&
      this.initializedNotificationRecord.templatePageTitle != null &&
      this.initializedNotificationRecord.templateBottomButtonTitle != '' &&
      this.initializedNotificationRecord.templateBottomButtonTitle != null &&
      this.initializedNotificationRecord.templateContents != null &&
      this.initializedNotificationRecord.templateContents.length > 0
    ) {
      console.log(this.initializedNotificationRecord);

     var orderedTemplateContents =  this.initializedNotificationRecord.templateContents.map((item,index) =>
                                      Object.assign({}, item, {order:index+1}));

     // finalize the order before submit the form
    //  let count:number = 1;
    //   for( var i=0; i< this.initializedNotificationRecord.templateContents.length; i++){
    //     orderedTemplateContents[i].order = count;
    //     count++;
    //   }


      var commandParam: CreateNewNotificationTemplateCommandInterface = {
        command: {
          id: this.initializedNotificationRecord.id,
          notificationType: this.initializedNotificationRecord.notificationType,
          messageTitle: this.initializedNotificationRecord.messageTitle,
          messageBody: this.initializedNotificationRecord.messageBody,
          templateId: this.initializedNotificationRecord.templateId,
          templatePageTitle: this.initializedNotificationRecord
            .templatePageTitle,
          templateBottomButtonTitle: this.initializedNotificationRecord
            .templateBottomButtonTitle,
          templateContents: orderedTemplateContents,//this.initializedNotificationRecord.templateContents,
          actionId: this.initializedNotificationRecord.actionId,
          actionProductId: this.initializedNotificationRecord.actionProductId,
          actionMessageTitle: this.initializedNotificationRecord
            .actionMessageTitle,
          actionMessageBody: this.initializedNotificationRecord
            .actionMessageBody,
          actionPositiveButtonText: this.initializedNotificationRecord
            .actionPositiveButtonText,
            actionPositiveButtonSuccessMessageText: this.initializedNotificationRecord
            .actionPositiveButtonSuccessMessageText,
          actionNegativeButtonText: this.initializedNotificationRecord
            .actionNegativeButtonText,


        },
      };

      this.store.dispatch(
        NotificationsPageActions.CreateNewNotificationTemplate({
          command: commandParam,
        })
      );
    } else {
      alert('Please fill in the required fields');
    }
  }

  /** general open close modal action */
  closeModal(id: string) {
    this._modalService.close(id);
  }

  openModal(id: string) {
    this._modalService.open(id);
  }

  /** Add content(html) modal window */
  submitAddHtmlContentEvent(ev: Event) {
    if (this.addContentForm.valid) {
      console.log(this.addContentForm.value);

      this.addTemplateContent(this.addContentForm.value.editorField);
      this.addContentForm.setValue({
        editorField: '',
      });
      this.closeAddContentModel('add-new-content-modal');
    }
  }

  submitUpdateContentEvent(ev: Event) {
    if (this.updateContentHtmlForm.valid) {
      console.log(this.currentUpdateContentModelHtmlTemplateIndex);
      // currentUpdateContentModelHtmlTemplateId
      console.log(this.updateContentHtmlForm.value.editorField);
      this.updateTemplateContent(this.currentUpdateContentModelHtmlTemplateIndex,this.updateContentHtmlForm.value.editorField);

      this.closeAddContentModel('update-content-html-modal');
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////      Add action Modal Window            /////////////////

  /** Add template modal window */
  submitAddActionEvent(ev: Event) {
    this.addActionFormSubmitted = true;
    if (this.addActionForm.valid) {
      var commandParam: CreateNewActionCommandInterface = {
        request: {
          actionProductId: this.addActionForm.value.actionProductId,
          actionMessageTitle: this.addActionForm.value.actionMessageTitle,
          actionMessageBody: this.addActionForm.value.actionMessageBody,
          actionPositiveButtonText: this.addActionForm.value
            .actionPositiveButtonText,
          actionPositiveButtonSuccessMessageText: this.addActionForm.value
            .actionPositiveButtonSuccessMessageText,
          actionNegativeButtonText: this.addActionForm.value
            .actionNegativeButtonText,
        },
      };

      this.store.dispatch(
        NotificationsPageActions.CreateNewAction({ command: commandParam })
      );
      this.closeModal('add-action-modal');
    } else {
      alert('Please fill in all the required field');
    }
  }

  resetAndSaveActionFormEvent(ev: Event) {
    // todo: reset the action form
    this.store.dispatch(NotificationsPageActions.ResetAndSaveAction());
  }
}
