import { createAction, props } from '@ngrx/store';
import { GroupModel } from 'src/app/shared/models/group.model';
import { ImageModel } from 'src/app/shared/models/image.model';
import { PagedListModel } from 'src/app/shared/models/pagedList.model';

export const GroupListLoadedSuccess = createAction(
  "[Group API] Group List Loaded Success",
  props<{ groupList: PagedListModel<GroupModel[]>}>()
);

export const GroupListLoadedFailed = createAction(
  "[Group API] Group List Loaded Failed",
  props<{ errors: string}>()
);

export const DeleteGroupFailed = createAction(
  "[Group API] Delete Group Failed",
  props<{ errors: string }>()
);

export const DeleteGroupSuccess = createAction(
  "[Group API] Delete Group Success",
  props<{ groupId: string }>()
);



export type GroupActions = ReturnType<
| typeof GroupListLoadedSuccess
| typeof GroupListLoadedFailed
| typeof DeleteGroupFailed
| typeof DeleteGroupSuccess
>
