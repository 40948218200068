import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    basicAuth:string = '';

  constructor( private oidcSecurityService: OidcSecurityService) {
    if(environment.production === false){
        this.basicAuth = btoa(`${environment.authUrl.clientId}:${environment.authUrl.clientSecret}`);
    } else {
      this.basicAuth = btoa(`${environment.authUrl.clientId}:${environment.authUrl.clientSecret}`);
    }
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log(request.headers.get('Content-Type'))
    console.log( request.body instanceof FormData)
    if(!(request.url.endsWith('connect/token'))  && this.oidcSecurityService.getToken() != null && request.headers.get('Content-Type') != 'multipart/form-data' && !(request.body instanceof FormData) ){

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.oidcSecurityService.getToken()}`,
          "Content-Type": 'application/json'
        }
      });

      return next.handle(request);
      } else

       if(!(request.url.endsWith('connect/token'))  && this.oidcSecurityService.getToken() != null &&  (request.body instanceof FormData)) {
        console.log(request.headers.get('Content-Type'))

     // const clonedRequest = request.clone({ headers: request.headers.append('Authorization', `Bearer ${this.oidcSecurityService.getToken()}`) });
      const clonedRequest  = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.oidcSecurityService.getToken()}`

        }
      });

      return next.handle(clonedRequest);
    } else

    if (environment.production !== false && request.url.endsWith('connect/token')) {



      let newHeaders = request.headers;

      newHeaders = newHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      newHeaders = newHeaders.append('Authorization', `Basic ${this.basicAuth}`);
      // console.log(`Caught request to ${request.url}`);

      // console.log(request.body);

     var newBody = request.body;
      var paramsKeyValue = newBody.split("&");

      var  newParamsKeyValue = [];
      for(var i=0; i<paramsKeyValue.length; i++){

        var keyValueArray =  paramsKeyValue[i].split("=");
        var key = keyValueArray[0];
        if(key != "code_verifier"){
          newParamsKeyValue.push(paramsKeyValue[i]);
        }
      }
      newBody = newParamsKeyValue.join("&");

      const newRequest = request.clone({
        headers: newHeaders,
        body: newBody
      });
      return next.handle(newRequest);

    }


    return next.handle(request);
  }
}
