import { CreateNewNotificationTemplateCommandInterface } from './../shared/types/params/request/CreateNewNotificationTemplateCommand.interface';
import { Injectable } from "@angular/core";
import { Effect, Actions, ofType, createEffect } from "@ngrx/effects";
import { NotificationsPageActions, NotificationsApiActions } from "./actions";
import { NotificationsService } from '../shared/services/notifications.service';
import {
  mergeMap,
  map,
  catchError,
  exhaustMap,
  concatMap, tap
} from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import { NotificationTemplateModel } from '../shared/models/notificationTemplate.model';
import { PagedListModel } from '../shared/models/pagedList.model';
import { loadNotificationTemplatesRequestInterface } from '../shared/types/params/request/loadNotificationTemplatesRequest.interface';
import { switchMap } from 'rxjs/operators';

import { PersistanceService } from '../shared/services/persistance.service';
import { NotificationRecordModel } from '../shared/models/notificationRecord.model';
import { Router } from '@angular/router';
import { NewMessageTemplateDiscardSuccess, SendNotificationToAllSuccess, LoadSegmentsSuccess } from './actions/notifications-api.actions';
import { environment } from 'src/environments/environment';
import { ActionHistoryModel } from '../shared/models/actionHistory.model';
import { LoadSegments } from './actions/notifications-page.actions';
import { SegmentAggregateModel, SegmentModel } from '../shared/models/segment.model';

/* load notifications templates */
@Injectable()
export class NotificationsApiEffects {

  @Effect()
  loadNT$ = this.actions$.pipe(
    ofType(NotificationsPageActions.loadNT.type),
    exhaustMap(({request} ) =>

      this.notificationsService.getNotificationsTemplate(request).pipe(

        map((notificationTemplates:PagedListModel<NotificationTemplateModel[]>) => NotificationsApiActions.NTLoadedSuccess({ notificationTemplates })),
        catchError((err:string) => of(NotificationsApiActions.NTLoadedFailed({ errors:err})))

      )
    )
  );


  @Effect()
  loadNTPrevPage$ = this.actions$.pipe(
    ofType(NotificationsPageActions.goPrevNTPage.type),
    exhaustMap(({request}) => {

      return this.notificationsService.getNotificationsTemplate( request ).pipe(
        map((notificationTemplates:PagedListModel<NotificationTemplateModel[]>) => NotificationsApiActions.NTLoadedSuccess({ notificationTemplates })),
        catchError((err:string) => of(NotificationsApiActions.NTLoadedFailed({ errors:err})))
      )
    }
    )
  );

  @Effect()
  loadNTNextPage$ = this.actions$.pipe(
    ofType(NotificationsPageActions.goNextNTPage.type),
    switchMap(({request}) => {
      return this.notificationsService.getNotificationsTemplate( request ).pipe(
        map((notificationTemplates:PagedListModel<NotificationTemplateModel[]>) => NotificationsApiActions.NTLoadedSuccess({ notificationTemplates })),
        catchError((err:string) => of(NotificationsApiActions.NTLoadedFailed({ errors:err})))
      )
    }
    )
  );


  showErrorMesssageAfterNTLoadedFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.NTLoadedFailed),
        tap((err) => {
          console.log(err)
          alert(`Failed to load Notification templates. [error:${err.errors}]`);
        })
      ),
    {dispatch: false}
  )


/* load delivered notifications */
  @Effect()
  loadDT$ = this.actions$.pipe(
    ofType(NotificationsPageActions.loadDN.type),
    exhaustMap(({request} ) =>
      this.notificationsService.getDeliveredNotifications(request).pipe(
        map((actionHistories:PagedListModel<ActionHistoryModel[]>) => NotificationsApiActions.DNLoadedSuccess({ actionHistories })),
        catchError(() => EMPTY)
      )
    )
  );



  @Effect()
  loadDNPrevPage$ = this.actions$.pipe(
    ofType(NotificationsPageActions.goPrevDNPage.type),
    exhaustMap(({request}) => {

      return this.notificationsService.getDeliveredNotifications( request ).pipe(
        map((actionHistories:PagedListModel<ActionHistoryModel[]>) => NotificationsApiActions.DNLoadedSuccess({ actionHistories })),
        catchError(() => EMPTY)
      )
    }
    )
  );

  @Effect()
  deleteNotification$ = this.actions$.pipe(
    ofType(NotificationsPageActions.DeleteNotification.type),
    switchMap(({command}) => {
      return this.notificationsService.deleteNotification( command ).pipe(
        map((isTrue:boolean) => {
            if(isTrue){
              return NotificationsApiActions.DeleteNotificationSuccess({messageId:command.request.messageId});
            } else {
              return NotificationsApiActions.DeleteNotificationFailed({errors:"Delete Notification failed."});
            }
          }
          ),
          catchError((err:string) => of(NotificationsApiActions.DeleteNotificationFailed({ errors:err})))
      )
    }
   )
  );


  @Effect()
  InitializeNewNotification$ = this.actions$.pipe(
    ofType(NotificationsPageActions.InitializeNewNotification.type),
    switchMap(({command}) => {

      try {
        let notificationRecord: NotificationRecordModel =  this.persistanceService.get("newNotificationRecord")

        // id the deaft message is not found in the local storage
        if(notificationRecord == null){
            let newNotificationRecord: NotificationRecordModel = {
              id: command.request.newId,
              notificationType: null,
              messageTitle: null,
              messageBody:null,
              templateId:command.request.newTemplateId,
              templatePageTitle:null,
              templateBottomButtonTitle:null,
              templateContents:null,
              actionId:command.request.newActionId,
              actionProductId:null,
              actionMessageTitle:null,
              actionMessageBody:null,
              actionPositiveButtonText:null,
              actionNegativeButtonText : null,
              actionPositiveButtonSuccessMessageText:null
            }
            this.persistanceService.set("newNotificationRecord", newNotificationRecord)
            return of(NotificationsApiActions.NewNotificationInitializedSuccess({ newNotification:newNotificationRecord }))
        } else {
          if(notificationRecord.id == null || notificationRecord.templateId == null || notificationRecord.actionId == null )
          {
            return of(NotificationsApiActions.MissingIdentifierIdDetected())
          }

          return of(NotificationsApiActions.NewNotificationInitializedSuccess({ newNotification:notificationRecord }))
        }
      } catch(e){
          return EMPTY
      }
    })
  )

  showAlertAfterDeleteNotificationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.DeleteNotificationSuccess),
        tap(() => {
          alert("notification deleted succesfully!");
        })
      ),
    {dispatch: false}
  )

  showAlertAfterDeleteNotificationFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.DeleteNotificationFailed),
        tap((err) => {
          console.log(err)
          alert(`Failed to delete notification. [error:${err.errors}]`);
        })
      ),
    {dispatch: false}
  )



  redirectAfterNewNotificationInitialized$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.NewNotificationInitializedSuccess),
        tap(({newNotification}) => {
          this.router.navigate(['./notifications/create/step-one', newNotification.id])
        })
      ),
    {dispatch: false}
  )


  redirectAfterMissingIdentifierId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.MissingIdentifierIdDetected),
        tap(() => {
          this.persistanceService.remove("newNotificationRecord");
          this.router.navigate(['./notifications/list-notifications'])
        })
      ),
    {dispatch: false}
  )





  @Effect()
  LoadNewNotificationRecord$ = this.actions$.pipe(
    ofType(NotificationsPageActions.LoadNewNotificationRecord.type),
    switchMap(({command}) => {
      try {

        let notificationRecord: NotificationRecordModel =  this.persistanceService.get("newNotificationRecord")

        if(notificationRecord == null){
          return of(NotificationsApiActions.MissingIdentifierIdDetected())
        }
        if(notificationRecord.id == null || notificationRecord.templateId == null || notificationRecord.actionId == null )
        {
          return of(NotificationsApiActions.MissingIdentifierIdDetected())
        }

        return of(NotificationsApiActions.NewNotificationRecordLoadedSuccess({ newNotification:notificationRecord }))
      } catch(e){
          return EMPTY
      }
    })
  )



  @Effect()
  UpdateSelectedNotificationType$ = this.actions$.pipe(
    ofType(NotificationsPageActions.UpdateSelectedNotificationType.type),
    switchMap(({command}) => {
      try {
        let oldNotificationRecord: NotificationRecordModel =  this.persistanceService.get("newNotificationRecord")
        var newNotificationRecord = {
          ...oldNotificationRecord,
          notificationType : command.request.notificationType
        }

       this.persistanceService.set("newNotificationRecord", newNotificationRecord)

        return of(NotificationsApiActions.SelectedNotificationTypeUpdatedSuccess({ newNotification:newNotificationRecord }))
      } catch(e){
          return EMPTY
      }
    })
  )



  @Effect()
  UpdateNewNotificationMessage$ = this.actions$.pipe(
    ofType(NotificationsPageActions.UpdateNotificationMessageContent.type),
    switchMap(({command}) => {
      try {
        let oldNotificationRecord: NotificationRecordModel =  this.persistanceService.get("newNotificationRecord")

        var newNotificationRecord = {
          ...oldNotificationRecord,
          ...command.request.newMessage
        }

       this.persistanceService.set("newNotificationRecord", newNotificationRecord)

        return of(NotificationsApiActions.NotificationMessageContentUpdatedSuccess({ newNotification:newNotificationRecord }))
      } catch(e){
          return EMPTY
      }
    })
  )

  redirectAfterNotificationMessageContentUpdated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.NotificationMessageContentUpdatedSuccess),
        tap(({newNotification}) => {
          this.router.navigate(['./notifications/create/step-three', newNotification.id])
        })
      ),
    {dispatch: false}
  )



  /** STEP THREE */
  @Effect()
  CreateNewTemplateContent$ = this.actions$.pipe(
    ofType(NotificationsPageActions.CreateNewContentTemplate.type),
    switchMap(({command}) => {
      try {
        let oldNotificationRecord: NotificationRecordModel =  this.persistanceService.get("newNotificationRecord")

        var newNotificationRecord = {
          ...oldNotificationRecord,
          templatePageTitle:command.request.templatePageTitle,
          templateBottomButtonTitle:command.request.templateBottomButtonTitle,
          templateContents:command.request.templateContents,
        }

       this.persistanceService.set("newNotificationRecord", newNotificationRecord)

        return of(NotificationsApiActions.NewContentTemplateCreatedSuccess({ newNotification:newNotificationRecord }))
      } catch(e){
          return EMPTY
      }
    })
  )


  showSuccessMesssageAfterNewContentTemplateCreatedSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.NewContentTemplateCreatedSuccess),
        tap(() => {
          alert("Template saved succesfully");
        })
      ),
    {dispatch: false}
  )

  @Effect()
  CreateNewAction$ = this.actions$.pipe(
    ofType(NotificationsPageActions.CreateNewAction.type),
    switchMap(({command}) => {
      try {
        let oldNotificationRecord: NotificationRecordModel =  this.persistanceService.get("newNotificationRecord")

        var newNotificationRecord = {
          ...oldNotificationRecord,
          actionProductId:command.request.actionProductId,
          actionMessageTitle:command.request.actionMessageTitle,
          actionMessageBody:command.request.actionMessageBody,
          actionPositiveButtonText:command.request.actionPositiveButtonText,
          actionNegativeButtonText:command.request.actionNegativeButtonText,
          actionPositiveButtonSuccessMessageText: command.request.actionPositiveButtonSuccessMessageText
        }

       this.persistanceService.set("newNotificationRecord", newNotificationRecord)

        return of(NotificationsApiActions.NewActionCreatedSuccess({ newNotification:newNotificationRecord }))
      } catch(e){
          return EMPTY
      }
    })
  )


  showSuccessMesssageAfterNewActionCreatedSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.NewActionCreatedSuccess),
        tap(() => {
          alert("Action saved succesfully");
        })
      ),
    {dispatch: false}
  )




  @Effect()
  ResetAndSaveAction$ = this.actions$.pipe(
    ofType(NotificationsPageActions.ResetAndSaveAction.type),
    switchMap(() => {
      try {
        let oldNotificationRecord: NotificationRecordModel =  this.persistanceService.get("newNotificationRecord")

        var newNotificationRecord = {
          ...oldNotificationRecord,
          actionProductId:null,
          actionMessageTitle:null,
          actionMessageBody:null,
          actionPositiveButtonText:null,
          actionNegativeButtonText:null
        }

       this.persistanceService.set("newNotificationRecord", newNotificationRecord)

        return of(NotificationsApiActions.ActionResetAndSaveSuccess({ newNotification:newNotificationRecord }))
      } catch(e){

          return EMPTY
      }
    })
  )


  @Effect()
  DiscardNewMessageTemplateAction$ = this.actions$.pipe(
    ofType(NotificationsPageActions.DiscardNewMessageTemplate.type),
    switchMap(() => {
      try {
        this.persistanceService.remove("newNotificationRecord");

        return of(NotificationsApiActions.NewMessageTemplateDiscardSuccess())
      } catch(e){

          return EMPTY
      }
    })
  )


  redirectAfterNewMessageTemplateDiscardAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.NewMessageTemplateDiscardSuccess),
        tap(() => {

          this.router.navigate(['./notifications/list-notifications'])
        })
      ),
    {dispatch: false}
  )


  @Effect()
  createNotificationTemplate$ = this.actions$.pipe(
    ofType(NotificationsPageActions.CreateNewNotificationTemplate.type),
    exhaustMap(({command}) =>
      this.notificationsService.createNewNotificationsTemplate(command).pipe(
        map((result:boolean) => {
          if(result){

            return NotificationsApiActions.NewNotificationTemplateCreatedSuccess();
          } else {
            return NotificationsApiActions.NewNotificationTemplateCreatedFailed({errors:"Failed to create notification template."});
          }
        }),
        catchError((err:string) => of( NotificationsApiActions.NewNotificationTemplateCreatedFailed({ errors:err})))
      )
    ),
    switchMap(res => [
      NotificationsPageActions.loadNT({ request:{
        request: {
          currentPage:1,
          pageSize: environment.pageSize
        }
      } }),
      NotificationsPageActions.DiscardNewMessageTemplate()
    ])
  );



  showSuccessMesssageAndReloadAfterNewNotificationTemplateCreatedSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.NewNotificationTemplateCreatedSuccess),
        tap(() => {


          alert("Notification template created!");
        })
      ),
    {dispatch: false}
  )


  showErrorMesssageAndReloadAfterNewNotificationTemplateCreatedFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.NewNotificationTemplateCreatedFailed),
        tap((err) => {

            if(typeof err.errors != "undefined" && err.errors != null){
              alert(`Notification template creation failed ![Err: ${err.errors} ]`);
            } else {
              alert(`Notification template creation failed!`);
            }
        })
      ),
    {dispatch: false}
  )


  @Effect()
  sendToAllCommand$ = this.actions$.pipe(
    ofType(NotificationsPageActions.SendNotificationToAll.type),
    exhaustMap(({command}) =>
      this.notificationsService.sendNotificationToAll(command).pipe(
        map((result:boolean) => {
          if(result){
            return NotificationsApiActions.SendNotificationToAllSuccess();
          } else {
            return NotificationsApiActions.SendNotificationToAllFailed({errors:"Failed to send notification to all."});
          }
        }),
        catchError((err:string) => of( NotificationsApiActions.SendNotificationToAllFailed({ errors:err})))
      )
    ),
    switchMap(res => [
      NotificationsPageActions.loadNT({ request:{
        request: {
          currentPage:1,
          pageSize: environment.pageSize
        }
      } }),
      NotificationsPageActions.DiscardNewMessageTemplate()
    ])
  );

  @Effect()
  sendToSingleCommand$ = this.actions$.pipe(
    ofType(NotificationsPageActions.SendNotificationToSingle.type),
    exhaustMap(({command}) =>
      this.notificationsService.sendNotificationToSingle(command).pipe(
        map((result:boolean) => {
          if(result){
            return NotificationsApiActions.SendNotificationToSingleSuccess();
          } else {
            return NotificationsApiActions.SendNotificationToSingleFailed({errors:"Failed to send notification to single."});
          }
        }),
        catchError((err:string) => of( NotificationsApiActions.SendNotificationToSingleFailed({ errors:err})))
      )
    ),
    switchMap(res => [
      NotificationsPageActions.loadNT({ request:{
        request: {
          currentPage:1,
          pageSize: environment.pageSize
        }
      } }),
      NotificationsPageActions.DiscardNewMessageTemplate()
    ])
  );



  @Effect()
  sendToGroupCommand$ = this.actions$.pipe(
    ofType(NotificationsPageActions.SendNotificationToGroup.type),
    exhaustMap(({command}) =>
      this.notificationsService.sendNotificationToGroup(command).pipe(
        map((result:boolean) => {
          if(result){
            return NotificationsApiActions.SendNotificationToGroupSuccess();
          } else {
            return NotificationsApiActions.SendNotificationToGroupFailed({errors:"Failed to send notification to group."});
          }
        }),
        catchError((err:string) => of( NotificationsApiActions.SendNotificationToGroupFailed({ errors:err})))
      )
    ),
    switchMap(res => [
      NotificationsPageActions.loadNT({ request:{
        request: {
          currentPage:1,
          pageSize: environment.pageSize
        }
      } }),
      NotificationsPageActions.DiscardNewMessageTemplate()
    ])
  );


  @Effect()
  sendToSegmentsCommand$ = this.actions$.pipe(
    ofType(NotificationsPageActions.SendNotificationToSegments.type),
    exhaustMap(({command}) =>
      this.notificationsService.sendNotificationToSegments(command).pipe(
        map((result:boolean) => {
          if(result){
            return NotificationsApiActions.SendNotificationToSegmentsSuccess();
          } else {
            return NotificationsApiActions.SendNotificationToSegmentsFailed({errors:"Failed to send notification to segments."});
          }
        }),
        catchError((err:string) => of( NotificationsApiActions.SendNotificationToSegmentsFailed({ errors:err})))
      )
    )
  );


  showSuccessMesssageAfterSendNotificationToSegmentsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsApiActions.SendNotificationToSegmentsSuccess),
        tap(() => {
          alert("Notification sent!");
        })
      ),
    {dispatch: false}
  )

  /* load delivered notifications */
  @Effect()
  loadUniqueTags$ = this.actions$.pipe(
    ofType(NotificationsPageActions.LoadSegments.type),
    exhaustMap(({request} ) =>
      this.notificationsService.getSegments(request).pipe(
        map((uniqueTags:SegmentAggregateModel) => NotificationsApiActions.LoadSegmentsSuccess({segments:uniqueTags})),
        catchError((err:string) => of(NotificationsApiActions.LoadSegmentsFailed({ errors:err})))
      )
    )
  );




  constructor(
    private notificationsService: NotificationsService,
    private persistanceService: PersistanceService,
    private router:Router,
    private actions$: Actions<
      NotificationsPageActions.NotificationsActions | NotificationsApiActions.NotificationApiActions
    >
  ) {}
}
