import { createSelector } from '@ngrx/store';
import { PagedListModel } from '../models/pagedList.model';
import { ImageModel } from '../models/image.model';
import { ImagesApiActions, ImagesPageActions } from 'src/app/images/actions';
import { DeleteNotificationSuccess } from '../../notifications/actions/notifications-api.actions';
import { map } from 'rxjs/operators';

export interface ImagesStateInterface {
  data: PagedListModel<ImageModel[]> | null;
  isLoading: boolean;
  error: string | null;
}

export const initialState: ImagesStateInterface = {
  data: null,
  isLoading: false,
  error: null,
};

export function reducer(
  state = initialState,
  action: ImagesPageActions.ImagesActions | ImagesApiActions.ImagesActions
): ImagesStateInterface {
  switch (action.type) {
    case ImagesApiActions.IMLoadedSuccess.type:
      return {
        ...state,
        isLoading: false,
        data: action.imagesList,
      };

    case ImagesApiActions.IMLoadedFailed.type:
      return {
        ...state,
        isLoading: false,
        error: action.errors,
      };

    case ImagesPageActions.loadImagesList.type:
      return {
        ...state,
        isLoading: true,
      };
    case ImagesPageActions.goNextIMPage.type:
      return {
        ...state,
        isLoading: true,
      };
    case ImagesPageActions.goPrevIMPage.type:
      return {
        ...state,
        isLoading: true,
      };

      case ImagesPageActions.DeleteImage.type:
        return   {
            ...state,
            isLoading: true
         };
      case ImagesApiActions.DeleteImageSuccess.type:
         {

          var toberemove = state.data.items.findIndex(a => a.id == action.imageId);

            return   {
              ...state,
             data:  <PagedListModel<ImageModel[]>>{
                items:[
                  ...state.data.items.slice(0, toberemove),
                  ...state.data.items.slice(toberemove + 1)
                ]
              },
                isLoading: false
            };
         }
        // return   {
        //     ...state,
        //     isLoading: false
        //  };

     case ImagesApiActions.DeleteImageFailed.type:

            return   {
                ...state,
                isLoading: false,
                error: action.errors
            };

    default:
      return state;
  }
}

/**
 * Selectors
 */

export const selectAllImages = (state: ImagesStateInterface) => state.data;
export const selectAllImagesState = (state: ImagesStateInterface) => state;
export const getAllImagesSelector = createSelector(
  selectAllImages,
  (data: PagedListModel<ImageModel[]>) => {
    return data;
  }
);

export const getAllImagesSelectorLoadingState = createSelector(
  selectAllImagesState,
  (data: ImagesStateInterface) => {
    return data.isLoading;
  }
);


export const getImagesListCurrentPageSelector = createSelector(
  selectAllImages,
  (data: PagedListModel<ImageModel[]>) => {
    if (data == null) {
      return 1;
    }
    if (data.currentPage == null) {
      return 1;
    }

    if (data.currentPage <= 0) {
      return 1;
    }

    return data.currentPage;
  }
);
